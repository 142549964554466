import React from 'react';

const TabContentComponentTSApp = ({ activeKey }) => {
    return (
        <div className="tab-content justify-content-center">
            {activeKey === 'tab1' && 
            <p>
                <div className="yellow-bg pt-150 pb-70 md-pt-60 md-pb-60">
                    <div className="container">
                    <div className="row">
                        <div className="col-lg-6 md-mb-50">
                        <div className="about-content">
                            <div className="images-part">
                            <img
                                src="/assets/images/installation-support.webp"
                                alt="Images"
                            />
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-6 pl-30 md-pr-l5">
                        <div className="sec-title mb-20">
                           <h2 className="title title3 pb-25">
                           Installation Support
                            </h2>
                            <p className="margin-0">
                            Our installation support service offers valuable assistance in the installation of your purchased products, delivered by our knowledgeable technical experts. To ensure a smooth process, it is crucial for you to identify your workstations and servers and arrange remote access for our team. Once granted access, our skilled technical experts utilize advanced remote support tools to promptly and efficiently provide the assistance you need.{" "}
                            </p>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </p>
            }
            {activeKey === 'tab2' && 
            <p>
            <div className="yellow-bg pt-150 pb-70 md-pt-80 md-pb-60">
                <div className="container">
                <div className="row">
                    <div className="col-lg-6 md-mb-50">
                    <div className="about-content">
                        <div className="images-part">
                        <img
                            src="/assets/images/tech-support.webp"
                            alt="Images"
                        />
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-6 pl-30 md-pr-l5">
                    <div className="sec-title mb-20">
                        <h2 className="title title3 pb-25">
                        Technical Production Support
                        </h2>
                        <p className="margin-0">
                        Technical production support is a collaborative endeavor involving technical support teams and key business stakeholders, particularly traders, to effectively address any technical challenges pertaining to systems and applications. In a trading environment, traders typically initiate support requests, commonly known as 'tickets,' through channels like email or telephone, and closely collaborate with support teams to expedite issue resolution.</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </p>
            }
            {activeKey === 'tab3' && 
            <p>
            <div className="yellow-bg pt-150 pb-70 md-pt-80 md-pb-60">
                <div className="container">
                <div className="row">
                    <div className="col-lg-6 md-mb-50">
                    <div className="about-content">
                        <div className="images-part">
                        <img
                            src="/assets/images/server-support.webp"
                            alt="Images"
                        />
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-6 pl-30 md-pr-l5">
                    <div className="sec-title mb-20">
                        <h2 className="title title3 pb-25">
                        Server and Database Support
                        </h2>
                        <p className="margin-0">
                        At our company, customer satisfaction is our top priority as we offer comprehensive and premium-quality database services. We go beyond standard maintenance and focus on optimizing efficiency while reducing costs. Our commitment to adaptability and personalized service has resulted in exceptional customer satisfaction and a high rate of client retention.</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </p>
            }
             {activeKey === 'tab4' && 
            <p>
            <div className="yellow-bg pt-150 pb-70 md-pt-80 md-pb-60">
                <div className="container">
                <div className="row">
                    <div className="col-lg-6 md-mb-50">
                    <div className="about-content">
                        <div className="images-part">
                        <img
                            src="/assets/images/troubleshooting-support.webp"
                            alt="Images"
                        />
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-6 pl-30 md-pr-l5">
                    <div className="sec-title mb-20">
                        <h2 className="title title3 pb-25">
                        Troubleshooting Support
                        </h2>
                        <p className="margin-0">
                        Troubleshooting is a systematic approach employed to resolve issues and anomalies. Its primary goal is to identify the underlying cause of unexpected behavior and devise appropriate solutions to rectify the problem. The initial phase of troubleshooting entails providing a thorough and detailed description of the issue at hand.{" "}
                        </p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </p>
            }
        </div>
    );
};

export default TabContentComponentTSApp;
