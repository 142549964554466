import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';

const TabsComponentAiMl = ({ activeKey, onSelect }) => {
    return (
        <Tabs activeKey={activeKey} onSelect={onSelect} id="tab-component" className='justify-content-center'>
            <Tab eventKey="tab1" title={
                <span>
                    <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/technologies/chatbots.webp" className="attachment-full" alt="Chatbots" title="Chatbots" />
                        </span>
                    </div>
                </span>
            } />
            {/* <Tab eventKey="tab2" title={
                <span>
                    <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/technologies/predictive.webp" className="attachment-full" alt="Predictive Analytics" title="Predictive Analytics" />
                        </span>
                    </div>
                </span>
            } />
            <Tab eventKey="tab3" title={
                <span>
                    <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/technologies/fraud-prevention.webp" className="attachment-full" alt="Fraud Detection" title="Fraud Detection" />
                        </span>
                    </div>
                </span>
            } /> */}
            <Tab eventKey="tab4" title={
                <span>
                    <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/technologies/image-recognition.webp" className="attachment-full" alt="Image Recognition" title="Image Recognition" />
                        </span>
                    </div>
                </span>
            } />
        </Tabs>
    );
};

export default TabsComponentAiMl;
