import React from 'react';

const TabContentComponentDMApp = ({ activeKey }) => {
    return (
        <div className="tab-content justify-content-center">
            {activeKey === 'tab1' && 
            <p>
                <div className="yellow-bg pt-150 pb-70 md-pt-60 md-pb-60">
                    <div className="container">
                    <div className="row">
                        <div className="col-lg-6 md-mb-50">
                        <div className="about-content">
                            <div className="images-part">
                            <img
                                src="/assets/images/seo-img.webp"
                                alt="Images"
                            />
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-6 pl-30 md-pr-l5">
                        <div className="sec-title mb-20">
                           <h2 className="title title3 pb-25">
                           Search Engine Optimization(SEO)
                            </h2>
                            <p className="margin-0">
                            Our expert team will enhance your website's search engine ranking through comprehensive solutions. We analyze, strategize, and optimize your content, keywords, and more. With our services, you'll receive implementation, ongoing support, and site reviews. Additionally, our Logistic Management Solution includes on-page and off-page SEO strategies to optimize your website's visibility and performance.{" "}
                            </p>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </p>
            }
            {activeKey === 'tab2' && 
            <p>
            <div className="yellow-bg pt-150 pb-70 md-pt-80 md-pb-60">
                <div className="container">
                <div className="row">
                    <div className="col-lg-6 md-mb-50">
                    <div className="about-content">
                        <div className="images-part">
                        <img
                            src="/assets/images/smm.webp"
                            alt="Images"
                        />
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-6 pl-30 md-pr-l5">
                    <div className="sec-title mb-20">
                        <h2 className="title title3 pb-25">
                        Social Media Marketing(SMM)
                        </h2>
                        <p className="margin-0">
                        Engage, grow, and convert your audience with a strategic approach. Enhance messaging, create a unique brand style, and build trust with your target audience on Facebook, Instagram, LinkedIn, and YouTube. Establish your brand as reliable, clear, and easily understandable to achieve social media success.</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </p>
            }
            {activeKey === 'tab3' && 
            <p>
            <div className="yellow-bg pt-150 pb-70 md-pt-80 md-pb-60">
                <div className="container">
                <div className="row">
                    <div className="col-lg-6 md-mb-50">
                    <div className="about-content">
                        <div className="images-part">
                        <img
                            src="/assets/images/sem.webp"
                            alt="Images"
                        />
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-6 pl-30 md-pr-l5">
                    <div className="sec-title mb-20">
                        <h2 className="title title3 pb-25">
                        Search Engine Marketing(SEM)
                        </h2>
                        <p className="margin-0">
                        Discover top-notch techniques and campaigns in our blog to effectively promote products in a competitive market. SEM is a powerful tool for rapid business expansion. Enhance online visibility, attract customers, and achieve growth objectives through impactful search, display, YouTube, and shopping ads.</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </p>
            }
             {activeKey === 'tab4' && 
            <p>
            <div className="yellow-bg pt-150 pb-70 md-pt-80 md-pb-60">
                <div className="container">
                <div className="row">
                    <div className="col-lg-6 md-mb-50">
                    <div className="about-content">
                        <div className="images-part">
                        <img
                            src="/assets/images/webanalytics.webp"
                            alt="Images"
                        />
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-6 pl-30 md-pr-l5">
                    <div className="sec-title mb-20">
                        <h2 className="title title3 pb-25">
                        Web Analytics
                        </h2>
                        <p className="margin-0">
                        Web-analytics framework : Optimize your online presence with a robust web-analytics framework. Gather, analyze, and report on user data to measure behavior, enhance website flow, and drive business objectives. Leverage powerful tools like the Webmaster Console and Google Analytics for actionable insights and improved conversions.{" "}
                        </p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </p>
            }
        </div>
    );
};

export default TabContentComponentDMApp;
