import axios from "axios";
import React, { useEffect, useState } from "react";
// import { API_ENDPOINT } from "../Api";

const CategoryList = ({ onCategoryClick }) => {
  const [categories, setCategories] = useState([]);

  // useEffect(() => {
  //   const fetchCategories = async () => {
  //     try {
  //       const response = await axios.get(`${API_ENDPOINT}/categories`);
  //       setCategories(response.data);
  //     } catch (error) {
  //       console.error("Error fetching categories:", error);
  //     }
  //   };

  //   fetchCategories();
  // }, []);

  const categoriesWithPosts = categories.filter(
    (category) => category["count"] > 0
  );
  return (
    <div className="categories">
      <div className="widget-title">
        <h3 className="title">Categories</h3>
      </div>
      <ul>
        <li key="all">
          <a className="pointer" onClick={() => onCategoryClick(null)}>
            All Categories
          </a>
        </li>
        {categoriesWithPosts.map((category) => (
          <li key={category.id}>
            <a className="pointer" onClick={() => onCategoryClick(category.id)}>
              {category.name}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CategoryList;
