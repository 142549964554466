import Header from "../Header";
import Footer from "../Footer";
import BreadCrums from "../BreadCrums";
import React, { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MetaTag from "../MetaTag";
import TabsComponentErpSol from "./TabsComponentErpSol";
import TabContentComponentErpSol from "./TabContentComponentErpSol";

const ERPSolutions = () => {
  const MetaTitle =
    "Leading Custom ERP Solutions | Excel in delivering tailored ERP | Hilton Software Technologies Pvt. Ltd.";
  const MetaDescription =
    "Discover tailored ERP solutions at Hilton Software Technologies Pvt. Ltd. We specialize in creating custom ERP systems designed to enhance business efficiency and streamline operations. Contact us for expert consultation today!";
  const MetaKeywords =
    "ERP solutions, custom ERP systems, business efficiency, streamline operations, Hilton Software Technologies, ERP consulting, tailored ERP solutions, enterprise resource planning, expert consultation";
  const path = window.location.pathname;
  const [activeKey, setActiveKey] = useState('tab1');
  return (
    <>
      <MetaTag
        title={MetaTitle}
        description={MetaDescription}
        keywords={MetaKeywords}
        path={path}
      />
      <Header />
      <BreadCrums
        name={"erp"}
        tittle={"ERP Solutions"}
        watermark={"ERP Solutions"}
      />
      <div id="" className="fulfill-ecommerce pt-60 pb-60 md-pt-60 md-pb-60 xs-pt-30 xs-pb-30">
        <div className="container-fluid custom">
          <div className="sec-title3 text-center mb-30 md-mb-30">
            <h1 className="title title2 pb-20">
              Showcasing Our Expertise in ERP Customizations
            </h1>
            <span className="border-line">
                <i className="fa fa-diamond" id="diamond"></i>
             </span>
            {/* <div class="bar-main">
              <div class="bar bar-big"></div>
            </div> */}
          </div>

          <div className="row y-middle text-white">
          <div className="offset-lg-1 col-lg-10 col-md-12">
          <p className="ft16" style={{ textAlign: "center" }}>
            Our software development company delivers cutting-edge ERP (Enterprise Resource Planning) solutions that transform how businesses manage their core operations. We create fully customized ERP systems that integrate essential functions like finance, inventory management, human resources, and customer relations into one cohesive platform, ensuring smoother workflows and improved data access. By automating routine tasks and providing real-time insights, our solutions help businesses reduce costs, boost productivity, and enhance decision-making.
            </p>
            <p className="ft16" style={{ textAlign: "center" }}>We specialize in tailoring ERP systems to fit the unique needs of each client, offering scalability and flexibility for businesses of all sizes. Our end-to-end approach includes everything from consultation and system design to implementation, training, and ongoing support. We ensure seamless integration with your existing software and third-party applications to maximize the ERP’s impact. With our solutions, companies can optimize resource allocation, streamline processes, and drive sustainable growth. Whether you’re looking to improve financial management, enhance supply chain efficiency, or manage human resources effectively, our ERP solutions empower your business to reach new heights of success.</p>
          </div>
          </div>
        </div>
      </div>

      <div
        id="operational-ecommerce"
        className="rs-single-shop vertical operational-ecommerce  pt-20 pb-60 md-pt-60 sm-pb-68-0 hidden"
      >
        <div className="container">
          <div className="sec-title text-center mb-55 md-mb-30">
            <h2 className="title title2">
              Why Choose Us for Your ERP Customizations?
            </h2>
          </div>

          <div className="tab-area desktop">
            <ul className="nav nav-tabs" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="Industry-Knowledge-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Industry-Knowledge"
                  type="button"
                  role="tab"
                  aria-controls="Industry-Knowledge"
                  aria-selected="true"
                >
                  <img src="/assets/images/icons/innovation.webp" /> Deep
                  Industry Knowledge
                </button>
              </li>

              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="Tailored-Solutions-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Tailored-Solutions"
                  type="button"
                  role="tab"
                  aria-controls="Tailored-Solutions"
                  aria-selected="true"
                >
                  <img src="/assets/images/icons/problem-solving.webp" />
                  Tailored Solutions
                </button>
              </li>

              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="Advanced-Technology-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Advanced-Technology"
                  type="button"
                  role="tab"
                  aria-controls="Advanced-Technology"
                  aria-selected="false"
                >
                  <img src="/assets/images/icons/technology.webp" />
                  Advanced Technology
                </button>
              </li>

              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="Comprehensive-Integration-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Comprehensive-Integration"
                  type="button"
                  role="tab"
                  aria-controls="Comprehensive-Integration"
                  aria-selected="false"
                >
                  <img src="/assets/images/icons/integration.webp" />{" "}
                  Comprehensive Integration
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="End-to-End-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#End-to-End"
                  type="button"
                  role="tab"
                  aria-controls="End-to-End"
                  aria-selected="false"
                >
                  <img src="/assets/images/icons/public-service.webp" />{" "}
                  End-to-End Support
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="Track-Record-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Track-Record"
                  type="button"
                  role="tab"
                  aria-controls="Track-Record"
                  aria-selected="false"
                >
                  <img src="/assets/images/icons/proven.webp" /> Proven Track
                  Record
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="Comprehensive-Training-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Comprehensive-Training"
                  type="button"
                  role="tab"
                  aria-controls="Comprehensive-Training"
                  aria-selected="false"
                >
                  <img src="/assets/images/icons/presentation.webp" />{" "}
                  Comprehensive Training
                </button>
              </li>
            </ul>

            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id="Industry-Knowledge"
                role="tabpanel"
                aria-labelledby="Industry-Knowledge-tab"
              >
                <h3>Deep Industry Knowledge</h3>
                <p className="">
                  Our team possesses a wealth of experience across various
                  industries, including manufacturing, retail, healthcare,
                  finance, and more. This diverse expertise allows us to
                  understand the specific challenges and requirements of your
                  industry, ensuring that our ERP solutions are perfectly
                  aligned with your business processes.
                </p>
              </div>

              <div
                className="tab-pane fade show"
                id="Tailored-Solutions"
                role="tabpanel"
                aria-labelledby="Tailored-Solutions-tab"
              >
                <h3>Tailored Solutions</h3>

                <p className="">
                  We recognize that every business is unique. Our approach to
                  ERP customization is highly personalized, taking into account
                  your specific goals, workflows, and pain points. Whether you
                  need to streamline your supply chain, enhance customer
                  relationship management, or optimize financial reporting, we
                  create solutions that fit seamlessly into your operations.
                </p>
              </div>

              <div
                className="tab-pane fade"
                id="Advanced-Technology"
                role="tabpanel"
                aria-labelledby="Advanced-Technology-tab"
              >
                <h3>Advanced Technology</h3>

                <p className="">
                  Leveraging the latest technologies and best practices, we
                  deliver cutting-edge ERP customizations that provide a
                  competitive edge. Our team stays abreast of the latest
                  developments in ERP systems, ensuring that your solution is
                  not only current but also future-proof.
                </p>
              </div>

              <div
                className="tab-pane fade"
                id="Comprehensive-Integration"
                role="tabpanel"
                aria-labelledby="Comprehensive-Integration-tab"
              >
                <h3>Comprehensive Integration</h3>
                <p className="">
                  We understand the importance of seamless integration between
                  your ERP system and other business applications. Our expertise
                  includes integrating ERP systems with CRM, HRM, SCM, and
                  various other tools, providing a unified and cohesive IT
                  ecosystem that enhances overall efficiency.
                </p>
              </div>
              <div
                className="tab-pane fade"
                id="End-to-End"
                role="tabpanel"
                aria-labelledby="End-to-End-tab"
              >
                <h3>End-to-End Support</h3>
                <p className="">
                  We understand the importance of seamless integration between
                  your ERP system and other business applications. Our expertise
                  includes integrating ERP systems with CRM, HRM, SCM, and
                  various other tools, providing a unified and cohesive IT
                  ecosystem that enhances overall efficiency.
                </p>
              </div>
              <div
                className="tab-pane fade"
                id="Track-Record"
                role="tabpanel"
                aria-labelledby="Track-Record-tab"
              >
                <h3>Proven Track Record</h3>
                <p className="">
                  Our portfolio of successful ERP customization projects speaks
                  for itself. We have helped numerous clients transform their
                  operations, reduce costs, and improve performance through our
                  expertly crafted ERP solutions. Our case studies and client
                  testimonials highlight our dedication to excellence and
                  customer satisfaction.
                </p>
              </div>
              <div
                className="tab-pane fade"
                id="Comprehensive-Training"
                role="tabpanel"
                aria-labelledby="Comprehensive-Training-tab"
              >
                <h3>Comprehensive Training</h3>
                <p className="">
                  We ensure that your team is fully equipped to utilize the
                  customized ERP system through comprehensive training programs.
                  Our training sessions are designed to empower your employees,
                  enhancing their proficiency and maximizing the benefits of the
                  ERP solution.
                </p>
              </div>
            </div>

            <div className="clearboth"></div>
          </div>
          {/* <!-- .tab-area --> */}
          <div className="rs-faq mobile">
            <div className="faq-content">
              <div id="accordion" className="accordion">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header">
                        <a
                          className="card-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="false"
                        >
                          <img src="/assets/images/icons/seo.webp" />
                          <span> SEO</span>
                        </a>
                      </div>
                      <div
                        id="collapseOne"
                        className="collapse"
                        data-bs-parent="#accordion"
                      >
                        <div className="card-body">
                          <h3>SEO</h3>
                          <p className="">
                            Our expert team will enhance your website's search
                            engine ranking through comprehensive solutions. We
                            analyze, strategize, and optimize your content,
                            keywords, and more. With our services, you'll
                            receive implementation, ongoing support, and site
                            reviews. Additionally, our Logistic Management
                            Solution includes on-page and off-page SEO
                            strategies to optimize your website's visibility and
                            performance.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <a
                          className="card-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                        >
                          <img src="/assets/images/icons/sem.webp" />
                          <span>SEM</span>
                        </a>
                      </div>
                      <div
                        id="collapseTwo"
                        className="collapse"
                        data-bs-parent="#accordion"
                      >
                        <div className="card-body">
                          <h3>SEM</h3>
                          <p className="">
                            Discover top-notch techniques and campaigns in our
                            blog to effectively promote products in a
                            competitive market. SEM is a powerful tool for rapid
                            business expansion. Enhance online visibility,
                            attract customers, and achieve growth objectives
                            through impactful search, display, YouTube, and
                            shopping ads.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <a
                          className="card-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                        >
                          <img src="/assets/images/icons/smm.webp" />
                          <span>SMM</span>
                        </a>
                      </div>
                      <div
                        id="collapseThree"
                        className="collapse"
                        data-bs-parent="#accordion"
                      >
                        <div className="card-body">
                          <h3>SMM</h3>
                          <p className="">
                            Engage, grow, and convert your audience with a
                            strategic approach. Enhance messaging, create a
                            unique brand style, and build trust with your target
                            audience on Facebook, Instagram, LinkedIn, and
                            YouTube. Establish your brand as reliable, clear,
                            and easily understandable to achieve social media
                            success.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <a
                          className="card-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="false"
                        >
                          <img src="/assets/images/icons/web-analytics.webp" />
                          <span> Web analytics</span>
                        </a>
                      </div>
                      <div
                        id="collapseFour"
                        className="collapse"
                        data-bs-parent="#accordion"
                      >
                        <div className="card-body">
                          <h3>Web-analytics framework</h3>
                          <p className="">
                            Optimize your online presence with a robust
                            web-analytics framework. Gather, analyze, and report
                            on user data to measure behavior, enhance website
                            flow, and drive business objectives. Leverage
                            powerful tools like the Webmaster Console and Google
                            Analytics for actionable insights and improved
                            conversions.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="major-platforms"
        className="major-platforms flipk pt-20 md-pt-20"
      >
        <div className="container">
          <div className="sec-title3 text-center mb-55 md-mb-30">
            <h2 className="title title2 pb-20">
            ERP Solutions Services
            </h2>
            <span className="border-line">
                <i className="fa fa-diamond" id="diamond"></i>
             </span>
            <p className="margin-0 text-white">
              Come and choose your specific business requirements from our pool
              of expert solutions.
            </p>
            {/* <div class="bar-main">
                  <div class="bar bar-big"></div>
             </div> */}
          </div>
        </div>
      </div>
      <div
        id="ecom-platforms"
        className="ecom-platforms flipk"
      >
          <div className="container-fluid mt-4">
            <TabsComponentErpSol activeKey={activeKey} onSelect={setActiveKey} />
          </div>
          <div>
          <TabContentComponentErpSol activeKey={activeKey} />
          </div>
      </div>
      <div
        id="process-steps"
        className="mob-dev style4 pt-60 pb-60 md-pt-60 md-pb-60 xs-pt-30 xs-pb-30"
      >
        <div className="container-fluid">
          <div className="sec-title3 text-center mb-60">
            <h2 className="title title2 pb-20">
            ERP Solutions Process
            </h2>
            <span className="border-line">
                <i className="fa fa-diamond" id="diamond"></i>
             </span>
            {/* <div class="bar-main">
                  <div class="bar bar-big"></div>
             </div> */}
          </div>

          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="process-steps">
                <div className="process-item bottom">
                    <div className="pt-150 sm-pt-0"></div>
                    <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/design-thinking.webp" className="attachment-full dance_hover" alt="Planning and Analysis" title="Planning and Analysis" />
                        </span>
                    </div>
                    <div className="process-content">
                      <h3 className="title">Planning and Analysis</h3>
                      <p>Conduct a thorough needs assessment to understand business requirements and goals. Define the project scope, select the right ERP vendor, and develop a detailed implementation plan.</p>
                    </div>
               </div>
               <div className="process-item top">
                    <div className="icon-wrapper reverse">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/development.webp" className="attachment-full dance_hover" alt="Design and Configuration" title="Design and Configuration" />
                        </span>
                    </div>
                    <div className="process-content">
                      <h3 className="title">Design and Configuration</h3>
                      <p>Design the ERP system’s architecture and configure it to meet specific business needs. Customize modules, set up workflows, and integrate with existing systems as required.</p>
                    </div>
               </div>
               <div className="process-item bottom">
                   <div className="pt-150 sm-pt-0"></div>
                    <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/qa.webp" className="attachment-full dance_hover" alt="Implementation and Migration" title="Implementation and Migration" />
                        </span>
                    </div>
                    <div className="process-content">
                      <h3 className="title">Implementation and Migration</h3>
                      <p>Deploy the ERP system, migrate data from legacy systems, and integrate with other business applications. Ensure all components are properly configured and functional.</p>
                    </div>
               </div>
               <div className="process-item top">
                    <div className="icon-wrapper reverse">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/startup.webp" className="attachment-full dance_hover" alt="Training and Go-Live" title="Training and Go-Live" />
                        </span>
                    </div>
                    <div className="process-content">
                      <h3 className="title">Training and Go-Live</h3>
                      <p>Train users on the new ERP system to ensure smooth adoption. Officially launch the system and monitor its performance closely during the initial go-live phase.</p>
                    </div>
               </div>
               <div className="process-item">
                   <div className="pt-150 sm-pt-0"></div>
                    <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/qa.webp" className="attachment-full dance_hover" alt="Support and Optimization" title="Support and Optimization" />
                        </span>
                    </div>
                    <div className="process-content">
                      <h3 className="title">Support and Optimization</h3>
                      <p>Provide ongoing support to address any issues and ensure system stability. Continuously evaluate the system’s performance, gather user feedback, and make improvements to optimize functionality and adapt to changing business needs.</p>
                    </div>
               </div>
               </div>
            </div>
          </div>
        </div>
      </div>

      <div className="rs-services main-home industry-solutions services-style1 home-4-style bg-black pt-60 pb-60 md-pt-60 md-pb-60 xs-pt-30 xs-pb-30">
        <div className="container-fluid">
          <div className="sec-title3 text-center mb-65 md-mb-45">
            <h2 className="title pb-20">Our Customization Process</h2>
              <span className="border-line">
                <i className="fa fa-diamond" id="diamond"></i>
             </span>
            {/* <div class="bar-main">
              <div class="bar bar-big"></div>
            </div> */}
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-6 mb-40">
              <div className="services-item">
                <div className="services-icon icon-box">
                  <img
                    src="/assets/images/services/research-and-development.webp"
                    alt="Services"
                  />
                </div>

                <div className="services-text">
                  <h2 className="title">
                    <a>Discovery and Analysis</a>
                  </h2>

                  <p className="services-txt">
                    In-depth assessment of your business needs and objectives
                    <br />
                    Identification of key processes and areas for improvement
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 mb-40">
              <div className="services-item">
                <div className="services-icon icon-box">
                  <img
                    src="/assets/images/services/project.webp"
                    alt="Services"
                  />
                </div>

                <div className="services-text">
                  <h2 className="title">
                    <a>Design and Planning</a>
                  </h2>

                  <p className="services-txt">
                    Development of a customized ERP solution plan
                    <br />
                    Detailed project roadmap with timelines and milestones
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 mb-40">
              <div className="services-item">
                <div className="services-icon icon-box">
                  <img
                    src="/assets/images/services/implementation.webp"
                    alt="Services"
                  />
                </div>

                <div className="services-text">
                  <h2 className="title">
                    <a>Implementation</a>
                  </h2>

                  <p className="services-txt">
                    Seamless integration of the ERP system with your existing
                    infrastructure
                    <br />
                    Customization of modules and features to suit your specific
                    requirements
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 mb-40">
              <div className="services-item">
                <div className="services-icon icon-box">
                  <img
                    src="/assets/images/services/authenticity.webp"
                    alt="Services"
                  />
                </div>

                <div className="services-text">
                  <h2 className="title">
                    <a>Testing and Validation</a>
                  </h2>

                  <p className="services-txt">
                    Rigorous testing to ensure functionality and performance
                    <br />
                    Validation of the customized ERP system against your
                    business needs
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 mb-40">
              <div className="services-item">
                <div className="services-icon icon-box">
                  <img
                    src="/assets/images/services/deployment.webp"
                    alt="Services"
                  />
                </div>

                <div className="services-text">
                  <h2 className="title">
                    <a>Deployment and Training</a>
                  </h2>

                  <p className="services-txt">
                    Smooth deployment of the ERP system with minimal disruption
                    <br />
                    Comprehensive training for your team to ensure effective
                    utilization
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="services-item">
                <div className="services-icon icon-box">
                  <img src="/assets/images/services/gear.webp" alt="Services" />
                </div>

                <div className="services-text">
                  <h2 className="title">
                    <a>Ongoing Support and Maintenance</a>
                  </h2>

                  <p className="services-txt">
                    Continuous monitoring and support to address any issues
                    <br />
                    Regular updates and enhancements to keep your ERP system
                    optimized
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="Digital-marketing-portfolio"
        className="pt-60 pb-60 md-pt-60 sm-pb-60 xs-pt-30 xs-pb-30"
      >
        <div className="container text-center">
          <div className="sec-title3 text-center mb-30 md-mb-30">
            <h2 className="title title2 pb-20">
              Partner with Hilton for Unmatched ERP Customizations
            </h2>
            <span className="border-line">
                <i className="fa fa-diamond" id="diamond"></i>
             </span>
            {/* <div class="bar-main">
              <div class="bar bar-big"></div>
            </div> */}
          </div>
          <p className="text-center text-white">
            At Hilton, we are committed to delivering ERP solutions that drive
            your business forward. Our expertise in ERP customization ensures
            that your system is perfectly tailored to your needs, providing the
            foundation for sustained growth and success. Contact us today to
            learn more about how we can transform your business with our bespoke
            ERP solutions.
          </p>
          <div className="btn-wrap mt-50">
              <a
                target="_self"
                className="fl-button yellow-bg"
                href="/contact"
                tabIndex="0"
                previewlistener="true"
              >
                <span className="fl-button-text">Contact Us</span>
                <i className="fl-button-icon fl-button-icon-after fa fa-long-arrow-right"></i>
                
              </a>
            </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ERPSolutions;
