import Header from "../Header";
import Footer from "../Footer";
import BreadCrums from "../BreadCrums";
import { sendJobRequestEmail } from "./SendMail";
import { useState } from "react";
import MetaTag from "../MetaTag";
import ReCAPTCHA from "react-google-recaptcha";
import { recaptchakey } from "../../Api";

const FullstackDeveloperJob = () => {
  const [successMessage, setSuccessMessage] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [messageType, setMessageType] = useState("");
  const [captchaVerified, setCaptchaVerified] = useState(false);

  const handleCaptchaVerify = () => {
    setCaptchaVerified(true);
  };

  const handleSuccessMessage = (message) => {
    setSuccessMessage(message);
    setShowSuccessMessage(true);
    // Clear the success message after a few seconds
    setTimeout(() => {
      setShowSuccessMessage(false);
      setSuccessMessage("");
    }, 5000); // Adjust the timeout duration as needed
  };
  const MetaTitle = "Full Stack Developer | Join Hilton Software Technologies";
  const MetaDescription =
    "Apply for the Full Stack Developer position at Hilton Software Technologies. Join our innovative team and work on cutting-edge projects with both front-end and back-end technologies. Grow your career with us today!";
  const MetaKeywords =
    "Full Stack Developer job, Full Stack developer careers, developer positions, Full Stack job openings, front-end and back-end development jobs, Hilton Software Technologies, developer careers, Full Stack development jobs, software developer roles";
  const path = window.location.pathname;

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    position: "",
    file: null,
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, file: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!captchaVerified) {
        setMessageType("error");
        handleSuccessMessage("Please complete the captcha.");
        return;
      }
      const successMessage = await sendJobRequestEmail(formData);
      setMessageType(successMessage.type);
      handleSuccessMessage(successMessage.message);
      setFormData({
        name: "",
        email: "",
        phone: "",
        position: "",
        file: null,
        message: "",
      });
    } catch (error) {
      setMessageType("error");
      handleSuccessMessage("Error sending email:");
    }
  };
  return (
    <>
      <MetaTag
        title={MetaTitle}
        description={MetaDescription}
        keywords={MetaKeywords}
        path={path}
      />
      <Header />
      <BreadCrums
        name={"career1"}
        tittle={"Full Stack Developer"}
        watermark={"Careers"}
      />
     
      {/* <!-- Why Choose Section Start --> */}
      <div className="rs-services main-home why-choose services-style1 home-4-style bg-black pt-60 pb-60 md-pt-60 md-pb-60 xs-pt-30 xs-pb-30">
        <div className="container">
          <div className="sec-title3 mb-65 md-mb-45">
            <h2 className="title">Full Stack Developer</h2>
                <div class="bar-main">
                  <div class="bar bar-big left"></div>
                </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-md-12">
                          <div className=" job__overview">
                            <div class="job__overview__content mb-30">
                                <ul class="d-grid grid-style">
                                    <li class="d-flex flex-column gap-3 gap-sm-0 align-items-center justify-content-between">
                                        <div class="d-flex gap-3">
                                            <span class="icon">
                                                <i class="fa fa-calendar"></i>
                                            </span>
                                            <div>
                                                <span class="left-text"> Date Posted</span>
                                                <span class="text">12, October, 2024</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="d-flex flex-column gap-3 gap-sm-0 align-items-center justify-content-between">
                                        <div class="d-flex gap-3">
                                            <span class="icon">
                                                <i class="fa fa-flask"></i>
                                            </span>
                                            <div>
                                                <span class="left-text"> Experience</span>
                                                <span class="text">0 - 1 Years</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="d-flex flex-column gap-3 gap-sm-0 align-items-center justify-content-between">
                                        <div class="d-flex gap-3">
                                            <span class="icon">
                                                <i class="fa fa-graduation-cap"></i>
                                            </span>
                                            <div>
                                                <span class="left-text"> Qualification</span>
                                                <span class="text">Bachelor's Degree</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="d-flex flex-column gap-3 gap-sm-0 align-items-center justify-content-between">
                                        <div class="d-flex gap-3">
                                            <span class="icon">
                                                <i class="fa fa-map-marker"></i>
                                            </span>
                                            <div>
                                                <span class="left-text"> Location</span>
                                                <span class="text">Hyderabad, India</span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                          </div>
                         <div id="description" class="mb-30">
                            <h6 class="fw-semibold mb-20">Job Description</h6>
                            <p>We are looking for a skilled Full Stack Developer with experience in front-end and back-end technologies to join our dynamic team. This is a great opportunity for someone who has recently completed their education or has minimal work experience in web development. The ideal candidate should have basic knowledge of React.js, Node.js, MySQL, and Python, with a willingness to learn and grow in a collaborative environment.</p>
                        </div>
                        <div id="responsibility" class="mb-30">
                            <h6 class="fw-semibold mb-20">Responsibility</h6>
                            <ul class="list-style-dot">
                                <li>Develop and maintain both front-end and back-end components of web applications using React.js, Node.js, MySQL, and Python.</li>
                                <li>Design and implement RESTful APIs for seamless integration between the front-end and back-end systems.</li>
                                <li>Collaborate with UI/UX designers to ensure that technical implementations meet visual and functional expectations.</li>
                                <li>Write clean, maintainable code and ensure best practices are followed, including coding standards, version control (Git), and testing.</li>
                                <li>Troubleshoot and debug existing applications, optimizing performance, and improving usability.</li>
                                <li>Create and maintain databases and perform queries using MySQL to manage data and ensure efficient data storage and retrieval.</li>
                                <li>Implement scalable solutions, ensuring code quality, security, and high-performance architecture.</li>
                                <li>Participate in code reviews and contribute to continuous improvement of the development process.</li>
                                <li>Stay up-to-date with the latest industry trends, technologies, and best practices in full-stack development.</li>
                            </ul>
                        </div>
                        <div id="requirements" class="mb-30">
                            <h6 class="fw-semibold mb-20">Requirements</h6>
                            <ul class="list-style-dot">
                                <li>Basic understanding of front-end technologies: JavaScript, React.js, HTML5, CSS3.</li>
                                <li>Familiarity with back-end technologies, particularly Node.js and Python.</li>
                                <li>Knowledge of MySQL or other relational databases.</li>
                                <li>Problem-solving skills and a passion for learning new technologies.</li>
                                <li>Familiarity with version control systems (e.g., Git) is a plus.</li>
                                <li>Strong communication and teamwork abilities.</li>
                                <li>Strong problem-solving skills and the ability to work independently as well as in a team.</li>
                            </ul>
                        </div>
                        <div id="requirements" class="mb-30">
                            <h6 class="fw-semibold mb-20">Education & Qualifications</h6>
                            <ul class="list-style-dot">
                                <li>Require a Bachelor's Degree in Computer Science or Information Technology (or equivalent).</li>
                            </ul>
                        </div>
                        <div id="skill" class="mb-30">
                            <h6 class="fw-semibold mb-20">Skills</h6>
                            <div class="job__tags job__details__tags">
                                <a href="#" class="job__tag">Html</a>
                                <a href="#" class="job__tag">CSS</a>
                                <a href="#" class="job__tag">Javascript</a>
                                <a href="#" class="job__tag">Bootstrap</a>
                                <a href="#" class="job__tag">React.js</a>
                                <a href="#" class="job__tag">Node.js</a>
                                <a href="#" class="job__tag">Python</a>
                                <a href="#" class="job__tag">SQL/NoSQL</a>
                            </div>
                        </div>
                        {/* <div class="mb-30 text-white">
                        <p><b>To Apply:</b> Send your resume and portfolio to <a href="mailto:info@hiltonglobal.in">info@hiltonglobal.in</a></p>
                        </div> */}
            </div>

          </div>
        </div>
      </div>

    {/* <!-- Why Choose Section Start --> */}


      {/* <!-- Appointment Section Start -->  */}
      <div className="rs-appointment style1 apply-career pt-60 pb-60 xs-pt-30 xs-pb-30">
        <div className="appoint-schedule">
          <div className="row margin-0" id="looking-for-job">
            <div
              className="col-md-12"
              id="Opening-doors-to-exciting-careers"
            >
              <div className="contact-wrap">
                <div className="container">
                <div className="sec-title mb-50 text-center">
                  <h2 className="title white-color">
                    {" "}
                    Opening doors to exciting{" "}
                    <span className="new-text">careers</span>
                  </h2>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-xs-12 mb-30">
                      <div className="form-group">
                        {" "}
                        <label>Name</label>{" "}
                        <input
                          id="name"
                          name="name"
                          className="form-control-mod"
                          type="text"
                          required
                          value={formData.name}
                          onChange={handleChange}
                        />{" "}
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12 mb-30">
                      <div className="form-group">
                        {" "}
                        <label>E-Mail</label>{" "}
                        <input
                          id="email"
                          name="email"
                          className="form-control-mod"
                          type="text"
                          required
                          value={formData.email}
                          onChange={handleChange}
                        />{" "}
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12 mb-30">
                      <div className="form-group">
                        {" "}
                        <label>Phone</label>{" "}
                        <input
                          id="phone"
                          name="phone"
                          className="form-control-mod"
                          type="text"
                          required
                          value={formData.phone}
                          onChange={handleChange}
                        />{" "}
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12 mb-30">
                      <div className="form-group">
                        {" "}
                        <label>Apply position</label>{" "}
                        <input
                          id="position"
                          name="position"
                          className="form-control-mod"
                          type="text"
                          required
                          value={formData.position}
                          onChange={handleChange}
                        />{" "}
                      </div>
                    </div>
                    <div className="col-lg-12 col-sm-6 mb-30">
                      <div className="form-group">
                        {" "}
                        <label>Your resume</label>{" "}
                        <div className="wpcf7-form-control-wrap resume">
                          {" "}
                          <input
                            type="file"
                            id="file"
                            name="file"
                            size="40"
                            className="wpcf7-form-control wpcf7-file wpcf7-validates-as-required"
                            required
                            onChange={handleFileChange}
                          />{" "}
                        </div>{" "}
                      </div>
                    </div>
                    <div className="col-lg-12 mb-35">
                      {" "}
                      <textarea
                        className="from-control"
                        id="message"
                        name="message"
                        placeholder="Your message Here"
                        required
                        value={formData.message}
                        onChange={handleChange}
                      ></textarea>{" "}
                    </div>
                  </div>
                  <>
                    <ReCAPTCHA
                      sitekey={recaptchakey} // Replace with your reCAPTCHA site key
                      onChange={handleCaptchaVerify}
                    />
                  </>
                  <div className="btn-wrap mt-50 text-center">
                    <a
                      target="_self"
                      className="fl-button yellow-bg"
                      href="#"
                      tabIndex="0"
                      previewlistener="true"
                    >
                      <span className="fl-button-text">Submit Now</span>
                      <i className="fl-button-icon fl-button-icon-after fa fa-long-arrow-right"></i>
                      
                    </a>
                  </div>
                </form>
                </div>
              </div>
              <div
                className={`notification ${messageType} ${
                  showSuccessMessage ? "show" : "hide"
                }`}
              >
                {successMessage}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Appointment Section End --> */}
      <Footer />
    </>
  );
};

export default FullstackDeveloperJob;
