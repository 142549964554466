import React from 'react';

const TabContentComponentWebApp = ({ activeKey }) => {
    return (
        <div className="tab-content justify-content-center">
            {activeKey === 'tab1' && 
            <p>
                <div className="yellow-bg pt-150 pb-70 md-pt-60 md-pb-60">
                    <div className="container">
                    <div className="row">
                        <div className="col-lg-6 md-mb-50">
                        <div className="about-content">
                            <div className="images-part">
                            <img
                                src="/assets/images/laravel-img.webp"
                                alt="Images"
                            />
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-6 pl-30 md-pr-l5">
                        <div className="sec-title mb-20">
                           <h2 className="title title3 pb-25">
                            Laravel
                            </h2>
                            <p className="margin-0">
                            Laravel is a powerful PHP framework designed to streamline and accelerate web application development. At Hilton, we utilize Laravel to build robust, scalable, and high-performance web solutions tailored to your needs. Its elegant syntax, intuitive routing, and comprehensive features like Eloquent ORM and built-in authentication enhance development efficiency and security. Laravel’s modular architecture and extensive package ecosystem facilitate rapid development and easy integration. By leveraging Laravel, we create custom web applications that are both secure and scalable, ensuring they meet your current requirements and future growth.{" "}
                            </p>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </p>
            }
            {activeKey === 'tab2' && 
            <p>
            <div className="yellow-bg pt-150 pb-70 md-pt-80 md-pb-60">
                <div className="container">
                <div className="row">
                    <div className="col-lg-6 md-mb-50">
                    <div className="about-content">
                        <div className="images-part">
                        <img
                            src="/assets/images/django-img.webp"
                            alt="Images"
                        />
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-6 pl-30 md-pr-l5">
                    <div className="sec-title mb-20">
                        <h2 className="title title3 pb-25">
                        Django
                        </h2>
                        <p className="margin-0">
                        Django is a high-performance Python web framework that accelerates the development of secure and scalable web applications. At Hilton, we harness Django’s extensive built-in features, such as its powerful ORM, user authentication, and admin interface, to streamline and expedite the development process. Django’s modular structure supports rapid development and easy maintenance, while its robust security measures safeguard against common threats. By leveraging Django’s capabilities, we build reliable, high-quality web applications tailored to your business objectives and ready for future growth.</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </p>
            }
            {activeKey === 'tab3' && 
            <p>
            <div className="yellow-bg pt-150 pb-70 md-pt-80 md-pb-60">
                <div className="container">
                <div className="row">
                    <div className="col-lg-6 md-mb-50">
                    <div className="about-content">
                        <div className="images-part">
                        <img
                            src="/assets/images/angular-img.webp"
                            alt="Images"
                        />
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-6 pl-30 md-pr-l5">
                    <div className="sec-title mb-20">
                        <h2 className="title title3 pb-25">
                        Angular
                        </h2>
                        <p className="margin-0">
                        At Hilton, we use Angular to build scalable, single-page applications with a focus on delivering seamless user experiences. Angular’s modular architecture and two-way data binding simplify the management of complex applications, keeping the user interface and data model in sync. The framework’s built-in features for routing, forms, and HTTP requests streamline development. Regular updates and strong community support ensure our applications remain current with industry standards. With Angular, we create fast, reliable, and adaptable web solutions tailored to your business needs.</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </p>
            }
             {activeKey === 'tab4' && 
            <p>
            <div className="yellow-bg pt-150 pb-70 md-pt-80 md-pb-60">
                <div className="container">
                <div className="row">
                    <div className="col-lg-6 md-mb-50">
                    <div className="about-content">
                        <div className="images-part">
                        <img
                            src="/assets/images/react-native.webp"
                            alt="Images"
                        />
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-6 pl-30 md-pr-l5">
                    <div className="sec-title mb-20">
                        <h2 className="title title3 pb-25">
                        React
                        </h2>
                        <p className="margin-0">
                        At Hilton, we use React to build high-performance web applications with a focus on dynamic, responsive experiences. Its component-based structure allows us to develop modular, reusable code, while the virtual DOM ensures fast rendering and smooth interactions. React’s versatility supports the creation of both simple and complex applications, making it ideal for diverse business needs. We integrate React with modern technologies to deliver scalable, high-quality solutions that enhance user engagement and performance.{" "}
                        </p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </p>
            }
        </div>
    );
};

export default TabContentComponentWebApp;
