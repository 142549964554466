import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';

const TabsComponentErpSol = ({ activeKey, onSelect }) => {
    return (
        <Tabs activeKey={activeKey} onSelect={onSelect} id="tab-component" className='justify-content-center'>
            <Tab eventKey="tab1" title={
                <span>
                    <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/technologies/dynamics365.webp" className="attachment-full" alt="Microsoft Dynamic 365" title="Microsoft Dynamic 365" />
                        </span>
                    </div>
                </span>
            } />
            <Tab eventKey="tab2" title={
                <span>
                    <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/technologies/netsuite.webp" className="attachment-full" alt="NetSuite ERP" title="NetSuite ERP" />
                        </span>
                    </div>
                </span>
            } />
            <Tab eventKey="tab3" title={
                <span>
                    <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/technologies/Infor.webp" className="attachment-full" alt="Infor ERP" title="Infor ERP" />
                        </span>
                    </div>
                </span>
            } />
            <Tab eventKey="tab4" title={
                <span>
                    <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/technologies/epicor.webp" className="attachment-full" alt="Epicor ERP" title="Epicor ERP" />
                        </span>
                    </div>
                </span>
            } />
        </Tabs>
    );
};

export default TabsComponentErpSol;
