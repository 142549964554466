import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';

const TabsComponentMobileApp = ({ activeKey, onSelect }) => {
    return (
        <Tabs activeKey={activeKey} onSelect={onSelect} id="tab-component" className='justify-content-center'>
            <Tab eventKey="tab1" title={
                <span>
                     <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/technologies/front-end-programming.png" className="attachment-full" alt="UI/UX" title="UI/UX" />
                        </span>
                    </div>
                </span>
            } />
            <Tab eventKey="tab2" title={
                <span>
                    <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/technologies/app-development.png" className="attachment-full" alt="Native" title="Native" />
                        </span>
                    </div>
                </span>
            } />
            <Tab eventKey="tab3" title={
                <span>
                    <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/technologies/webapp.png" className="attachment-full" alt="Progressive web Apps" title="Progressive web Apps" />
                        </span>
                    </div>
                </span>
            } />
            <Tab eventKey="tab4" title={
                <span>
                    <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/technologies/swift.png" className="attachment-full" alt="Swift" title="Swift" />
                        </span>
                    </div>
                </span>
            } />
        </Tabs>
    );
};

export default TabsComponentMobileApp;
