import React from 'react';
import './loader.css';

const AppLoader = () => {
  return (
    <div className="app-loader">
      <div className="loader-spin">
      <div class="loader">
        <svg viewBox="0 0 200 200">
          <circle cx="100" cy="100" r="50"></circle>
        </svg>
        <svg viewBox="0 0 200 200">
          <circle cx="100" cy="100" r="50"></circle>
        </svg>
        <svg viewBox="0 0 200 200">
          <circle cx="100" cy="100" r="50"></circle>
        </svg>
      </div>
      </div>
    </div>
  );
};

export default AppLoader;
