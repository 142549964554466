import Fparticles from "./Fparticles";
import ScrollToTopLink from "./ScrollToTopLink";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <>
    

      <footer id="rs-footer" className="rs-footer style1 footer-home5-style">
      <Fparticles />
        <div className="footer-top">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-sm-12 md-mb-10">
                <div className="footer-logo mb-20">
                  <ScrollToTopLink to="/">
                    <img src="/assets/images/hiltonlogo.webp" alt="" />
                  </ScrollToTopLink>
                </div>
                <div className="textwidget white-color pb-30">
                  <p>Hilton Technologies – Delivering cutting-edge digital solutions that empower businesses to innovate and grow across various industries.</p>
                </div>
                <ul className="footer-social md-mb-30">
                  <li>
                    <a
                      href="https://www.facebook.com/hiltonsoftwarecompany/"
                      target="_blank"
                    >
                      <span>
                        <i className="fa fa-facebook"></i>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/Hilton_global" target="_blank">
                      <span>
                        <i className="fa fa-twitter"></i>
                      </span>
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://in.linkedin.com/company/hilton-global"
                      target="_blank"
                    >
                      <span>
                        <i className="fa fa-linkedin"></i>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/hiltonsoftwaretech/"
                      target="_blank"
                    >
                      <span>
                        <i className="fa fa-instagram"></i>
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
             
              <div className="offset-xl-1 col-xl-7 col-lg-8 col-sm-12 md-mb-10">
                <h3 className="footer-title">Our services</h3>
                <ul className="site-map">
                  <li>
                    <ScrollToTopLink to="/services/fullstack-development">
                      Full Stack Development
                    </ScrollToTopLink>
                  </li>
                  <li>
                    <ScrollToTopLink to="/services/e-commerce-development">
                      e-Commerce Development
                    </ScrollToTopLink>
                  </li>
                  <li>
                    <ScrollToTopLink to="/services/mobile-app-development">
                      Mobile Apps Development
                    </ScrollToTopLink>
                  </li>
                  <li>
                    <ScrollToTopLink to="/services/web-app-development">
                      Web Application Development
                    </ScrollToTopLink>
                  </li>
                  <li>
                    <ScrollToTopLink to="/services/hire-a-developer">
                      Hire a Developer
                    </ScrollToTopLink>
                  </li>
                  <li>
                    <ScrollToTopLink to="/services/technical-support">
                      Technical Support
                    </ScrollToTopLink>
                  </li>
                  <li>
                    <ScrollToTopLink to="/services/bi-analytics">
                     Data & Analytics
                    </ScrollToTopLink>
                  </li>
                  <li>
                    <ScrollToTopLink to="/services/ai-ml">
                      AI & ML
                    </ScrollToTopLink>
                  </li>
                  <li>
                    <ScrollToTopLink to="/services/erp-solutions">
                      ERP Solutions
                    </ScrollToTopLink>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 md-mb-10 hidden">
                <h3 className="footer-title">Contact info</h3>
                <ul className="address-widget">
                  <li>
                    <i className="flaticon-location"></i>
                    <div className="desc">
                      INDIA: 8-3-191/17, 2/A, 3rd Floor, Vengala Rao Nagar, S.R
                      Nagar, Hyderabad-38.
                    </div>
                  </li>
                  <li>
                    <i className="flaticon-location"></i>
                    <div className="desc">
                      USA: 3340 walnut avenue, suite #296, Fremont CA - 94538.
                    </div>
                  </li>
                  <li>
                    <i className="flaticon-call"></i>
                    <div className="desc">
                      <a href="tel:(+91)7993606894">(+91)7993606894</a>
                    </div>
                  </li>
                  <li>
                    <i className="flaticon-email"></i>
                    <div className="desc">
                      <a href="mailto:info@hiltonglobal.in">
                        info@hiltonglobal.in
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 mt-30 hidden">
                <div className="row accredited">
                   <div className="sec-title4 mb-1">
                    <h3 className="title text-white">We are accredited by</h3>
                    <p className="text-white">we have been attained a specific qualification including</p>
                    </div>
                   <div className="rs-counter hover-box">
                      <div className="counter-icon">
                        <img
                          src="/assets/images/clients/magento_certified.webp"
                          alt="Images" style={{height: '90px'}}
                        />
                      <img
                          src="/assets/images/zend-php-engineer.webp"
                          alt="Images" style={{height: '70px'}}
                        />
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container-fluid">
            <div className="row y-middle">
              <div className="col-lg-6 md-mb-10 text-lg-end text-center order-last">
                <ul className="copy-right-menu">
                  <li>
                    <ScrollToTopLink to="/privacy-policy">
                      Privacy policy
                    </ScrollToTopLink>
                  </li>
                  <li>
                    <ScrollToTopLink to="/terms-conditions">
                      Terms & conditions
                    </ScrollToTopLink>
                  </li>
                </ul>
              </div>
              <div className="col-lg-6">
                <div className="copyright text-lg-start text-center ">
                  <p>
                    © {currentYear}{" "}
                     All rights reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div className="modal fade search-modal" id="searchModal" tabIndex="-1">
        <button type="button" className="close" data-bs-dismiss="modal">
          <span className="flaticon-cross"></span>
        </button>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="search-block clearfix">
              <form>
                <div className="form-group">
                  <input
                    className="form-control"
                    placeholder="Search Here..."
                    type="text"
                  />
                  <button type="submit" value="Search">
                    <i className="flaticon-search"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer;
