import React from "react";
import { Link } from "react-router-dom";

const ScrollToTopLink = ({ to, children, className }) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Link to={to} className={className} onClick={scrollToTop}>
      {children}
    </Link>
  );
};

export default ScrollToTopLink;
