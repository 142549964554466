import Header from "../Header";
import Footer from "../Footer";
import BreadCrums from "../BreadCrums";
import { sendContactEmail } from "./SendMail";
import { useState } from "react";
import MetaTag from "../MetaTag";
import ReCAPTCHA from "react-google-recaptcha";
import { recaptchakey } from "../../Api";

const GetaQuote = () => {
  const [successMessage, setSuccessMessage] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [messageType, setMessageType] = useState("");
  const [captchaVerified, setCaptchaVerified] = useState(false);

  const handleCaptchaVerify = () => {
    setCaptchaVerified(true);
  };

  const handleSuccessMessage = (message) => {
    setSuccessMessage(message);
    setShowSuccessMessage(true);
    // Clear the success message after a few seconds
    setTimeout(() => {
      setShowSuccessMessage(false);
      setSuccessMessage("");
    }, 5000); // Adjust the timeout duration as needed
  };
  const MetaTitle = "Get a Quote for Your Business Idea | Hilton Software Technologies";
  const MetaDescription =
    "Request a personalized quote from Hilton Technologies for our services. Provide your project details, and our team will respond promptly with a tailored solution to meet your needs and budget.";
  const MetaKeywords =
    "Get a Quote, Hilton Technologies Quote, Custom Quote Request, Service Pricing, Project Estimate, Personalized Quote, Digital Solutions Pricing, Request a Quote, Hilton Technologies Services";
  const path = window.location.pathname;

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    services: "Select service",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!captchaVerified) {
        setMessageType("error");
        handleSuccessMessage("Please complete the captcha.");
        return;
      }
      const successMessage = await sendContactEmail(formData);
      setMessageType(successMessage.type);
      handleSuccessMessage(successMessage.message);
      setFormData({
        name: "",
        email: "",
        phone: "",
        subject: "",
        services: "Select service",
        message: "",
      });
    } catch (error) {
      setMessageType("error");
      handleSuccessMessage("Error sending email:");
    }
  };

  return (
    <>
      <MetaTag
        title={MetaTitle}
        description={MetaDescription}
        keywords={MetaKeywords}
        path={path}
      />
      <Header />

      {/* <!-- Breadcrumbs Start --> */}
      <div class="snowflakes" aria-hidden="true">
      <div class="intro">
      <BreadCrums
        name={"contact1"}
        tittle={"Let's make something awesome together"}
        watermark={"Services"}
      />
      </div>
        <div class="snowflake">
          ❅
          </div>
          <div class="snowflake">
          ❅
          </div>
          <div class="snowflake">
          ❆
          </div>
          <div class="snowflake">
          ❄
          </div>
          <div class="snowflake">
          ❅
          </div>
          <div class="snowflake">
          ❆
          </div>
          <div class="snowflake">
          ❄
          </div>
          <div class="snowflake">
          ❅
          </div>
          <div class="snowflake">
          ❆
          </div>
          <div class="snowflake">
          ❄
          </div>
        </div>
      {/* <!-- Breadcrumbs End --> */}
      <div
        className={`notification ${messageType} ${
          showSuccessMessage ? "show" : "hide"
        }`}
      >
        {successMessage}
      </div>

      <div className="rs-contact contact-style2 pt-60 pb-60 md-pt-60 md-pb-60 xs-pt-30 xs-pb-30">
        <div className="container">
          <div className="row">
            <div className="offset-lg-3 col-lg-6">
              {/* <div className="sec-title mb-25 md-mb-25">
                <h2 className="title" style={{paddingTop:'0'}}>Get in touch</h2>
              </div> */}

              <div className="contact-wrap">
                {/* <div id="form-messages" style={{ fontWeight: "bold" }}></div> */}
                <h3 className="text-center text-white">Get a Quote</h3>
                <form onSubmit={handleSubmit}>
                  <fieldset>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 mb-20">
                        <input
                          className="from-control"
                          type="text"
                          id="name"
                          name="name"
                          placeholder="Name"
                          required=""
                          value={formData.name}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-6 mb-20">
                        <input
                          className="from-control"
                          type="text"
                          id="email"
                          name="email"
                          placeholder="E-Mail"
                          required=""
                          value={formData.email}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-6 mb-20">
                        <input
                          className="from-control"
                          type="text"
                          id="phone"
                          name="phone"
                          placeholder="Phone Number"
                          required=""
                          value={formData.phone}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-6 mb-20">
                        <input
                          className="from-control"
                          type="text"
                          id="Website"
                          name="subject"
                          placeholder="Your Website"
                          required=""
                          value={formData.subject}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="col-lg-12 col-md-12 col-sm-12 mb-20">
                      <select
                          className="from-control"
                          name="services"
                          id="services"
                          placeholder="Select Service"
                          value={formData.services}
                          onChange={handleChange}
                        >
                          <option>Select service</option>
                          <option value="fullstackdevelopment" name="fullstackdevelopment">
                          Full stack development
                          </option>
                          <option value="ecommerce">
                            e-Commerce development
                          </option>
                          <option value="mobileappdevelopment">
                          Mobile App development
                          </option>
                          <option value="webdevelopment" name="webdevelopment">
                            Web App development
                          </option>
                          <option value="hire">Hire a developer</option>
                          <option value="technicalsupport">Technical Support Service</option>
                          <option value="dataanalytics">
                            Data & Analytics
                          </option>
                          <option value="aiml">
                          AI & ML
                          </option>
                          <option value="erpsolutions">ERP Solutions</option>
                        </select>
                      </div>

                      <div className="col-lg-12 mb-20">
                        <textarea
                          className="from-control"
                          id="message"
                          name="message"
                          placeholder="Your message Here"
                          required=""
                          value={formData.message}
                          onChange={handleChange}
                        ></textarea>
                      </div>
                    </div>

                    <>
                      <ReCAPTCHA
                        sitekey={recaptchakey} // Replace with your reCAPTCHA site key
                        onChange={handleCaptchaVerify}
                      />
                    </>
                    <br />
                    <div className="btn-wrap mt-50">
                        <button
                          type="submit"
                          className="submit-btn fl-button yellow-bg"
                          name="submit"
                          id="submit"
                        >
                          <span className="fl-button-text">Submit</span>
                          <i className="fl-button-icon fl-button-icon-after fa fa-long-arrow-right"></i>
                        </button>
                       </div>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </div>
        </div>
        {/* <!-- Contact Icon Section Start --> */}

       
        {/* <!-- Contact Icon Section End --> */}
      
      <Footer />
    </>
  );
};

export default GetaQuote;
