import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';

const TabsComponentTSApp = ({ activeKey, onSelect }) => {
    return (
        <Tabs activeKey={activeKey} onSelect={onSelect} id="tab-component" className='justify-content-center'>
            <Tab eventKey="tab1" title={
                <span>
                    <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/technologies/Installation.png" className="attachment-full" alt="Installation Support" title="Installation Support" />
                        </span>
                    </div>
                </span>
            } />
            <Tab eventKey="tab2" title={
                <span>
                    <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/technologies/Technical-Production.png" className="attachment-full" alt="Technical Production Support" title="Technical Production Support" />
                        </span>
                    </div>
                </span>
            } />
            <Tab eventKey="tab3" title={
                <span>
                    <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/technologies/Server-Database.png" className="attachment-full" alt="Server and Database Support" title="Server and Database Support" />
                        </span>
                    </div>
                </span>
            } />
            <Tab eventKey="tab4" title={
                <span>
                    <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/technologies/Troubleshooting.png" className="attachment-full" alt="Troubleshooting Support" title="Troubleshooting Support" />
                        </span>
                    </div>
                </span>
            } />
        </Tabs>
    );
};

export default TabsComponentTSApp;
