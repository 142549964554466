import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';

const TabsComponentFullStack = ({ activeKey, onSelect }) => {
    return (
        <Tabs activeKey={activeKey} onSelect={onSelect} id="tab-component" className='justify-content-center'>
            <Tab eventKey="tab1" title={
                <span>
                    <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/technologies/user-experience.webp" className="attachment-full" alt="Frontend" title="Frontend" />
                        </span>
                    </div>
                </span>
            } />
            <Tab eventKey="tab2" title={
                <span>
                    <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/technologies/backend.webp" className="attachment-full" alt="Backend" title="Backend" />
                        </span>
                    </div>
                </span>
            } />
            <Tab eventKey="tab3" title={
                <span>
                    <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/technologies/database-file.webp" className="attachment-full" alt="Databases" title="Databases" />
                        </span>
                    </div>
                </span>
            } />
            <Tab eventKey="tab4" title={
                <span>
                    <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/technologies/framework.webp" className="attachment-full" alt="Frameworks" title="Frameworks" />
                        </span>
                    </div>
                </span>
            } />
        </Tabs>
    );
};

export default TabsComponentFullStack;
