import Header from "../Header";
import Footer from "../Footer";
import BreadCrums from "../BreadCrums";
import { sendJobRequestEmail } from "./SendMail";
import { useState } from "react";
import MetaTag from "../MetaTag";
import ReCAPTCHA from "react-google-recaptcha";
import { recaptchakey } from "../../Api";

const TechnicalSupportEngineerJob = () => {
  const [successMessage, setSuccessMessage] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [messageType, setMessageType] = useState("");
  const [captchaVerified, setCaptchaVerified] = useState(false);

  const handleCaptchaVerify = () => {
    setCaptchaVerified(true);
  };

  const handleSuccessMessage = (message) => {
    setSuccessMessage(message);
    setShowSuccessMessage(true);
    // Clear the success message after a few seconds
    setTimeout(() => {
      setShowSuccessMessage(false);
      setSuccessMessage("");
    }, 5000); // Adjust the timeout duration as needed
  };
  const MetaTitle = "Technical Support Engineer | Hilton Software Technologies";
  const MetaDescription =
    "Explore career opportunities as a Technical Support Engineer at Hilton Software Technologies. Join a dynamic team providing innovative solutions, troubleshooting technical issues, and offering expert support to enhance customer satisfaction.";
  const MetaKeywords =
    "Technical Support Engineer, technical support jobs, IT support, troubleshooting, customer support, Hilton Software Technologies, tech career, technical solutions, IT engineer, support services, technical expertise";
  const path = window.location.pathname;

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    position: "",
    file: null,
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, file: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!captchaVerified) {
        setMessageType("error");
        handleSuccessMessage("Please complete the captcha.");
        return;
      }
      const successMessage = await sendJobRequestEmail(formData);
      setMessageType(successMessage.type);
      handleSuccessMessage(successMessage.message);
      setFormData({
        name: "",
        email: "",
        phone: "",
        position: "",
        file: null,
        message: "",
      });
    } catch (error) {
      setMessageType("error");
      handleSuccessMessage("Error sending email:");
    }
  };
  return (
    <>
      <MetaTag
        title={MetaTitle}
        description={MetaDescription}
        keywords={MetaKeywords}
        path={path}
      />
      <Header />
      <BreadCrums
        name={"career1"}
        tittle={"Technical Support Engineer"}
        watermark={"Careers"}
      />
     
      {/* <!-- Why Choose Section Start --> */}
      <div className="rs-services main-home why-choose services-style1 home-4-style bg-black pt-60 pb-60 md-pt-60 md-pb-60 xs-pt-30 xs-pb-30">
        <div className="container">
          <div className="sec-title3 mb-65 md-mb-45">
            <h2 className="title">Technical Support Engineer</h2>
                <div class="bar-main">
                  <div class="bar bar-big left"></div>
                </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12">
                          <div className=" job__overview">
                            <div class="job__overview__content mb-30">
                                <ul class="d-grid grid-style">
                                    <li class="d-flex flex-column gap-3 gap-sm-0 align-items-center justify-content-between">
                                        <div class="d-flex gap-3">
                                            <span class="icon">
                                                <i class="fa fa-calendar"></i>
                                            </span>
                                            <div>
                                                <span class="left-text"> Date Posted</span>
                                                <span class="text">12, October, 2024</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="d-flex flex-column gap-3 gap-sm-0 align-items-center justify-content-between">
                                        <div class="d-flex gap-3">
                                            <span class="icon">
                                                <i class="fa fa-flask"></i>
                                            </span>
                                            <div>
                                                <span class="left-text"> Experience</span>
                                                <span class="text">0 - 1 Years</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="d-flex flex-column gap-3 gap-sm-0 align-items-center justify-content-between">
                                        <div class="d-flex gap-3">
                                            <span class="icon">
                                                <i class="fa fa-graduation-cap"></i>
                                            </span>
                                            <div>
                                                <span class="left-text"> Qualification</span>
                                                <span class="text">Bachelor's Degree</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="d-flex flex-column gap-3 gap-sm-0 align-items-center justify-content-between">
                                        <div class="d-flex gap-3">
                                            <span class="icon">
                                                <i class="fa fa-map-marker"></i>
                                            </span>
                                            <div>
                                                <span class="left-text"> Location</span>
                                                <span class="text">Hyderabad, India</span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                          </div>
                         <div id="description" class="mb-30">
                            <h6 class="fw-semibold mb-20">Job Description</h6>
                            <p>As a Technical Support Engineer, the candidate will work in a stimulating environment that is collaborations-paced and technically innovative. This role requires external-facing client communication, so the candidate will have a professional approach and will be comfortable working with customers across the globe and time zones. We require a go-getter proactive personality with exceptional communication skills.</p>
                        </div>
                        <div id="responsibility" class="mb-30">
                            <h6 class="fw-semibold mb-20">Responsibility</h6>
                            <ul class="list-style-dot">
                                <li> Provide Technical Support to Enterprise customers via web, email, online sessions, and other channels as required i.e. handling technical customer queries day-to-day.</li>
                                <li>Perform problem determination to identify and isolate failure points including hardware, application, customer setup, and/or documentation.</li>
                                <li>Take quick ownership of customer issues (as L1 support) and strive to achieve customer satisfaction/resolution quickly.</li>
                                <li>Follow standard procedures for escalation of unresolved issues to the appropriate internal teams after accurate analysis and issue re-creation process.</li>
                                <li>Research, diagnose, troubleshoot, and identify solutions to resolve non-standard customer issues.</li>
                                <li>Funnel product improvement requests to the engineering development pipeline Drive the resolution to customer issues.</li>
                                <li>Work with sales to effectively handle pre-sales questions, and as appropriate pass sales leads to the Sales team.</li>
                                <li>Champion customer satisfaction.</li>
                            </ul>
                        </div>
                        <div id="requirements" class="mb-30">
                            <h6 class="fw-semibold mb-20">Requirements</h6>
                            <ul class="list-style-dot">
                                <li>Requires 0-1 year of IT and/or technology work experience Or Technical Support work experience (L1/L2)</li>
                                <li>Requires excellent communications skills and presentation skills with the ability to communicate effectively at all levels both within and outside the organization.</li>
                                <li>Knowledge of communication protocols such as I2C, SPI, CAN, or USB is required.</li>
                                <li>Programming/Scripting skills (Eg., C/C++/Python, XML)</li>
                                <li>Prefer prior Embedded Technology Experience providing L1/L2 support.</li>
                                <li>Good knowledge and hands on experience in PC (Personal Computer) configurations (both Software and Hardware) with methods of Troubleshooting.</li>
                                <li>Prefer diverse Operating System experience including Windows, Mac OS X, and Linux OS.</li>
                                <li>Bachelor's degree in Computer Science, Electronics Engineering, or a related field.</li>
                                <li>Certification in Embedded Systems, Python programming, or IT support may be a plus.</li>
                                </ul>
                        </div>
                        <div id="requirements" class="mb-30">
                            <h6 class="fw-semibold mb-20">Education & Qualifications</h6>
                            <ul class="list-style-dot">
                                <li>Require a Bachelor's Degree in Electronics and Communication Engineering (or equivalent)</li>
                                <li>Strongly prefer a Master’s Degree in Electronics and Communication Engineering (or equivalent)</li>
                            </ul>
                        </div>
                        <div id="skill" class="mb-30">
                            <h6 class="fw-semibold mb-20">Skills</h6>
                            <div class="job__tags job__details__tags">
                                <a href="#" class="job__tag">I2C</a>
                                <a href="#" class="job__tag">SPI</a>
                                <a href="#" class="job__tag">CAN</a>
                                <a href="#" class="job__tag">USB</a>
                                <a href="#" class="job__tag">C/C++</a>
                                <a href="#" class="job__tag">Python</a>
                                <a href="#" class="job__tag">XML</a>
                            </div>
                        </div>
                        {/* <div class="mb-30 text-white">
                        <p><b>To Apply:</b> Send your resume and portfolio to <a href="mailto:info@hiltonglobal.in">info@hiltonglobal.in</a></p>
                        </div> */}
            </div>
          </div>
        </div>
      </div>

    {/* <!-- Why Choose Section Start --> */}


      {/* <!-- Appointment Section Start -->  */}
      <div className="rs-appointment style1 apply-career pt-60 pb-60 xs-pt-30 xs-pb-30">
        <div className="appoint-schedule">
          <div className="row margin-0" id="looking-for-job">
            <div
              className="col-md-12"
              id="Opening-doors-to-exciting-careers"
            >
              <div className="contact-wrap">
                <div className="container">
                <div className="sec-title mb-50 text-center">
                  <h2 className="title white-color">
                    {" "}
                    Opening doors to exciting{" "}
                    <span className="new-text">careers</span>
                  </h2>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-xs-12 mb-30">
                      <div className="form-group">
                        {" "}
                        <label>Name</label>{" "}
                        <input
                          id="name"
                          name="name"
                          className="form-control-mod"
                          type="text"
                          required
                          value={formData.name}
                          onChange={handleChange}
                        />{" "}
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12 mb-30">
                      <div className="form-group">
                        {" "}
                        <label>E-Mail</label>{" "}
                        <input
                          id="email"
                          name="email"
                          className="form-control-mod"
                          type="text"
                          required
                          value={formData.email}
                          onChange={handleChange}
                        />{" "}
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12 mb-30">
                      <div className="form-group">
                        {" "}
                        <label>Phone</label>{" "}
                        <input
                          id="phone"
                          name="phone"
                          className="form-control-mod"
                          type="text"
                          required
                          value={formData.phone}
                          onChange={handleChange}
                        />{" "}
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12 mb-30">
                      <div className="form-group">
                        {" "}
                        <label>Apply position</label>{" "}
                        <input
                          id="position"
                          name="position"
                          className="form-control-mod"
                          type="text"
                          required
                          value={formData.position}
                          onChange={handleChange}
                        />{" "}
                      </div>
                    </div>
                    <div className="col-lg-12 col-sm-6 mb-30">
                      <div className="form-group">
                        {" "}
                        <label>Your resume</label>{" "}
                        <div className="wpcf7-form-control-wrap resume">
                          {" "}
                          <input
                            type="file"
                            id="file"
                            name="file"
                            size="40"
                            className="wpcf7-form-control wpcf7-file wpcf7-validates-as-required"
                            required
                            onChange={handleFileChange}
                          />{" "}
                        </div>{" "}
                      </div>
                    </div>
                    <div className="col-lg-12 mb-35">
                      {" "}
                      <textarea
                        className="from-control"
                        id="message"
                        name="message"
                        placeholder="Your message Here"
                        required
                        value={formData.message}
                        onChange={handleChange}
                      ></textarea>{" "}
                    </div>
                  </div>
                  <>
                    <ReCAPTCHA
                      sitekey={recaptchakey} // Replace with your reCAPTCHA site key
                      onChange={handleCaptchaVerify}
                    />
                  </>
                  <div className="btn-wrap mt-50 text-center">
                    <a
                      target="_self"
                      className="fl-button yellow-bg"
                      href="#"
                      tabIndex="0"
                      previewlistener="true"
                    >
                      <span className="fl-button-text">Submit Now</span>
                      <i className="fl-button-icon fl-button-icon-after fa fa-long-arrow-right"></i>
                      
                    </a>
                  </div>
                </form>
                </div>
              </div>
              <div
                className={`notification ${messageType} ${
                  showSuccessMessage ? "show" : "hide"
                }`}
              >
                {successMessage}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Appointment Section End --> */}
      <Footer />
    </>
  );
};

export default TechnicalSupportEngineerJob;
