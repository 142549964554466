import Slider from "react-slick/lib/slider";

const ClientSection = ({ settings }) => {
  return (
    <Slider {...settings}>
      <div className="project-item">
        <div className="project-img">
          <a>
            <img
              className="hovers-logos rs-grid-img"
              src="/assets/images/brands/a2bhomes.webp"
              title=""
              alt=""
            />
            <img
              className="mains-logos rs-grid-img "
              src="/assets/images/brands/a2bhomes.webp"
              title=""
              alt=""
            />
          </a>
        </div>
      </div>
      <div className="project-item">
        <div className="project-img">
          <a>
            <img
              className="hovers-logos rs-grid-img"
              src="/assets/images/brands/bachlr.webp"
              title=""
              alt=""
            />
            <img
              className="mains-logos rs-grid-img "
              src="/assets/images/brands/bachlr.webp"
              title=""
              alt=""
            />
          </a>
        </div>
      </div>
      <div className="project-item">
        <div className="project-img">
          <a>
            <img
              className="hovers-logos rs-grid-img"
              src="/assets/images/brands/WealthGuardLogo.webp"
              title=""
              alt=""
            />
            <img
              className="mains-logos rs-grid-img "
              src="/assets/images/brands/WealthGuardLogo.webp"
              title=""
              alt=""
            />
          </a>
        </div>
      </div>
      <div className="project-item">
        <div className="project-img">
          <a>
            <img
              className="hovers-logos rs-grid-img"
              src="/assets/images/brands/sankhalpam.webp"
              title=""
              alt=""
            />
            <img
              className="mains-logos rs-grid-img "
              src="/assets/images/brands/sankhalpam.webp"
              title=""
              alt=""
            />
          </a>
        </div>
      </div>
      <div className="project-item">
        <div className="project-img">
          <a>
            <img
              className="hovers-logos rs-grid-img"
              src="/assets/images/brands/scoutlogo.webp"
              title=""
              alt=""
            />
            <img
              className="mains-logos rs-grid-img "
              src="/assets/images/brands/scoutlogo.webp"
              title=""
              alt=""
            />
          </a>
        </div>
      </div>
      <div className="project-item">
        <div className="project-img">
          <a>
            <img
              className="hovers-logos rs-grid-img"
              src="/assets/images/brands/Picture1.webp"
              title=""
              alt=""
            />
            <img
              className="mains-logos rs-grid-img "
              src="/assets/images/brands/Picture1.webp"
              title=""
              alt=""
            />
          </a>
        </div>
      </div>
      <div className="project-item">
        <div className="project-img">
          <a>
            <img
              className="hovers-logos rs-grid-img"
              src="/assets/images/brands/Picture2.webp"
              title=""
              alt=""
            />
            <img
              className="mains-logos rs-grid-img "
              src="/assets/images/brands/Picture2.webp"
              title=""
              alt=""
            />
          </a>
        </div>
      </div>
      <div className="project-item">
        <div className="project-img">
          <a>
            <img
              className="hovers-logos rs-grid-img"
              src="/assets/images/brands/Picture3.webp"
              title=""
              alt=""
            />
            <img
              className="mains-logos rs-grid-img "
              src="/assets/images/brands/Picture3.webp"
              title=""
              alt=""
            />
          </a>
        </div>
      </div>
      <div className="project-item">
        <div className="project-img">
          <a>
            <img
              className="hovers-logos rs-grid-img"
              src="/assets/images/brands/Picture4.webp"
              title=""
              alt=""
            />
            <img
              className="mains-logos rs-grid-img "
              src="/assets/images/brands/Picture4.webp"
              title=""
              alt=""
            />
          </a>
        </div>
      </div>
      <div className="project-item">
        <div className="project-img">
          <a>
            <img
              className="hovers-logos rs-grid-img"
              src="/assets/images/brands/Picture5.webp"
              title=""
              alt=""
            />
            <img
              className="mains-logos rs-grid-img "
              src="/assets/images/brands/Picture5.webp"
              title=""
              alt=""
            />
          </a>
        </div>
      </div>
      <div className="project-item">
        <div className="project-img">
          <a>
            <img
              className="hovers-logos rs-grid-img"
              src="/assets/images/brands/Picture6.webp"
              title=""
              alt=""
            />
            <img
              className="mains-logos rs-grid-img "
              src="/assets/images/brands/Picture6.webp"
              title=""
              alt=""
            />
          </a>
        </div>
      </div>
      <div className="project-item">
        <div className="project-img">
          <a>
            <img
              className="hovers-logos rs-grid-img"
              src="/assets/images/brands/Picture7.webp"
              title=""
              alt=""
            />
            <img
              className="mains-logos rs-grid-img "
              src="/assets/images/brands/Picture7.webp"
              title=""
              alt=""
            />
          </a>
        </div>
      </div>
      <div className="project-item">
        <div className="project-img">
          <a>
            <img
              className="hovers-logos rs-grid-img"
              src="/assets/images/brands/Picture8.webp"
              title=""
              alt=""
            />
            <img
              className="mains-logos rs-grid-img "
              src="/assets/images/brands/Picture8.webp"
              title=""
              alt=""
            />
          </a>
        </div>
      </div>
      <div className="project-item">
        <div className="project-img">
          <a>
            <img
              className="hovers-logos rs-grid-img"
              src="/assets/images/brands/Picture9.webp"
              title=""
              alt=""
            />
            <img
              className="mains-logos rs-grid-img "
              src="/assets/images/brands/Picture9.webp"
              title=""
              alt=""
            />
          </a>
        </div>
      </div>
      <div className="project-item">
        <div className="project-img">
          <a>
            <img
              className="hovers-logos rs-grid-img"
              src="/assets/images/brands/Picture10.webp"
              title=""
              alt=""
            />
            <img
              className="mains-logos rs-grid-img "
              src="/assets/images/brands/Picture10.webp"
              title=""
              alt=""
            />
          </a>
        </div>
      </div>
      <div className="project-item">
        <div className="project-img">
          <a>
            <img
              className="hovers-logos rs-grid-img"
              src="/assets/images/brands/Picture11.webp"
              title=""
              alt=""
            />
            <img
              className="mains-logos rs-grid-img "
              src="/assets/images/brands/Picture11.webp"
              title=""
              alt=""
            />
          </a>
        </div>
      </div>
      <div className="project-item">
        <div className="project-img">
          <a>
            <img
              className="hovers-logos rs-grid-img"
              src="/assets/images/brands/Picture12.webp"
              title=""
              alt=""
            />
            <img
              className="mains-logos rs-grid-img "
              src="/assets/images/brands/Picture12.webp"
              title=""
              alt=""
            />
          </a>
        </div>
      </div>
      <div className="project-item">
        <div className="project-img">
          <a>
            <img
              className="hovers-logos rs-grid-img"
              src="/assets/images/brands/Picture15.webp"
              title=""
              alt=""
            />
            <img
              className="mains-logos rs-grid-img "
              src="/assets/images/brands/Picture15.webp"
              title=""
              alt=""
            />
          </a>
        </div>
      </div>
      <div className="project-item">
        <div className="project-img">
          <a>
            <img
              className="hovers-logos rs-grid-img"
              src="/assets/images/brands/Picture16.webp"
              title=""
              alt=""
            />
            <img
              className="mains-logos rs-grid-img "
              src="/assets/images/brands/Picture16.webp"
              title=""
              alt=""
            />
          </a>
        </div>
      </div>
      <div className="project-item">
        <div className="project-img">
          <a>
            <img
              className="hovers-logos rs-grid-img"
              src="/assets/images/brands/globex.webp"
              title=""
              alt=""
            />
            <img
              className="mains-logos rs-grid-img "
              src="/assets/images/brands/globex.webp"
              title=""
              alt=""
            />
          </a>
        </div>
      </div>
      <div className="project-item">
        <div className="project-img">
          <a>
            <img
              className="hovers-logos rs-grid-img"
              src="/assets/images/brands/Picture19.webp"
              title=""
              alt=""
            />
            <img
              className="mains-logos rs-grid-img "
              src="/assets/images/brands/Picture19.webp"
              title=""
              alt=""
            />
          </a>
        </div>
      </div>
      <div className="project-item">
        <div className="project-img">
          <a>
            <img
              className="hovers-logos rs-grid-img"
              src="/assets/images/brands/Picture20.webp"
              title=""
              alt=""
            />
            <img
              className="mains-logos rs-grid-img "
              src="/assets/images/brands/Picture20.webp"
              title=""
              alt=""
            />
          </a>
        </div>
      </div>
      <div className="project-item">
        <div className="project-img">
          <a>
            <img
              className="hovers-logos rs-grid-img"
              src="/assets/images/brands/Picture21.webp"
              title=""
              alt=""
            />
            <img
              className="mains-logos rs-grid-img "
              src="/assets/images/brands/Picture21.webp"
              title=""
              alt=""
            />
          </a>
        </div>
      </div>
      <div className="project-item">
        <div className="project-img">
          <a>
            <img
              className="hovers-logos rs-grid-img"
              src="/assets/images/brands/Picture22.webp"
              title=""
              alt=""
            />
            <img
              className="mains-logos rs-grid-img "
              src="/assets/images/brands/Picture22.webp"
              title=""
              alt=""
            />
          </a>
        </div>
      </div>
      <div className="project-item">
        <div className="project-img">
          <a>
            <img
              className="hovers-logos rs-grid-img"
              src="/assets/images/brands/A.webp"
              title=""
              alt=""
            />
            <img
              className="mains-logos rs-grid-img "
              src="/assets/images/brands/A.webp"
              title=""
              alt=""
            />
          </a>
        </div>
      </div>
      <div className="project-item">
        <div className="project-img">
          <a>
            <img
              className="hovers-logos rs-grid-img"
              src="/assets/images/brands/10.webp"
              title=""
              alt=""
            />
            <img
              className="mains-logos rs-grid-img "
              src="/assets/images/brands/10.webp"
              title=""
              alt=""
            />
          </a>
        </div>
      </div>
    </Slider>
  );
};

export default ClientSection;
