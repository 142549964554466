import Header from "../Header";
import Footer from "../Footer";
import BreadCrums from "../BreadCrums";
import { sendJobRequestEmail } from "./SendMail";
import { useState } from "react";
import MetaTag from "../MetaTag";
import ReCAPTCHA from "react-google-recaptcha";
import { recaptchakey } from "../../Api";

const JobDetails = () => {
  const [successMessage, setSuccessMessage] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [messageType, setMessageType] = useState("");
  const [captchaVerified, setCaptchaVerified] = useState(false);

  const handleCaptchaVerify = () => {
    setCaptchaVerified(true);
  };

  const handleSuccessMessage = (message) => {
    setSuccessMessage(message);
    setShowSuccessMessage(true);
    // Clear the success message after a few seconds
    setTimeout(() => {
      setShowSuccessMessage(false);
      setSuccessMessage("");
    }, 5000); // Adjust the timeout duration as needed
  };
  const MetaTitle = "Careers | Hilton";
  const MetaDescription =
    "Discover the story behind our company and learn about our mission, values, and commitment to providing top-quality solutions.";
  const MetaKeywords =
    "Python application development services, AI-ML development services, Devops services, Server maintenance, Hire Magento Developers, e-commerce development services, Full stack development services,web application development services, custom application development services,sever support and maintenance, AWS solution archtecture services, Cloud support and services,technical experience,technical strengths";

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    position: "",
    file: null,
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, file: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!captchaVerified) {
        setMessageType("error");
        handleSuccessMessage("Please complete the captcha.");
        return;
      }
      const successMessage = await sendJobRequestEmail(formData);
      setMessageType(successMessage.type);
      handleSuccessMessage(successMessage.message);
      setFormData({
        name: "",
        email: "",
        phone: "",
        position: "",
        file: null,
        message: "",
      });
    } catch (error) {
      setMessageType("error");
      handleSuccessMessage("Error sending email:");
    }
  };
  return (
    <>
      <MetaTag
        title={MetaTitle}
        description={MetaDescription}
        keywords={MetaKeywords}
      />
      <Header />
      <BreadCrums
        name={"career1"}
        tittle={"Frontend Software Engineer"}
        watermark={"Careers"}
      />
     
      {/* <!-- Why Choose Section Start --> */}
      <div className="rs-services main-home why-choose services-style1 home-4-style bg-black pt-60 pb-60 md-pt-60 md-pb-60 xs-pt-30 xs-pb-30">
        <div className="container">
          <div className="sec-title3 mb-65 md-mb-45">
            <h2 className="title">Job Overview</h2>
                <div class="bar-main">
                  <div class="bar bar-big left"></div>
                </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-md-12">
                          <div className=" job__overview">
                            <div class="job__overview__content mb-30">
                                <ul class="d-grid grid-style">
                                    <li class="d-flex flex-column gap-3 gap-sm-0 align-items-center justify-content-between">
                                        <div class="d-flex gap-3">
                                            <span class="icon">
                                                <i class="fa fa-calendar"></i>
                                            </span>
                                            <div>
                                                <span class="left-text"> Date Posted</span>
                                                <span class="text">10,July, 2024</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="d-flex flex-column gap-3 gap-sm-0 align-items-center justify-content-between">
                                        <div class="d-flex gap-3">
                                            <span class="icon">
                                                <i class="fa fa-flask"></i>
                                            </span>
                                            <div>
                                                <span class="left-text"> Experience</span>
                                                <span class="text">5 Years</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="d-flex flex-column gap-3 gap-sm-0 align-items-center justify-content-between">
                                        <div class="d-flex gap-3">
                                            <span class="icon">
                                                <i class="fa fa-graduation-cap"></i>
                                            </span>
                                            <div>
                                                <span class="left-text"> Qualification</span>
                                                <span class="text">Bachelor Degree</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="d-flex flex-column gap-3 gap-sm-0 align-items-center justify-content-between">
                                        <div class="d-flex gap-3">
                                            <span class="icon">
                                                <i class="fa fa-map-marker"></i>
                                            </span>
                                            <div>
                                                <span class="left-text"> Location</span>
                                                <span class="text">Hyderabad, India</span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                          </div>
                         <div id="description" class="mb-30">
                            <h6 class="fw-semibold mb-20">Job Description</h6>
                            <p>We are seeking a skilled Part-Time Software Engineer to join our team, specializing in
                                social media content creation for lead generation purposes. The ideal candidate will
                                have a creative flair, technical proficiency, and a strong understanding of social media
                                trends and algorithms. Must be able to work Monday-Friday during EST business hours.
                                This role will be under the ScaledOn brand, but will be working directly with one of our
                                partners as their dedicated Software Engineer.</p>
                        </div>
                        <div id="responsibility" class="mb-30">
                            <h6 class="fw-semibold mb-20">Responsibility</h6>
                            <ul class="list-style-dot">
                                <li>Design and build web and enterprise application using in ReactJS/Next JS/.Net Core
                                </li>
                                <li>Collaborate with cross-functional teams to analyze, design, and implement new
                                    features.</li>
                                <li>Follow defined coding rules/conventions defined by the company.</li>
                                <li>Perform Unit test and ensure proper test coverage as per organizational standard.
                                </li>
                                <li>Prepare basic design, detail design, execute basic acceptance testing.</li>
                                <li>Follow review process for peer review to code delivery.</li>
                                <li>Participate in defined meeting as per company policy.</li>
                                <li>Senior Software Engineer should pose mindset and ability to lead small team.</li>
                            </ul>
                        </div>
                        <div id="requirements" class="mb-30">
                            <h6 class="fw-semibold mb-20">Requirements</h6>
                            <ul class="list-style-dot">
                                <li>Excellent knowledge of Relational Databases MYSQL and ORM technologies (JPA,
                                    Hibernate).</li>
                                <li>Strong understanding on Object-Oriented analysis and design using common design
                                    patterns.</li>
                                <li>Need to know advanced in ReactJS/Next JS/.Net Core.</li>
                                <li>Practical experience in REST &amp; RESTful web services.</li>
                                <li>Commanding knowledge on Maven, Gradle build tools.</li>
                                <li>Follow review process for peer review to code delivery.</li>
                                <li>Participate in defined meeting as per company policy.</li>
                                <li>Senior Software Engineer should pose mindset and ability to lead small team.</li>
                            </ul>
                        </div>
                        <div id="skill" class="mb-30">
                            <h6 class="fw-semibold mb-20">Skills and Experience</h6>
                            <div class="job__tags job__details__tags">
                                <a href="#" class="job__tag">Javascript</a>
                                <a href="#" class="job__tag">user interface</a>
                                <a href="#" class="job__tag">Problem Solving</a>
                            </div>
                        </div>

            </div>

          </div>
        </div>
      </div>

    {/* <!-- Why Choose Section Start --> */}


      {/* <!-- Appointment Section Start -->  */}
      <div className="rs-appointment style1 apply-career pt-60 pb-60 xs-pt-30 xs-pb-30">
        <div className="appoint-schedule">
          <div className="row margin-0" id="looking-for-job">
            <div
              className="col-md-7 pt-20 pb-20 pr-20 pl-20 hidden"
              id="PHP-Magento-Developer"
              style={{ backgroundColor: "#f6f6f6" }}

              //   style="background-color:#f6f6f6;"
            >
              <div className="tab-area">
                <ul className="nav nav-tabs mb-20" role="tablist">
                  <li className="nav-item" role="presentation">
                    {" "}
                    <button
                      className="nav-link active"
                      id="phpdev-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#phpdev"
                      type="button"
                      role="tab"
                      aria-controls="phpdev"
                      aria-selected="true"
                    >
                      Php developers
                    </button>{" "}
                  </li>
                  <li className="nav-item" role="presentation">
                    {" "}
                    <button
                      className="nav-link"
                      id="magentodev-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#magentodev"
                      type="button"
                      role="tab"
                      aria-controls="magentodev"
                      aria-selected="false"
                    >
                      Magento developers
                    </button>{" "}
                  </li>
                </ul>
                <div className="tab-content">
                  <div
                    className="tab-pane fade show active"
                    id="phpdev"
                    role="tabpanel"
                    aria-labelledby="phpdev-tab"
                  >
                    <h3 className="h5 font-bold mb-sm">
                      Looking for PHP developers
                    </h3>
                    <h3 className="h6 mb-sm">
                      Location: <span>Hyderabad</span>
                    </h3>
                    <h3 className="h6 mb-sm">
                      Job: <span>Full-time</span>
                    </h3>
                    <h3 className="h6 mb-sm">Job description:</h3>
                    <p>
                      As a member of this team, you will develop new features
                      and other backend services required by the software
                      applications and sites.
                    </p>
                    <h3 className="h6 mb-sm">Skills required:</h3>
                    <ul className="disc-list">
                      <li>Must have experience working with PHP</li>
                      <li>Framework Laravel</li>
                      <li>
                        Excellent working knowledge and experience in
                        object-oriented Web application development
                      </li>
                      <li>
                        Great coding skills in PHP, MySQL, HTML, CSS, BootStrap
                        JavaScript, jQuery, Ajax, JSON, Angular (preferable)
                      </li>
                      <li>
                        Excellent working knowledge of MySQL database with good
                        understanding of tuning database performance and sql
                        queries
                      </li>
                      <li>
                        Good knowledge and understanding of eCommerce, payment
                        gateway & other API integration like Google APIs
                      </li>
                      <li>
                        Ability to work with minimal supervision, troubleshoot,
                        fix and test functionality with a passion to learn and
                        grow
                      </li>
                      <li>Problem solving, analytical skills</li>
                      <li>Good knowledge of version control tools</li>
                      <li>
                        Good exposure to configuration management, testing,
                        deployment
                      </li>
                      <li>
                        Team player, friendly, self-motivated, quick &
                        self-learner and be able to work autonomously
                      </li>
                      <li>
                        Excellent communication skills & positive working
                        attitude
                      </li>
                      <li>Let's get it done attitude.</li>
                    </ul>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="magentodev"
                    role="tabpanel"
                    aria-labelledby="magentodev-tab"
                  >
                    <h3 className="h5 font-bold mb-sm">
                      Looking for Magento developers
                    </h3>
                    <h3 className="h6 mb-sm">
                      Location: <span>Hyderabad</span>
                    </h3>
                    <h3 className="h6 mb-sm">
                      Job: <span>Full-time</span>
                    </h3>
                    <h3 className="h6 mb-sm">Job description:</h3>
                    <p>
                      A professional with 3+ years of experience on PHP based
                      applications, focusing on e-Commerce implementation.
                    </p>
                    <h3 className="h6 mb-sm">Technical skills required:</h3>
                    <ul className="disc-list">
                      <li>Must have experience working with PHP</li>
                      <li>Magento</li>
                      <li>Zend</li>
                      <li>Web Services</li>
                      <li>JQuery</li>
                      <li>Ajax</li>
                      <li>MySQL / Oracle / PostgreSQL</li>
                    </ul>
                    <h3 className="h6 mb-sm pt-20">Secondary skills:</h3>
                    <ul className="disc-list">
                      <li>
                        Must have strong oral and written communication skills,
                        and be customer focused to understand and appropriately
                        respond to customers' business needs.
                      </li>
                      <li>
                        Demonstrated critical thinking skills, ability to
                        accurately analyze information and make sound decisions.
                      </li>
                      <li>Team management</li>
                      <li>Performance optimizations of application</li>
                    </ul>
                    <h3 className="h6 mb-sm pt-20">Key responsibilities:</h3>
                    <ul className="disc-list">
                      <li>
                        Understanding of business requirements and the process
                        of translating them into well-engineered and integrated
                        technical solutions and reusable components.
                      </li>
                      <li>
                        Interface effectively and collaborate with clients,
                        peers, and management to develop solutions and ensure
                        stakeholder buy-in.
                      </li>
                      <li>
                        Managing multiple client projects focusing on ecommerce
                        implementation.
                      </li>
                      <li>
                        Setting up process for code quality & coding practices.
                        Coach and mentor programmers.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* <!-- .tab-area --> */}
            </div>
            <div
              className="col-md-12"
              id="Opening-doors-to-exciting-careers"
            >
              <div className="contact-wrap">
                <div className="container">
                <div className="sec-title mb-50 text-center">
                  <h2 className="title white-color">
                    {" "}
                    Opening doors to exciting{" "}
                    <span className="new-text">careers</span>
                  </h2>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-xs-12 mb-30">
                      <div className="form-group">
                        {" "}
                        <label>Name</label>{" "}
                        <input
                          id="name"
                          name="name"
                          className="form-control-mod"
                          type="text"
                          required
                          value={formData.name}
                          onChange={handleChange}
                        />{" "}
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12 mb-30">
                      <div className="form-group">
                        {" "}
                        <label>E-Mail</label>{" "}
                        <input
                          id="email"
                          name="email"
                          className="form-control-mod"
                          type="text"
                          required
                          value={formData.email}
                          onChange={handleChange}
                        />{" "}
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12 mb-30">
                      <div className="form-group">
                        {" "}
                        <label>Phone</label>{" "}
                        <input
                          id="phone"
                          name="phone"
                          className="form-control-mod"
                          type="text"
                          required
                          value={formData.phone}
                          onChange={handleChange}
                        />{" "}
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-12 mb-30">
                      <div className="form-group">
                        {" "}
                        <label>Apply position</label>{" "}
                        <input
                          id="position"
                          name="position"
                          className="form-control-mod"
                          type="text"
                          required
                          value={formData.position}
                          onChange={handleChange}
                        />{" "}
                      </div>
                    </div>
                    <div className="col-lg-12 col-sm-6 mb-30">
                      <div className="form-group">
                        {" "}
                        <label>Your resume</label>{" "}
                        <div className="wpcf7-form-control-wrap resume">
                          {" "}
                          <input
                            type="file"
                            id="file"
                            name="file"
                            size="40"
                            className="wpcf7-form-control wpcf7-file wpcf7-validates-as-required"
                            required
                            onChange={handleFileChange}
                          />{" "}
                        </div>{" "}
                      </div>
                    </div>
                    <div className="col-lg-12 mb-35">
                      {" "}
                      <textarea
                        className="from-control"
                        id="message"
                        name="message"
                        placeholder="Your message Here"
                        required
                        value={formData.message}
                        onChange={handleChange}
                      ></textarea>{" "}
                    </div>
                  </div>
                  <>
                    <ReCAPTCHA
                      sitekey={recaptchakey} // Replace with your reCAPTCHA site key
                      onChange={handleCaptchaVerify}
                    />
                  </>
                  <div className="btn-wrap mt-50 text-center">
                    <a
                      target="_self"
                      className="fl-button yellow-bg"
                      href="#"
                      tabIndex="0"
                      previewlistener="true"
                    >
                      <span className="fl-button-text">Submit Now</span>
                      <i className="fl-button-icon fl-button-icon-after fa fa-long-arrow-right"></i>
                      
                    </a>
                  </div>
                </form>
                </div>
              </div>
              <div
                className={`notification ${messageType} ${
                  showSuccessMessage ? "show" : "hide"
                }`}
              >
                {successMessage}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Appointment Section End --> */}
      <Footer />
    </>
  );
};

export default JobDetails;
