import React from "react";

const BreadCrums = ({ name, tittle, watermark }) => {
  return (
    <div className={`rs-breadcrumbs ${name}`}>
      <div className="container-fluid">
        <div className="breadcrumbs-inner">
          <h1 className="page-title">
            {tittle}
            <span className="watermark">{watermark}</span>
          </h1>
        </div>
      </div>
    </div>
  );
};

export default BreadCrums;
