import React from 'react';

const TabContentComponent = ({ activeKey }) => {
    return (
        <div className="tab-content justify-content-center">
            {activeKey === 'tab1' && 
            <p>
                <div className="yellow-bg pt-150 pb-70 md-pt-60 md-pb-60">
                    <div className="container">
                    <div className="row">
                        <div className="col-lg-6 md-mb-50">
                        <div className="about-content">
                            <div className="images-part">
                            <img
                                src="/assets/images/magento.webp"
                                alt="Images"
                            />
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-6 pl-30 md-pr-l5">
                        <div className="sec-title mb-20">
                           <h2 className="title title3 pb-25">
                             Magento
                            </h2>
                            <p className="margin-0">
                            Our Magento development services provide you with the tools to build a fully customized and high-performing online store. Whether you're looking to create a brand-new site or optimize your existing platform, our skilled developers will craft a solution tailored to your business objectives. We focus on delivering a seamless user experience, robust security, and smooth integrations to ensure your store operates efficiently and effectively. With Magento, we help you adapt to evolving market demands, scale your business and achieve lasting success in the digital marketplace.{" "}
                            </p>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </p>
            }
            {activeKey === 'tab2' && 
            <p>
            <div className="yellow-bg pt-150 pb-70 md-pt-80 md-pb-60">
                <div className="container">
                <div className="row">
                    <div className="col-lg-6 md-mb-50">
                    <div className="about-content">
                        <div className="images-part">
                        <img
                            src="/assets/images/shopify-img.png"
                            alt="Images"
                        />
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-6 pl-30 md-pr-l5">
                    <div className="sec-title mb-20">
                        <h2 className="title title3 pb-25">
                         Shopify
                        </h2>
                        <p className="margin-0">
                        Our Shopify development services provide the perfect foundation for your online store's success. We specialize in building custom Shopify solutions that are tailored to your unique business needs. Whether you’re launching a new store or enhancing your current setup, our team delivers a seamless, user-friendly experience that resonates with your customers. From custom themes and feature-rich integrations to optimized performance and scalability, we leverage Shopify’s powerful platform to create a store that drives sales and supports long-term growth. Trust us to help you unlock the full potential of Shopify and position your brand for success in the competitive e-commerce landscape.{" "}
                        </p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </p>
            }
            {activeKey === 'tab3' && 
            <p>
            <div className="yellow-bg pt-150 pb-70 md-pt-80 md-pb-60">
                <div className="container">
                <div className="row">
                    <div className="col-lg-6 md-mb-50">
                        <div className="about-content">
                            <div className="images-part">
                            <img
                                src="/assets/images/woocommerce-img.png"
                                alt="Images"
                            />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 pl-30 md-pr-l5">
                    <div className="sec-title mb-20">
                        <h2 className="title title3 pb-25">
                            Woo-Commerce
                        </h2>
                        <p className="margin-0">
                        Take your online store to new heights with our tailored WooCommerce development services. WooCommerce is an ideal platform for businesses seeking flexibility, functionality, and scalability. Our experienced team customizes every aspect of WooCommerce to align with your business goals, ensuring a smooth, engaging shopping experience for your customers. From advanced features to seamless third-party integrations, we optimize WooCommerce to enhance performance and drive sales. Whether you’re launching a new store or upgrading an existing one, our WooCommerce solutions are designed to support your growth and keep you competitive in the ever-evolving e-commerce landscape.{" "}
                        </p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </p>
            }
             {activeKey === 'tab4' && 
            <p>
            <div className="yellow-bg pt-150 pb-70 md-pt-80 md-pb-60">
                <div className="container">
                <div className="row">
                    <div className="col-lg-6 md-mb-50">
                    <div className="about-content">
                        <div className="images-part">
                        <img
                            src="/assets/images/bigcommerce.png"
                            alt="Images"
                        />
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-6 pl-30 md-pr-l5">
                    <div className="sec-title mb-20">
                        <h2 className="title title3 pb-25">
                            Big-Commerce
                        </h2>
                        <p className="margin-0">
                        Our expert team is adept at customizing BigCommerce solutions to meet your specific requirements, ensuring that your online store is both functional and visually compelling. We handle everything from integrating advanced features and optimizing performance to designing an intuitive user experience that drives engagement and conversions. With our BigCommerce development services, you gain access to cutting-edge tools and strategies that streamline your operations and enhance your digital presence. Whether you’re launching a new store or upgrading an existing one, we’re here to support your growth and help you achieve your business goals with ease.{" "}
                        </p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </p>
            }
        </div>
    );
};

export default TabContentComponent;
