import React from 'react';

const TabContentComponentMobileApp = ({ activeKey }) => {
    return (
        <div className="tab-content justify-content-center">
            {activeKey === 'tab1' && 
            <p>
                <div className="yellow-bg pt-150 pb-70 md-pt-80 md-pb-60">
                <div className="container">
                <div className="row">
                    <div className="col-lg-6 md-mb-50">
                    <div className="about-content">
                        <div className="images-part">
                        <img
                            src="/assets/images/uiux.webp"
                            alt="Images"
                        />
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-6 pl-30 md-pr-l5">
                    <div className="sec-title mb-20">
                        <h2 className="title title3 pb-25">
                        UI/UX
                        </h2>
                        <p className="margin-0">
                        UI/UX Design is crucial for developing software that excels in both aesthetics and usability. At Hilton, we create visually compelling user interfaces (UI) that are easy to navigate and engaging. Our user experience (UX) design process involves thorough research and testing to refine the user journey, ensuring smooth and intuitive interactions. We focus on aligning design with user needs to deliver an application that is both visually attractive and functionally efficient, enhancing overall satisfaction and usability.</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </p>
            }
            {activeKey === 'tab2' && 
            <p>
            <div className="yellow-bg pt-150 pb-70 md-pt-80 md-pb-60">
                <div className="container">
                <div className="row">
                    <div className="col-lg-6 md-mb-50">
                    <div className="about-content">
                        <div className="images-part">
                        <img
                            src="/assets/images/nativeapp.webp"
                            alt="Images"
                        />
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-6 pl-30 md-pr-l5">
                    <div className="sec-title mb-20">
                        <h2 className="title title3 pb-25">
                        Native
                        </h2>
                        <p className="margin-0">
                        Native mobile apps ensure a top-notch user experience and peak performance by being finely tuned to the unique features of iOS and Android systems. Cross-platform apps require only one codebase to create apps for multiple platforms, offering versatility and convenience. Choose the right approach based on your needs and target audience, ensuring optimal reach and engagement for your mobile app.</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </p>
            }
            {activeKey === 'tab3' && 
            <p>
            <div className="yellow-bg pt-150 pb-70 md-pt-60 md-pb-60">
                    <div className="container">
                    <div className="row">
                        <div className="col-lg-6 md-mb-50">
                        <div className="about-content">
                            <div className="images-part">
                            <img
                                src="/assets/images/pwebapp.webp"
                                alt="Images"
                            />
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-6 pl-30 md-pr-l5">
                        <div className="sec-title mb-20">
                           <h2 className="title title3 pb-25">
                            Progressive web Apps
                            </h2>
                            <p className="margin-0">
                            Our progressive web applications offer unparalleled user experiences through cutting-edge technology. Our skilled team provides top-notch mobile app development services at a competitive price, guaranteeing seamless performance and a native-like interface. As a top-tier software development firm, we craft exceptional mobile and progressive web apps, uniquely tailored to align with your business goals.{" "}
                            </p>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
        </p>
            }
             {activeKey === 'tab4' && 
            <p>
            <div className="yellow-bg pt-150 pb-70 md-pt-80 md-pb-60">
                <div className="container">
                <div className="row">
                    <div className="col-lg-6 md-mb-50">
                    <div className="about-content">
                        <div className="images-part">
                        <img
                            src="/assets/images/swiftapp.webp"
                            alt="Images"
                        />
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-6 pl-30 md-pr-l5">
                    <div className="sec-title mb-20">
                        <h2 className="title title3 pb-25">
                        Swift
                        </h2>
                        <p className="margin-0">
                        Swift is Apple’s premier programming language for developing high-performance, native applications on iOS, macOS, watchOS, and tvOS. With its modern syntax, Swift enhances code clarity and reduces development time. Its advanced safety features, such as optional types and automatic memory management, prevent common coding errors and improve app stability. Swift’s seamless integration with Objective-C allows for flexible development and legacy code utilization. Regular updates ensure Swift incorporates the latest advancements, keeping projects cutting-edge.{" "}
                        </p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </p>
            }
        </div>
    );
};

export default TabContentComponentMobileApp;
