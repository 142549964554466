import Header from "../Header";
import Footer from "../Footer";
import BreadCrums from "../BreadCrums";
import React, { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MetaTag from "../MetaTag";
import TabsComponentBiApp from "./TabsComponentBiApp";
import TabContentComponentBiApp from "./TabContentComponentBiApp";

const BIAnalytics = () => {
  const MetaTitle =
    "Advanced Data & Analytics Solutions | Hilton Software Technologies";
  const MetaDescription =
    "Discover tailored BI Analytics solutions at Hilton Software Technologies Pvt. Ltd. We specialize in creating custom BI systems designed to enhance business insights and streamline data-driven decision-making. Contact us for expert consultation today!";
  const MetaKeywords =
    "Data & Analytics solutions, custom BI systems, business insights, data-driven decision-making, Hilton Software Technologies, BI consulting, tailored data solutions, business intelligence, expert consultation";
  const path = window.location.pathname;

  const [activeKey, setActiveKey] = useState('tab1');
  return (
    <>
      <MetaTag
        title={MetaTitle}
        description={MetaDescription}
        keywords={MetaKeywords}
        path={path}
      />
      <Header />
      <BreadCrums
        name={"bi"}
        tittle={"Data & Analytics"}
        watermark={"Data & Analytics"}
      />
      <div id="" className="fulfill-ecommerce pt-60 pb-30 md-pt-60 md-pb-30 xs-pt-30 xs-pb-30">
        <div className="container-fluid custom">
          <div className="sec-title3 text-center mb-30 md-mb-30">
            <h1 className="title title2 pb-20">Data Insights and Analytics Solution</h1>
            <span className="border-line">
                <i className="fa fa-diamond" id="diamond"></i>
             </span>
          </div>
          <div className="row y-middle text-white">
          <div className="offset-lg-1 col-lg-10 col-md-12">
          <p className="ft16" style={{ textAlign: "center" }}>
            At Hilton, our Data and Analytics services help businesses turn complex data into actionable insights. We offer comprehensive solutions for data collection, storage, analysis, and visualization, ensuring your data is transformed into a valuable asset for decision-making.
            </p>
            <p className="ft16" style={{ textAlign: "center" }}>
            Our team integrates leading analytics platforms like Power BI and Microsoft Fabric to build tailored dashboards that provide real-time, easy-to-understand insights. Whether it’s predictive analytics, trend analysis, or performance tracking, we help you uncover patterns and make data-driven decisions.
            </p>
            <p className="ft16" style={{ textAlign: "center" }}>
            With our expertise, businesses can optimize operations, improve customer experiences, and achieve sustainable growth through smarter data utilization.
            </p>
            </div>
          </div>
        </div>
      </div>

      <div
        id="operational-ecommerce"
        className="rs-single-shop vertical operational-ecommerce  pt-20 pb-60 md-pt-60 sm-pb-68-0 hidden"
      >
        <div className="container">
          <div className="sec-title text-center mb-55 md-mb-30">
            <h2 className="title title2">Our BI Analytics Services</h2>
          </div>

          <div className="tab-area desktop">
            <ul className="nav nav-tabs" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="Integration-Management-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Integration-Management"
                  type="button"
                  role="tab"
                  aria-controls="Integration-Management"
                  aria-selected="true"
                >
                  <img src="/assets/images/icons/seo.webp" /> Data Integration
                  and Management
                </button>
              </li>

              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="Data-Visualization-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Data-Visualization"
                  type="button"
                  role="tab"
                  aria-controls="Data-Visualization"
                  aria-selected="true"
                >
                  <img src="/assets/images/icons/sem.webp" />
                  Data Visualization
                </button>
              </li>

              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="Advanced-Analytics-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Advanced-Analytics"
                  type="button"
                  role="tab"
                  aria-controls="Advanced-Analytics"
                  aria-selected="false"
                >
                  <img src="/assets/images/icons/smm.webp" />
                  Advanced Analytics
                </button>
              </li>

              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="Reporting-Performance-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Reporting-Performance"
                  type="button"
                  role="tab"
                  aria-controls="Reporting-Performance"
                  aria-selected="false"
                >
                  <img src="/assets/images/icons/web-analytics.webp" />{" "}
                  Reporting and Performance Management
                </button>
              </li>
            </ul>

            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id="Integration-Management"
                role="tabpanel"
                aria-labelledby="Integration-Management-tab"
              >
                <h3>Data Integration and Management</h3>
                <p className="">
                  Efficient data management is the foundation of effective BI
                  analytics. We help you integrate data from various sources,
                  ensuring it is clean, consistent, and ready for analysis.
                </p>
                <ul>
                  <li> Data warehousing</li>
                  <li> ETL (Extract, Transform, Load) processes</li>
                  <li> Data quality and governance</li>
                </ul>
              </div>

              <div
                className="tab-pane fade show"
                id="Data-Visualization"
                role="tabpanel"
                aria-labelledby="Data-Visualization-tab"
              >
                <h3>Data Visualization</h3>

                <p className="">
                  Our data visualization solutions turn complex data sets into
                  intuitive and interactive dashboards and reports. This makes
                  it easier for stakeholders to understand and act on insights.
                </p>
                <ul>
                  <li> Custom dashboards</li>
                  <li> Interactive reports</li>
                  <li> Real-time data visualization</li>
                </ul>
              </div>

              <div
                className="tab-pane fade"
                id="Advanced-Analytics"
                role="tabpanel"
                aria-labelledby="Advanced-Analytics-tab"
              >
                <h3>Advanced Analytics</h3>

                <p className="">
                  Leverage advanced analytics techniques such as predictive
                  analytics, machine learning, and AI to uncover hidden patterns
                  and gain deeper insights into your business operations.
                </p>
                <ul>
                  <li>Predictive analytics</li>
                  <li>Machine learning models</li>
                  <li>AI-driven insights</li>
                </ul>
              </div>

              <div
                className="tab-pane fade"
                id="Reporting-Performance"
                role="tabpanel"
                aria-labelledby="Reporting-Performance-tab"
              >
                <h3>Reporting and Performance Management</h3>

                <p className="">
                  We provide robust reporting solutions that enable you to track
                  key performance indicators (KPIs) and measure the success of
                  your strategies in real-time.
                </p>
                <ul>
                  <li>KPI tracking</li>
                  <li>Performance dashboards</li>
                  <li>Custom reporting solutions</li>
                </ul>
              </div>
            </div>

            <div className="clearboth"></div>
          </div>
          {/* <!-- .tab-area --> */}
          <div className="rs-faq mobile">
            <div className="faq-content">
              <div id="accordion" className="accordion">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header">
                        <a
                          className="card-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="false"
                        >
                          <img src="/assets/images/icons/seo.webp" />
                          <span> SEO</span>
                        </a>
                      </div>
                      <div
                        id="collapseOne"
                        className="collapse"
                        data-bs-parent="#accordion"
                      >
                        <div className="card-body">
                          <h3>SEO</h3>
                          <p className="">
                            Our expert team will enhance your website's search
                            engine ranking through comprehensive solutions. We
                            analyze, strategize, and optimize your content,
                            keywords, and more. With our services, you'll
                            receive implementation, ongoing support, and site
                            reviews. Additionally, our Logistic Management
                            Solution includes on-page and off-page SEO
                            strategies to optimize your website's visibility and
                            performance.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <a
                          className="card-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                        >
                          <img src="/assets/images/icons/sem.webp" />
                          <span>SEM</span>
                        </a>
                      </div>
                      <div
                        id="collapseTwo"
                        className="collapse"
                        data-bs-parent="#accordion"
                      >
                        <div className="card-body">
                          <h3>SEM</h3>
                          <p className="">
                            Discover top-notch techniques and campaigns in our
                            blog to effectively promote products in a
                            competitive market. SEM is a powerful tool for rapid
                            business expansion. Enhance online visibility,
                            attract customers, and achieve growth objectives
                            through impactful search, display, YouTube, and
                            shopping ads.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <a
                          className="card-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                        >
                          <img src="/assets/images/icons/smm.webp" />
                          <span>SMM</span>
                        </a>
                      </div>
                      <div
                        id="collapseThree"
                        className="collapse"
                        data-bs-parent="#accordion"
                      >
                        <div className="card-body">
                          <h3>SMM</h3>
                          <p className="">
                            Engage, grow, and convert your audience with a
                            strategic approach. Enhance messaging, create a
                            unique brand style, and build trust with your target
                            audience on Facebook, Instagram, LinkedIn, and
                            YouTube. Establish your brand as reliable, clear,
                            and easily understandable to achieve social media
                            success.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <a
                          className="card-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="false"
                        >
                          <img src="/assets/images/icons/web-analytics.webp" />
                          <span> Web analytics</span>
                        </a>
                      </div>
                      <div
                        id="collapseFour"
                        className="collapse"
                        data-bs-parent="#accordion"
                      >
                        <div className="card-body">
                          <h3>Web-analytics framework</h3>
                          <p className="">
                            Optimize your online presence with a robust
                            web-analytics framework. Gather, analyze, and report
                            on user data to measure behavior, enhance website
                            flow, and drive business objectives. Leverage
                            powerful tools like the Webmaster Console and Google
                            Analytics for actionable insights and improved
                            conversions.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="major-platforms"
        className="major-platforms flipk pt-20 md-pt-20"
      >
        <div className="container">
          <div className="sec-title3 text-center mb-55 md-mb-30">
            <h2 className="title title2 pb-20">
              Data & Analytics Services
            </h2>
            <span className="border-line">
                <i className="fa fa-diamond" id="diamond"></i>
             </span>
            <p className="margin-0 text-white">
              Come and choose your specific business requirements from our pool
              of expert solutions.
            </p>
           
          </div>
        </div>
      </div>
      <div
        id="ecom-platforms"
        className="ecom-platforms flipk"
      >
          <div className="container-fluid mt-4">
            <TabsComponentBiApp activeKey={activeKey} onSelect={setActiveKey} />
          </div>
          <div>
          <TabContentComponentBiApp activeKey={activeKey} />
          </div>
      </div>
      <div
        id="process-steps"
        className="mob-dev style4 pt-60 pb-60 md-pt-60 md-pb-60 xs-pt-30 xs-pb-30"
      >
        <div className="container-fluid">
          <div className="sec-title3 text-center mb-60">
            <h2 className="title title2 pb-20">
              Data & Analytics Process
            </h2>
            <span className="border-line">
                <i className="fa fa-diamond" id="diamond"></i>
             </span>
            {/* <div class="bar-main">
                  <div class="bar bar-big"></div>
             </div> */}
          </div>

          <div className="row">
            <div className="col-lg-12 col-md-12 md-mb-30">
              <div className="process-steps">
                <div className="process-item bottom">
                    <div className="pt-150 sm-pt-0"></div>
                    <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/design-thinking.webp" className="attachment-full dance_hover" alt="Data Collection" title="Data Collection" />
                        </span>
                    </div>
                    <div className="process-content">
                      <h3 className="title">Data Collection</h3>
                      <p>Collect raw data from various sources such as databases, APIs, surveys, and sensors.</p>
                    </div>
               </div>
               <div className="process-item top">
                    <div className="icon-wrapper reverse">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/development.webp" className="attachment-full dance_hover" alt="Data Integration" title="Data Integration" />
                        </span>
                    </div>
                    <div className="process-content">
                      <h3 className="title">Data Integration</h3>
                      <p>Remove duplicates, correct errors, and handle missing values to ensure data quality.</p>
                    </div>
               </div>
               <div className="process-item bottom">
                   <div className="pt-150 sm-pt-0"></div>
                    <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/qa.webp" className="attachment-full dance_hover" alt="Data Storage" title="Data Storage" />
                        </span>
                    </div>
                    <div className="process-content">
                      <h3 className="title">Data Storage</h3>
                      <p>Utilize storage solutions for large volumes of unstructured or semi-structured data, allowing for flexible analysis.</p>
                    </div>
               </div>
               <div className="process-item top">
                    <div className="icon-wrapper reverse">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/startup.webp" className="attachment-full dance_hover" alt="Data Processing" title="Data Processing" />
                        </span>
                    </div>
                    <div className="process-content">
                      <h3 className="title">Data Processing & Analysis</h3>
                      {/* <p>Design and build data models to structure data in a way that supports analysis and reporting.</p> */}
                      <p>Design and build data models for analysis and reporting, and create visual dashboards for easy interpretation.</p>
                    </div>
               </div>
               {/* <div className="process-item bottom">
                   <div className="pt-150 sm-pt-0"></div>
                    <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/qa.webp" className="attachment-full dance_hover" alt="Data Analysis" title="Data Analysis" />
                        </span>
                    </div>
                    <div className="process-content">
                      <h3 className="title">Data Analysis</h3>
                      <p>Create charts, graphs, and dashboards to represent data visually and facilitate easier interpretation.</p>
                    </div>
               </div> */}
               <div className="process-item bottom">
               <div className="pt-150 sm-pt-0"></div>
                    <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/startup.webp" className="attachment-full dance_hover" alt="Data Governance" title="Data Governance" />
                        </span>
                    </div>
                    <div className="process-content">
                      <h3 className="title">Data Governance</h3>
                      <p>Ensure data is protected through access controls, encryption, and other security measures.</p>
                    </div>
               </div>
               </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rs-services main-home industry-solutions services-style1 home-4-style bg-black pt-60 pb-60 md-pt-60 md-pb-60 xs-pt-30 xs-pb-30">
        <div className="container-fluid">
          <div className="sec-title3 text-center mb-65 md-mb-45">
            <h2 className="title pb-20">Industry Solutions</h2>
              <span className="border-line">
                <i className="fa fa-diamond" id="diamond"></i>
             </span>
            <p className="text-white">
              Our BI analytics services are designed to cater to various
              industries, each with its unique data challenges and
              opportunities.
            </p>
            {/* <div className="heading-border-line"></div> */}
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-6 mb-40">
              
              <div className="services-item">
                <div className="services-icon icon-box">
                  <img
                    src="/assets/images/services/production.webp"
                    alt="Services"
                  />
                </div>

                <div className="services-text">
                  <h2 className="title">
                    <a>Manufacturing</a>
                  </h2>

                  <p className="services-txt">
                    Optimize production processes, manage supply chains, and
                    improve product quality with data-driven insights tailored
                    to the manufacturing industry.
                  </p>
{/* 
                  <div className="serial-number">01</div> */}
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 mb-40">
              <div className="services-item">
                <div className="services-icon icon-box">
                  <img
                    src="/assets/images/services/retailer.webp"
                    alt="Services"
                  />
                </div>

                <div className="services-text">
                  <h2 className="title">
                    <a>Retail</a>
                  </h2>

                  <p className="services-txt">
                    Enhance customer experiences, optimize inventory, and boost
                    sales with powerful BI tools designed for the retail sector.
                  </p>

                  <div className="serial-number">02</div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 mb-40">
              <div className="services-item">
                <div className="services-icon icon-box">
                  <img
                    src="/assets/images/services/healthcare.webp"
                    alt="Services"
                  />
                </div>

                <div className="services-text">
                  <h2 className="title">
                    <a>Healthcare</a>
                  </h2>

                  <p className="services-txt">
                    Improve patient care, manage resources, and ensure
                    compliance with our specialized BI analytics solutions for
                    healthcare providers.
                  </p>

                  <div className="serial-number">03</div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 mb-40">
              <div className="services-item">
                <div className="services-icon icon-box">
                  <img
                    src="/assets/images/services/investment.webp"
                    alt="Services"
                  />
                </div>

                <div className="services-text">
                  <h2 className="title">
                    <a>Finance</a>
                  </h2>

                  <p className="services-txt">
                    Make informed financial decisions, manage risks, and ensure
                    regulatory compliance with our comprehensive BI analytics
                    services for the finance industry.
                  </p>

                  <div className="serial-number">04</div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="services-item">
                <div className="services-icon icon-box">
                  <img
                    src="/assets/images/services/delivery.webp"
                    alt="Services"
                  />
                </div>

                <div className="services-text">
                  <h2 className="title">
                    <a>Logistics</a>
                  </h2>

                  <p className="services-txt">
                    Streamline operations, reduce costs, and improve delivery
                    performance with our BI analytics solutions for the
                    logistics sector.
                  </p>

                  <div className="serial-number">03</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rs-estimate hire-technical relative pt-60 pb-60 md-pt-60 md-mt-0 md-pb-60 xs-pt-30 xs-pb-30 hidden">
        <div className="container">
          <div className="row rs-vertical-middle">
            <div className="col-lg-6">
              <div className="image-part">
                <img src="/assets/images/support.webp" alt="" />
              </div>
            </div>

            <div className="col-lg-6 md-pt-50">
              <div className="sec-title4 mb-36">
                <h2 className="title">Why Choose Us?</h2>
              </div>
              <ul className="estimate-info">
                <li>
                  <h5 className="mb-10 title">
                    <i className="fa fa-thumbs-o-up"></i> Expertise and
                    Experience
                  </h5>
                  <p>
                    Our team of BI analytics experts has extensive experience
                    across various industries. We bring the latest technologies
                    and best practices to deliver solutions that drive real
                    business value.
                  </p>
                </li>

                <li>
                  <h5 className="mb-10 title">
                    <i className="fa fa-thumbs-o-up"></i> Customized Solutions
                  </h5>
                  <p>
                    We understand that every business is unique. Our BI
                    analytics solutions are tailored to meet your specific
                    needs, ensuring you get the most relevant and actionable
                    insights.
                  </p>
                </li>

                <li>
                  <h5 className="mb-10 title">
                    <i className="fa fa-thumbs-o-up"></i> End-to-End Support
                  </h5>
                  <p>
                    From initial consultation and implementation to ongoing
                    support and optimization, we are committed to ensuring your
                    success at every stage of your BI journey.
                  </p>
                </li>

                <li>
                  <h5 className="mb-10 title">
                    <i className="fa fa-thumbs-o-up"></i> Proven Track Record
                  </h5>
                  <p>
                    Our portfolio of successful BI analytics projects and
                    satisfied clients speaks to our ability to deliver
                    high-quality solutions that meet and exceed expectations.
                  </p>
                </li>
              </ul>
            </div>
          </div>

          <img
            className="pattern-img"
            src="/assets/images/pattern/pattern4.webp"
            alt=""
          />
        </div>
      </div>
      <div
        id="Digital-marketing-portfolio"
        className="pt-60 pb-60 md-pt-60 sm-pb-60 xs-pt-30 xs-pb-30 darkgray-bg"
      >
        <div className="container text-center text-white">
          <div className="sec-title3 text-center mb-30 md-mb-30">
            <h2 className="title title2 text-white">Get Started Today !</h2>
            {/* <span className="border-line">
                <i className="fa fa-diamond" id="diamond"></i>
             </span> */}
            <div class="bar-main">
                  <div class="bar bar-big"></div>
             </div>
          </div>
          <p className="text-center">
            Unlock the full potential of your data with our BI analytics
            solutions. Contact us today for a consultation and learn how we can
            help you turn data into your most valuable asset.
          </p>
          <div className="btn-wrap mt-50">
              <a
                target="_self"
                className="fl-button yellow-bg"
                href="/contact"
                tabIndex="0"
                previewlistener="true"
              >
                <span className="fl-button-text">Contact Us</span>
                <i className="fl-button-icon fl-button-icon-after fa fa-long-arrow-right"></i>
                
              </a>
            </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BIAnalytics;
