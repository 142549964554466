import Header from "../Header";
import Footer from "../Footer";
import BreadCrums from "../BreadCrums";
import { sendJobRequestEmail } from "./SendMail";
import { useState } from "react";
import MetaTag from "../MetaTag";
import ReCAPTCHA from "react-google-recaptcha";
import { recaptchakey } from "../../Api";

const About = () => {
  const [successMessage, setSuccessMessage] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [messageType, setMessageType] = useState("");
  const [captchaVerified, setCaptchaVerified] = useState(false);

  const handleCaptchaVerify = () => {
    setCaptchaVerified(true);
  };

  const handleSuccessMessage = (message) => {
    setSuccessMessage(message);
    setShowSuccessMessage(true);
    // Clear the success message after a few seconds
    setTimeout(() => {
      setShowSuccessMessage(false);
      setSuccessMessage("");
    }, 5000); // Adjust the timeout duration as needed
  };
  const MetaTitle = "Software Development Company | Website Development Services | Hilton";
  const MetaDescription =
    "Learn more about Hilton Technologies, a leading provider of innovative digital solutions. Our mission is to empower businesses through advanced technologies, exceptional service, and a commitment to excellence.";
  const MetaKeywords =
    "About Hilton Technologies, Digital Solutions Provider, Company Mission, Innovative Technology, Business Empowerment, Company Values, Meet Our Team, Technology Experts, Service Excellence, Corporate Profile";
  const path = window.location.pathname;
  
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    position: "",
    file: null,
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, file: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!captchaVerified) {
        setMessageType("error");
        handleSuccessMessage("Please complete the captcha.");
        return;
      }
      const successMessage = await sendJobRequestEmail(formData);
      setMessageType(successMessage.type);
      handleSuccessMessage(successMessage.message);
      setFormData({
        name: "",
        email: "",
        phone: "",
        position: "",
        file: null,
        message: "",
      });
    } catch (error) {
      setMessageType("error");
      handleSuccessMessage("Error sending email:");
    }
  };
  return (
    <>
      <MetaTag
        title={MetaTitle}
        description={MetaDescription}
        keywords={MetaKeywords}
        path={path}
      />
      <Header />
      
      <div class="snowflakes" aria-hidden="true">
        <div class="intro">
        <BreadCrums
          name={"about1"}
          tittle={"Delivering business advantage through proven experience"}
          watermark={"Company"}
        />
        </div>
        <div class="snowflake">
          ❅
          </div>
          <div class="snowflake">
          ❅
          </div>
          <div class="snowflake">
          ❆
          </div>
          <div class="snowflake">
          ❄
          </div>
          <div class="snowflake">
          ❅
          </div>
          <div class="snowflake">
          ❆
          </div>
          <div class="snowflake">
          ❄
          </div>
          <div class="snowflake">
          ❅
          </div>
          <div class="snowflake">
          ❆
          </div>
          <div class="snowflake">
          ❄
          </div>
        </div>
      <div
        id="rs-about"
        className="rs-about style3 pt-60 pb-60 md-pt-60 md-pb-60 xs-pt-30 xs-pb-30"
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 md-mb-50 text-center">
              <div className="about-content">
              <div className="rs-animations">
                  <div className="spinner bulb">
                    <img
                      className="up-down"
                      src="/assets/images/about/bulb.webp"
                      alt="Images"
                    />
                  </div>
                </div>
                <div className="images-part">
                  <img
                    src="/assets/images/about/about-us.webp"
                    alt="Images"
                  />
                </div>
                <div className="rs-animations">
                  <div className="spinner ball">
                    <img
                      className="dance2"
                      src="/assets/images/about/home3/2.webp"
                      alt="Images"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 pl-30 md-pr-l5">
              <div className="sec-title mb-20 text-white">
                <span className="sub-text small">About us</span>
                <h1 className="title title3 text-white">
                  Hilton software technologies
                </h1>
                <div class="bar-main">
                  <div class="bar bar-big left"></div>
                </div>
                <p className="margin-0">
                  At Hilton, we have been providing personalized it solutions to
                  accelerate business growth since our establishment in 2013.
                  Our premium, tailored services include product development,
                  e-Commerce, CMS, and web/mobile application development, as
                  well as other key areas such as AI, ML, and deep learning. Our
                  unrivaled customer support and vast experience with small,
                  medium, and large companies worldwide make us the go-to for
                  professional IT services. Our commitment to delivering optimal
                  solutions, no matter the company size or location, sets us
                  apart. Contact us today to learn how we can help you achieve
                  your growth goals.{" "}
                </p>
                   
              </div>
              <div id="accordion" className="accordion hidden">
                <div className="card hidden">
                  <div className="card-header">
                    <a
                      className="collapsed card-link"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                    >
                      Why choose us?
                    </a>
                  </div>
                  <div
                    id="collapseOne"
                    className="collapse"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      At Hilton software technologies, tailored IT solutions for
                      growth objectives, quality commitment, and exceptional
                      service make us the trusted choice.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <a
                      className="collapsed card-link"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                    >
                      Our vision
                    </a>
                  </div>
                  <div
                    id="collapseTwo"
                    className="collapse"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      Empowering business growth through innovative IT solutions
                      and exceptional customer service.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <a
                      className="collapsed card-link"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                    >
                      Our mission
                    </a>
                  </div>
                  <div
                    id="collapseThree"
                    className="collapse"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      Our goal is to offer innovative and trustworthy IT solutions that fuel business advancement and achievement.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 pl-30 mt-20">
            <div className="mt-20" style={{float:'left', height:'120px', marginRight:'20px'}}>
                      <img
                        src="/assets/images/vision.png"
                        alt="images" style={{width:'80px'}}
                      />
                    </div>
                    <div className="mt-20 text-white">
                      <h4 className="mb-0 text-white">
                        <a>Vision</a>
                      </h4>
                       <p>
                      Empowering business growth through innovative IT solutions and exceptional customer service.
                      </p>
                    </div>
             </div>
            <div className="col-lg-6 pl-30 mt-20">
              <div style={{float:'left', height:'120px', marginRight:'20px'}}>
                      <img
                        src="/assets/images/mission.png"
                        alt="images" style={{width:'80px'}}
                      />
                    </div>
                    <div className="mt-20 text-white">
                      <h4 className="mb-0 text-white">
                        <a>Our Mission</a>
                      </h4>
                       <p>Our goal is to offer innovative and trustworthy IT solutions that fuel business advancement and achievement.</p>
                    </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="process-steps"
        className="pmanagement-approach pt-60 pb-60 md-pt-60 md-pb-60 xs-pt-30 xs-pb-30 hidden"
      >
        <div className="container-fluid">
          <div className="sec-title text-center mb-60">
            <h2 className="title title2 pb-20 text-white">
              Our Proven Project Management Approach
            </h2>
            <span className="border-line">
            <i className="fa fa-diamond" id="diamond"></i>
            </span>
            {/* <div class="bar-main">
                  <div class="bar bar-big"></div>
             </div> */}
          </div>

          <div className="row">
            <div className="col-lg-12 col-md-12 md-mb-30">
              <div className="process-steps text-white">
                <div className="process-item bottom">
                    <div className="pt-150 sm-pt-0"></div>
                    <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/design-thinking.webp" className="attachment-full dance_hover" alt="Analysis & Design" title="Analysis & Design" />
                        </span>
                    </div>
                    <div className="process-content">
                      <h3 className="title">Analysis & Design</h3>
                      <p>Our UX/UI designers create intuitive app designs that prioritize user engagement and seamless navigation, ensuring your app stands out.</p>
                    </div>
               </div>
               <div className="process-item top">
                    <div className="icon-wrapper reverse">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/development.webp" className="attachment-full dance_hover" alt="Development" title="Development" />
                        </span>
                    </div>
                    <div className="process-content">
                      <h3 className="title">Development</h3>
                      <p>Our developers build the app using the latest technologies, ensuring fast load times, smooth transitions, and an optimized mobile experience.</p>
                    </div>
               </div>
               <div className="process-item bottom">
                   <div className="pt-150 sm-pt-0"></div>
                    <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/qa.webp" className="attachment-full dance_hover" alt="Quality Assurance & Testing" title="Quality Assurance & Testing" />
                        </span>
                    </div>
                    <div className="process-content">
                      <h3 className="title">Quality Assurance & Testing</h3>
                      <p>Rigorous testing is conducted to ensure the app performs well across different devices and operating systems, guaranteeing a bug-free user experience.</p>
                    </div>
               </div>
               <div className="process-item">
                    <div className="icon-wrapper reverse">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/startup.webp" className="attachment-full dance_hover" alt="Launch & Support" title="Launch & Support" />
                        </span>
                    </div>
                    <div className="process-content">
                      <h3 className="title">Launch & Support</h3>
                      <p>Post-launch, we provide ongoing updates and support to ensure the app remains up-to-date with the latest features and security standards.</p>
                    </div>
               </div>
               </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="rs-process style1 pt-60 pb-60 md-pt-60 md-pb-60 xs-pt-30 xs-pb-30 hidden"
        style={{ backgroundColor: "#111" }}
        // style="background-color:#111;"
      >
        <div className="container">
          <div className="row y-middle">
            <div className="col-lg-12">
              <div className="sec-title text-center mb-30">
                <h2 className="title title2 white-color">
                  Our Proven Project Management Approach
                </h2>
                <div class="bar-main">
                  <div class="bar bar-big"></div>
               </div>
              </div>
            </div>
          </div>
        </div>
        <div id="project-management-approach" className="container custom2">
          <div className="process-effects-layer">
            <div className="row">
              <div className="col-lg-2 col-md-4 md-mb-30">
                <div className="rs-addon-number">
                  <div className="number-part">
                    <div className="number-image">
                      <img
                        src="/assets/images/process/style1/analysis.webp"
                        alt="Process"
                      />
                    </div>
                    <div className="number-text">
                      <div className="number-area">
                        {" "}
                        <span className="number-prefix"> 1 </span>
                      </div>
                      <div className="number-title">
                        <h3 className="title"> Analysis</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 md-mb-30">
                <div className="rs-addon-number">
                  <div className="number-part">
                    <div className="number-image">
                      <img
                        src="/assets/images/process/style1/design.webp"
                        alt="Process"
                      />
                    </div>
                    <div className="number-text">
                      <div className="number-area">
                        {" "}
                        <span className="number-prefix"> 2 </span>
                      </div>
                      <div className="number-title">
                        <h3 className="title">Design</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 sm-mb-30">
                <div className="rs-addon-number">
                  <div className="number-part">
                    <div className="number-image">
                      <img
                        src="/assets/images/process/style1/coding.webp"
                        alt="Process"
                      />
                    </div>
                    <div className="number-text">
                      <div className="number-area">
                        {" "}
                        <span className="number-prefix"> 3 </span>
                      </div>
                      <div className="number-title">
                        <h3 className="title">Coding</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 sm-mb-30">
                <div className="rs-addon-number">
                  <div className="number-part">
                    <div className="number-image">
                      <img
                        src="/assets/images/process/style1/testing.webp"
                        alt="Process"
                      />
                    </div>
                    <div className="number-text">
                      <div className="number-area">
                        {" "}
                        <span className="number-prefix"> 4 </span>
                      </div>
                      <div className="number-title">
                        <h3 className="title">Testing</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 sm-mb-30">
                <div className="rs-addon-number">
                  <div className="number-part">
                    <div className="number-image">
                      <img
                        src="/assets/images/process/style1/demonstration.webp"
                        alt="Process"
                      />
                    </div>
                    <div className="number-text">
                      <div className="number-area">
                        {" "}
                        <span className="number-prefix"> 5 </span>
                      </div>
                      <div className="number-title">
                        <h3 className="title">Demonstrate</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 sm-mb-30">
                <div className="rs-addon-number">
                  <div className="number-part">
                    <div className="number-image">
                      <img
                        src="/assets/images/process/style1/deployment.webp"
                        alt="Process"
                      />
                    </div>
                    <div className="number-text">
                      <div className="number-area">
                        {" "}
                        <span className="number-prefix"> 6 </span>
                      </div>
                      <div className="number-title">
                        <h3 className="title">Deployment</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Why Choose Section Start --> */}
      <div className="rs-about pt-60 pb-60 md-pt-60 md-pb-60 xs-pt-30 xs-pb-30 hidden">
        <div className="container custom">
          <div className="row">
            <div className="col-lg-6 md-mb-50">
              <div className="images-part">
                <img src="/assets/images/about-3.webp" alt="Images" />
              </div>
            </div>
            <div className="col-lg-6 pl-60 md-pl-15">
              <div className="sec-title">
                <h2 className="title pb-10">
                  Innovation, affordability, and efficiency: Our winning
                  solutions
                </h2>
                <p className="margin-0 pt-15">
                  At Hilton, we specialize in bespoke, innovative, and efficient
                  IT solutions that enhance growth, reduce expenses, and
                  optimize efficiency. Our team of accomplished developers works
                  closely with clients to understand their unique demands and
                  create tailored solutions that surpass expectations. We pride
                  ourselves on delivering superior quality solutions that foster
                  long-term relationships built on trust and mutual
                  accomplishment. Contact us today to explore how we can propel
                  your business towards success.
                </p>
              </div>
              {/* <!-- Counter Section End --> */}
              <div className="rs-counter style1 pt-20">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 xs-mb-30">
                    <div className="counter-area">
                      <div className="counter-list mb-20">
                        <div className="counter-icon">
                          <img
                            src="/assets/images/Integrity.webp"
                            alt="Integrity"
                          />
                        </div>
                        <div className="counter-number">
                          <h5 className="title">Integrity</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="counter-area">
                      <div className="counter-list mb-20">
                        <div className="counter-icon">
                          <img src="/assets/images/Trust.webp" alt="Trust" />
                        </div>
                        <div className="counter-number">
                          <h5 className="title">Trust</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 xs-mb-30">
                    <div className="counter-area">
                      <div className="counter-list mb-20">
                        <div className="counter-icon">
                          <img
                            src="/assets/images/Commitment.webp"
                            alt="Commitment"
                          />
                        </div>
                        <div className="counter-number">
                          <h5 className="title">Commitment</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="counter-area">
                      <div className="counter-list mb-20">
                        <div className="counter-icon">
                          <img src="/assets/images/Growth.webp" alt="Growth" />
                        </div>
                        <div className="counter-number">
                          <h5 className="title">Growth</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Counter Section End --> */}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Why Choose Section Start --> */}

      {/* <!-- Appointment Section Start -->  */}
      <div className="rs-appointment style1 apply-career hidden">
        <div className="appoint-schedule">
          <div className="row margin-0" id="looking-for-job">
            <div
              className="col-md-7 pt-20 pb-20 pr-20 pl-20"
              id="PHP-Magento-Developer"
              style={{ backgroundColor: "#f6f6f6" }}

              //   style="background-color:#f6f6f6;"
            >
              <div className="tab-area">
                <ul className="nav nav-tabs mb-20" role="tablist">
                  <li className="nav-item" role="presentation">
                    {" "}
                    <button
                      className="nav-link active"
                      id="phpdev-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#phpdev"
                      type="button"
                      role="tab"
                      aria-controls="phpdev"
                      aria-selected="true"
                    >
                      Php developers
                    </button>{" "}
                  </li>
                  <li className="nav-item" role="presentation">
                    {" "}
                    <button
                      className="nav-link"
                      id="magentodev-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#magentodev"
                      type="button"
                      role="tab"
                      aria-controls="magentodev"
                      aria-selected="false"
                    >
                      Magento developers
                    </button>{" "}
                  </li>
                </ul>
                <div className="tab-content">
                  <div
                    className="tab-pane fade show active"
                    id="phpdev"
                    role="tabpanel"
                    aria-labelledby="phpdev-tab"
                  >
                    <h3 className="h5 font-bold mb-sm">
                      Looking for PHP developers
                    </h3>
                    <h3 className="h6 mb-sm">
                      Location: <span>Hyderabad</span>
                    </h3>
                    <h3 className="h6 mb-sm">
                      Job: <span>Full-time</span>
                    </h3>
                    <h3 className="h6 mb-sm">Job description:</h3>
                    <p>
                      As a member of this team, you will develop new features
                      and other backend services required by the software
                      applications and sites.
                    </p>
                    <h3 className="h6 mb-sm">Skills required:</h3>
                    <ul className="disc-list">
                      <li>Must have experience working with PHP</li>
                      <li>Framework Laravel</li>
                      <li>
                        Excellent working knowledge and experience in
                        object-oriented Web application development
                      </li>
                      <li>
                        Great coding skills in PHP, MySQL, HTML, CSS, BootStrap
                        JavaScript, jQuery, Ajax, JSON, Angular (preferable)
                      </li>
                      <li>
                        Excellent working knowledge of MySQL database with good
                        understanding of tuning database performance and sql
                        queries
                      </li>
                      <li>
                        Good knowledge and understanding of eCommerce, payment
                        gateway & other API integration like Google APIs
                      </li>
                      <li>
                        Ability to work with minimal supervision, troubleshoot,
                        fix and test functionality with a passion to learn and
                        grow
                      </li>
                      <li>Problem solving, analytical skills</li>
                      <li>Good knowledge of version control tools</li>
                      <li>
                        Good exposure to configuration management, testing,
                        deployment
                      </li>
                      <li>
                        Team player, friendly, self-motivated, quick &
                        self-learner and be able to work autonomously
                      </li>
                      <li>
                        Excellent communication skills & positive working
                        attitude
                      </li>
                      <li>Let's get it done attitude.</li>
                    </ul>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="magentodev"
                    role="tabpanel"
                    aria-labelledby="magentodev-tab"
                  >
                    <h3 className="h5 font-bold mb-sm">
                      Looking for Magento developers
                    </h3>
                    <h3 className="h6 mb-sm">
                      Location: <span>Hyderabad</span>
                    </h3>
                    <h3 className="h6 mb-sm">
                      Job: <span>Full-time</span>
                    </h3>
                    <h3 className="h6 mb-sm">Job description:</h3>
                    <p>
                      A professional with 3+ years of experience on PHP based
                      applications, focusing on e-Commerce implementation.
                    </p>
                    <h3 className="h6 mb-sm">Technical skills required:</h3>
                    <ul className="disc-list">
                      <li>Must have experience working with PHP</li>
                      <li>Magento</li>
                      <li>Zend</li>
                      <li>Web Services</li>
                      <li>JQuery</li>
                      <li>Ajax</li>
                      <li>MySQL / Oracle / PostgreSQL</li>
                    </ul>
                    <h3 className="h6 mb-sm pt-20">Secondary skills:</h3>
                    <ul className="disc-list">
                      <li>
                        Must have strong oral and written communication skills,
                        and be customer focused to understand and appropriately
                        respond to customers' business needs.
                      </li>
                      <li>
                        Demonstrated critical thinking skills, ability to
                        accurately analyze information and make sound decisions.
                      </li>
                      <li>Team management</li>
                      <li>Performance optimizations of application</li>
                    </ul>
                    <h3 className="h6 mb-sm pt-20">Key responsibilities:</h3>
                    <ul className="disc-list">
                      <li>
                        Understanding of business requirements and the process
                        of translating them into well-engineered and integrated
                        technical solutions and reusable components.
                      </li>
                      <li>
                        Interface effectively and collaborate with clients,
                        peers, and management to develop solutions and ensure
                        stakeholder buy-in.
                      </li>
                      <li>
                        Managing multiple client projects focusing on ecommerce
                        implementation.
                      </li>
                      <li>
                        Setting up process for code quality & coding practices.
                        Coach and mentor programmers.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* <!-- .tab-area --> */}
            </div>
            <div
              className="col-md-5 padding-0"
              id="Opening-doors-to-exciting-careers"
            >
              <div className="contact-wrap">
                <div className="sec-title mb-50">
                  <h2 className="title white-color">
                    {" "}
                    Opening doors to exciting{" "}
                    <span className="new-text">careers</span>
                  </h2>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6 col-sm-12 col-xs-12 mb-30">
                      <div className="form-group">
                        {" "}
                        <label>Name</label>{" "}
                        <input
                          id="name"
                          name="name"
                          className="form-control-mod"
                          type="text"
                          required
                          value={formData.name}
                          onChange={handleChange}
                        />{" "}
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12 col-xs-12 mb-30">
                      <div className="form-group">
                        {" "}
                        <label>E-Mail</label>{" "}
                        <input
                          id="email"
                          name="email"
                          className="form-control-mod"
                          type="text"
                          required
                          value={formData.email}
                          onChange={handleChange}
                        />{" "}
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12 col-xs-12 mb-30">
                      <div className="form-group">
                        {" "}
                        <label>Phone</label>{" "}
                        <input
                          id="phone"
                          name="phone"
                          className="form-control-mod"
                          type="text"
                          required
                          value={formData.phone}
                          onChange={handleChange}
                        />{" "}
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12 col-xs-12 mb-30">
                      <div className="form-group">
                        {" "}
                        <label>Apply position</label>{" "}
                        <input
                          id="position"
                          name="position"
                          className="form-control-mod"
                          type="text"
                          required
                          value={formData.position}
                          onChange={handleChange}
                        />{" "}
                      </div>
                    </div>
                    <div className="col-lg-12 col-sm-6 mb-30">
                      <div className="form-group">
                        {" "}
                        <label>Your resume</label>{" "}
                        <div className="wpcf7-form-control-wrap resume">
                          {" "}
                          <input
                            type="file"
                            id="file"
                            name="file"
                            size="40"
                            className="wpcf7-form-control wpcf7-file wpcf7-validates-as-required"
                            required
                            onChange={handleFileChange}
                          />{" "}
                        </div>{" "}
                      </div>
                    </div>
                    <div className="col-lg-12 mb-35">
                      {" "}
                      <textarea
                        className="from-control"
                        id="message"
                        name="message"
                        placeholder="Your message Here"
                        required
                        value={formData.message}
                        onChange={handleChange}
                      ></textarea>{" "}
                    </div>
                  </div>
                  <>
                    <ReCAPTCHA
                      sitekey={recaptchakey} // Replace with your reCAPTCHA site key
                      onChange={handleCaptchaVerify}
                    />
                  </>
                  <br />
                  <div className="btn-part">
                    <div className="form-group mb-0">
                      {" "}
                      <input
                        className="readon consultant get-quote"
                        type="submit"
                        value="Submit Now"
                      />{" "}
                    </div>
                  </div>
                </form>
              </div>
              <div
                className={`notification ${messageType} ${
                  showSuccessMessage ? "show" : "hide"
                }`}
              >
                {successMessage}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Appointment Section End --> */}
      <div className="rs-services style8 black-bg3 pt-60 pb-60 md-pt-60 md-pb-60 xs-pt-30 xs-pb-30">
        <div className="container-fluid">
          <div className="row y-middle">
            <div className="col-lg-5 pr-25 md-pr-15 text-white">
              <div className="sec-title">
                <h2 className="title pb-22 text-white">What we offer</h2>
                <div class="bar-main">
                  <div class="bar bar-big left"></div>
                </div>
              </div>
              <div className="sec-title3">
                <p className="margin-0 pb-40 ft16">
                  Our comprehensive software development solutions deliver
                  robust and effective IT solutions customized to your business
                  needs, ensuring sustainable growth.
                </p>
              </div>
            </div>
            <div className="col-lg-7 pl-30 md-pl-15">
              <div className="row">
                <div className="col-lg-6 col-sm-6">
                  <div className="flip-box-inner mb-30">
                    <div className="flip-box-wrap">
                      <div className="front-part">
                        <div className="front-content-part">
                          <div className="front-icon-part">
                            <div className="icon-part">
                              <img src="/assets/images/full-stack.webp" alt="" />
                            </div>
                          </div>
                          <div className="front-title-part">
                            <h3 className="title">
                              <a>Development</a>
                            </h3>
                          </div>
                          <div className="front-desc-part">
                            <p>
                              Empower your business with our cutting-edge
                              development solutions.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="back-front">
                        <div className="back-front-content">
                          <div className="back-title-part">
                            <h3 className="back-title">
                              <a>Development</a>
                            </h3>
                          </div>
                          <div className="back-desc-part">
                            <p className="back-desc">
                              Our passion lies in delivering robust software
                              solutions to clients, with a specialization in
                              enterprise-level systems that address business
                              challenges in today's ever-changing marketplace.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flip-box-inner sm-mb-30">
                    <div className="flip-box-wrap">
                      <div className="front-part">
                        <div className="front-content-part">
                          <div className="front-icon-part">
                            <div className="icon-part">
                              <img src="/assets/images/computer.webp" alt="" />
                            </div>
                          </div>
                          <div className="front-title-part">
                            <h3 className="title">
                              <a>IT Support</a>
                            </h3>
                          </div>
                          <div className="front-desc-part">
                            <p>
                              Reliable IT support that keeps your business
                              running smoothly.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="back-front">
                        <div className="back-front-content">
                          <div className="back-title-part">
                            <h3 className="back-title">
                              <a>IT Support</a>
                            </h3>
                          </div>
                          <div className="back-desc-part">
                            <p className="back-desc">
                              Setting up and maintaining a support center can be
                              a time-consuming and expensive. By outsourcing
                              your technical support services, you can shift
                              your focus to business expansion instead of
                              managing customer inquiries.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 mt-30 md-mt-0">
                  <div className="flip-box-inner mb-30">
                    <div className="flip-box-wrap">
                      <div className="front-part">
                        <div className="front-content-part">
                          <div className="front-icon-part">
                            <div className="icon-part">
                              <img
                                src="/assets/images/online-shopping.webp"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="front-title-part">
                            <h3 className="title">
                              <a>Designing</a>
                            </h3>
                          </div>
                          <div className="front-desc-part">
                            <p>
                              Unleash creativity with our exceptional design
                              solutions.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="back-front">
                        <div className="back-front-content">
                          <div className="back-title-part">
                            <h3 className="back-title">
                              <a>Designing</a>
                            </h3>
                          </div>
                          <div className="back-desc-part">
                            <p className="back-desc">
                              Our Web development services deliver visually
                              captivating and responsive designs that seamlessly
                              adapt to various mobile devices, including
                              desktops, laptops, and tablets.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flip-box-inner">
                    <div className="flip-box-wrap">
                      <div className="front-part">
                        <div className="front-content-part">
                          <div className="front-icon-part">
                            <div className="icon-part">
                              <img src="/assets/images/mobile-app.webp" alt="" />
                            </div>
                          </div>
                          <div className="front-title-part">
                            <h3 className="title">
                              <a>Marketing</a>
                            </h3>
                          </div>
                          <div className="front-desc-part">
                            <p>
                              Unlock your business's online potential with our
                              expert digital marketing solutions.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="back-front">
                        <div className="back-front-content">
                          <div className="back-title-part">
                            <h3 className="back-title">
                              <a>Marketing</a>
                            </h3>
                          </div>
                          <div className="back-desc-part">
                            <p className="back-desc">
                              Hilton is a reliable digital marketing company
                              providing comprehensive solutions for your brand's
                              digital requirements, including design and social
                              media strategy. Our knowledgeable team is driven
                              by a passion for continuous learning.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rs-services style8 bg-color pt-60 pb-60 md-pt-60 md-pb-60 xs-pt-30 xs-pb-30 hidden">
        <div className="container-fluid">
          <div className="row y-middle">
            <div className="col-lg-5 pr-25 md-pr-15 md-mb-50">
              <div className="sec-title">
                <h2 className="title pb-22">Why Choose Hilton</h2>
                <div class="bar-main">
                  <div class="bar bar-big left"></div>
             </div>
              </div>
              <div className="sec-title3">
                <p className="margin-0 pb-40 ft16">
                We helping our client to fullfill their needs with our expert.
                </p>
              </div>
            </div>
            <div className="col-lg-7 pl-30 md-pl-15">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="flip-box-inner mb-30">
                    <div className="flip-box-wrap">
                      <div className="front-part">
                        <div className="front-content-part">
                          <div className="front-icon-part">
                            <div className="icon-part">
                              <img src="/assets/images/quality-service.png" alt="" />
                            </div>
                          </div>
                          <div className="front-title-part">
                            <h3 className="title">
                              <a>Quality Service</a>
                            </h3>
                          </div>
                          <div className="front-desc-part">
                            <p>
                              Empower your business with our cutting-edge
                              development solutions.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="back-front">
                        <div className="back-front-content">
                          <div className="back-title-part">
                            <h3 className="back-title">
                              <a>Quality Service</a>
                            </h3>
                          </div>
                          <div className="back-desc-part">
                            <p className="back-desc">
                              Empower your business with our cutting-edge
                              development solutions.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flip-box-inner sm-mb-30">
                    <div className="flip-box-wrap">
                      <div className="front-part">
                        <div className="front-content-part">
                          <div className="front-icon-part">
                            <div className="icon-part">
                              <img src="/assets/images/24-hours-service.png" alt="" />
                            </div>
                          </div>
                          <div className="front-title-part">
                            <h3 className="title">
                              <a>24x7 Support</a>
                            </h3>
                          </div>
                          <div className="front-desc-part">
                            <p>
                              Reliable IT support that keeps your business
                              running smoothly.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="back-front">
                        <div className="back-front-content">
                          <div className="back-title-part">
                            <h3 className="back-title">
                              <a>24x7 Support</a>
                            </h3>
                          </div>
                          <div className="back-desc-part">
                            <p className="back-desc">
                            Reliable IT support that keeps your business
                            running smoothly.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 mt-30 md-mt-0">
                  <div className="flip-box-inner mb-30">
                    <div className="flip-box-wrap">
                      <div className="front-part">
                        <div className="front-content-part">
                          <div className="front-icon-part">
                            <div className="icon-part">
                              <img
                                src="/assets/images/sale.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="front-title-part">
                            <h3 className="title">
                              <a>Great Pricing</a>
                            </h3>
                          </div>
                          <div className="front-desc-part">
                            <p>
                              Unleash creativity with our exceptional design
                              solutions.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="back-front">
                        <div className="back-front-content">
                          <div className="back-title-part">
                            <h3 className="back-title">
                              <a>Great Pricing</a>
                            </h3>
                          </div>
                          <div className="back-desc-part">
                            <p className="back-desc">
                            Unleash creativity with our exceptional design
                            solutions.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flip-box-inner">
                    <div className="flip-box-wrap">
                      <div className="front-part">
                        <div className="front-content-part">
                          <div className="front-icon-part">
                            <div className="icon-part">
                              <img src="/assets/images/relationship.png" alt="" />
                            </div>
                          </div>
                          <div className="front-title-part">
                            <h3 className="title">
                              <a>100% Trusted</a>
                            </h3>
                          </div>
                          <div className="front-desc-part">
                            <p>
                              Unlock your business's online potential with our
                              expert digital marketing solutions.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="back-front">
                        <div className="back-front-content">
                          <div className="back-title-part">
                            <h3 className="back-title">
                              <a>100% Trusted</a>
                            </h3>
                          </div>
                          <div className="back-desc-part">
                            <p className="back-desc">
                            Unlock your business's online potential with our
                            expert digital marketing solutions.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default About;
