import React from 'react';

const TabContentComponentAiMl = ({ activeKey }) => {
    return (
        <div className="tab-content justify-content-center">
            {activeKey === 'tab1' && 
            <p>
                <div className="yellow-bg pt-150 pb-70 md-pt-60 md-pb-60">
                    <div className="container">
                    <div className="row">
                        <div className="col-lg-6 md-mb-50">
                        <div className="about-content">
                            <div className="images-part">
                            <img
                                src="/assets/images/chatbots-img.webp"
                                alt="Images"
                            />
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-6 pl-30 md-pr-l5">
                        <div className="sec-title mb-20">
                           <h2 className="title title3 pb-25">
                           Chatbots
                            </h2>
                            <p className="margin-0">
                            Our AI-powered chatbots offer a powerful way for software development companies to enhance customer engagement and streamline operations. By utilizing machine learning (ML) and natural language processing (NLP), these intelligent bots provide round-the-clock support, responding instantly to customer inquiries with human-like accuracy. They help automate routine tasks, such as answering FAQs or managing simple queries, freeing up time for teams to focus on core development work. These chatbots also personalize customer interactions by analyzing user behavior and preferences, offering tailored product or service recommendations.{" "}
                            </p>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </p>
            }
            {activeKey === 'tab2' && 
            <p>
            <div className="yellow-bg pt-150 pb-70 md-pt-80 md-pb-60">
                <div className="container">
                <div className="row">
                    <div className="col-lg-6 md-mb-50">
                    <div className="about-content">
                        <div className="images-part">
                        <img
                            src="/assets/images/predictive-analytics.webp"
                            alt="Images"
                        />
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-6 pl-30 md-pr-l5">
                    <div className="sec-title mb-20">
                        <h2 className="title title3 pb-25">
                        Predictive Analytics
                        </h2>
                        <p className="margin-0">
                        AI Predictive Analysis is a powerful tool that enables businesses to anticipate future trends, optimize operations, and make data-driven decisions. Using advanced machine learning algorithms, our solutions analyze vast amounts of historical data to forecast outcomes such as demand, customer behavior, and potential risks. This allows businesses to enhance their decision-making process, improve resource allocation, and stay ahead in competitive markets. Our AI Predictive Analysis solutions provide the edge needed to drive sustainable growth and innovation.</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </p>
            }
            {activeKey === 'tab3' && 
            <p>
            <div className="yellow-bg pt-150 pb-70 md-pt-80 md-pb-60">
                <div className="container">
                <div className="row">
                    <div className="col-lg-6 md-mb-50">
                    <div className="about-content">
                        <div className="images-part">
                        <img
                            src="/assets/images/fraud-detection.webp"
                            alt="Images"
                        />
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-6 pl-30 md-pr-l5">
                    <div className="sec-title mb-20">
                        <h2 className="title title3 pb-25">
                        Fraud Detection
                        </h2>
                        <p className="margin-0">
                        Protect your e-commerce business with our AI-powered fraud detection solution. Utilizing advanced machine learning algorithms, our system monitors transactions in real-time, identifying and flagging suspicious activities. By continuously learning from new data, it adapts to emerging fraud tactics, reducing false positives and enhancing accuracy. Seamlessly integrated with your existing platforms, it offers detailed reports and alerts to help you act swiftly against potential threats, ensuring your business remains secure and your customers' transactions are safe.</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </p>
            }
             {activeKey === 'tab4' && 
            <p>
            <div className="yellow-bg pt-150 pb-70 md-pt-80 md-pb-60">
                <div className="container">
                <div className="row">
                    <div className="col-lg-6 md-mb-50">
                    <div className="about-content">
                        <div className="images-part">
                        <img
                            src="/assets/images/image-recognition.webp"
                            alt="Images"
                        />
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-6 pl-30 md-pr-l5">
                    <div className="sec-title mb-20">
                        <h2 className="title title3 pb-25">
                        Image Recognition
                        </h2>
                        <p className="margin-0">
                        Transform your e-commerce site with our AI image recognition technology, which empowers customers to search for products using images. This feature enhances the shopping experience by providing accurate, visual search results and automatic tagging of products. Improve user engagement with personalized recommendations and interactive features like virtual try-ons. Our AI-driven solutions streamline inventory management and enhance security by detecting counterfeit products. With scalable and flexible technology, we help you deliver a sophisticated, intuitive shopping experience that meets modern consumer expectations.{" "}
                        </p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </p>
            }
        </div>
    );
};

export default TabContentComponentAiMl;
