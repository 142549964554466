import React from "react";
import { Helmet } from "react-helmet";

const MetaTag = ({ title, description, keywords,path }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      {keywords && <meta name="keywords" content={keywords} />}
      <meta property="og:title" content={title}></meta>
      <meta property="og:description" content={description}></meta>
      <meta property="og:type" content="website"/>
      <meta property="og:image" content="https://hiltonglobal.in/assets/images/hiltonlogo.webp"/>
      <meta property="og:url" content={`https://hiltonglobal.in${path}`}></meta>
      
    </Helmet>
  );
};

export default MetaTag;
