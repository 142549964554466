import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';

const TabsComponentBiApp = ({ activeKey, onSelect }) => {
    return (
        <Tabs activeKey={activeKey} onSelect={onSelect} id="tab-component" className='justify-content-center'>
            <Tab eventKey="tab1" title={
                <span>
                    <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/technologies/power-bi.png" className="attachment-full" alt="Power BI" title="Power BI" />
                        </span>
                    </div>
                </span>
            } />
            <Tab eventKey="tab2" title={
                <span>
                    <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/technologies/Fabric-logo.webp" className="attachment-full" alt="Microsoft Fabric" title="Microsoft Fabric" />
                        </span>
                    </div>
                </span>
            } />
            <Tab eventKey="tab3" title={
                <span>
                    <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/technologies/adf.webp" className="attachment-full" alt="Azure Data Factory" title="Azure Data Factory" />
                        </span>
                    </div>
                </span>
            } />
            
        </Tabs>
    );
};

export default TabsComponentBiApp;
