import Header from "../Header";
import Footer from "../Footer";
import BreadCrums from "../BreadCrums";
import { settings } from "../CarosuelsSettings";
import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MetaTag from "../MetaTag";
import HTMLFlipBook from "react-pageflip";
import TabsComponentMobileApp from "./TabsComponentMobileApp";
import TabContentComponentMobileApp from "./TabContentComponentMobileApp";

const MobileAppDevelopment = () => {
  const MetaTitle = "Mobile Application Development Services - Hybrid App - React Native Apps | Hilton";
  const MetaDescription =
    "Looking for a Mobile App Development? We are providing a full range of Android, IOS, Ionic, React native applications. We specialized in both complete and customized software solutions.";
  const MetaKeywords =
    "Build Mobile Apps, Mobile Application Development, Mobile Application Development company, Hybrid app development, React Native app development";
  const path = window.location.pathname;
  const [activeKey, setActiveKey] = useState('tab1');
  return (
    <>
      <MetaTag
        title={MetaTitle}
        description={MetaDescription}
        keywords={MetaKeywords}
        path={path}
      />
      <Header />
      <BreadCrums
        name={"mobileapp"}
        tittle={"Mobile application development"}
        watermark={"Mobile application development services"}
      />
      <div id="" className="fulfill-ecommerce pt-60 pb-30 md-pt-60 md-pb-30 xs-pt-30 xs-pb-30">
        <div className="container custom">
          <div className="sec-title3 text-center mb-30 md-mb-30">
            <h1 className="title title2 pb-20">
              Tailored Mobile App Innovation
            </h1>
                <span className="border-line">
                  <i className="fa fa-diamond" id="diamond"></i>
                </span>
            {/* <div class="bar-main">
                  <div class="bar bar-big"></div>
             </div> */}
          </div>

          <div className="row y-middle">
            <div className="col-md-6 about-quote">
              <div className="mb-2">
                <img
                  src="/assets/images/quotation.webp"
                  alt="quote"
                  className="quote-image"
                />
              </div>

              <p>
                Build, manage, and modernize custom applications using the
                latest advances in technology
              </p>
            </div>

            <div className="col-lg-6 col-md-6 mb-30">
              <p className="services-txt ft16 text-white">
              Transform your vision into reality with our advanced mobile app development services, where we craft bespoke, user-centric applications that captivate and engage. Our team excels in leveraging top-tier technologies, including native iOS, Android, and cross-platform solutions like Flutter and React Native, to deliver apps that are both innovative and intuitive. 
              </p>
              <p className="services-txt ft16 text-white">
              From initial concept through design, development, and deployment, we ensure a seamless and efficient process. With a strong focus on performance, security, and scalability, we create apps that thrive under real-world conditions while providing a polished, user-friendly experience.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        id="operational-ecommerce"
        className="rs-single-shop vertical operational-ecommerce  pt-20 pb-60 md-pt-60 sm-pb-68-0 hidden"
      >
        <div className="container">
          <div className="sec-title text-center mb-55 md-mb-30">
            <h2 className="title title2">Operational mobile app solutions</h2>

            <p className="margin-0">
              We offer peerless services to drive your business growth
            </p>
          </div>

          <div className="tab-area desktop">
            <ul className="nav nav-tabs" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="Logistic-Management-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Logistic-Management"
                  type="button"
                  role="tab"
                  aria-controls="Logistic-Management"
                  aria-selected="true"
                >
                  <img src="/assets/images/icons/mapp1.webp" /> Flutter
                  application development services
                </button>
              </li>

              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="Inventory-Management-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Inventory-Management"
                  type="button"
                  role="tab"
                  aria-controls="Inventory-Management"
                  aria-selected="true"
                >
                  <img src="/assets/images/icons/mapp2.webp" /> Progressive web
                  application development
                </button>
              </li>

              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="Warehouse-Management-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Warehouse-Management"
                  type="button"
                  role="tab"
                  aria-controls="Warehouse-Management"
                  aria-selected="false"
                >
                  <img src="/assets/images/icons/mapp3.webp" /> Ionic application
                  development
                </button>
              </li>

              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="PIM-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#PIM"
                  type="button"
                  role="tab"
                  aria-controls="PIM"
                  aria-selected="false"
                >
                  <img src="/assets/images/icons/mapp5.webp" /> React native
                  application development
                </button>
              </li>

              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="Document-Management-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Document-Management"
                  type="button"
                  role="tab"
                  aria-controls="Document-Management"
                  aria-selected="false"
                >
                  <img src="/assets/images/icons/mapp1.webp" /> Native android
                  application development
                </button>
              </li>

              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="POS-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#POS"
                  type="button"
                  role="tab"
                  aria-controls="POS"
                  aria-selected="false"
                >
                  <img src="/assets/images/icons/mapp3.webp" /> Native
                  application devlopmment
                </button>
              </li>

              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="Loyalty-Programs-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Loyalty-Programs"
                  type="button"
                  role="tab"
                  aria-controls="Loyalty-Programs"
                  aria-selected="false"
                >
                  <img src="/assets/images/icons/mapp4.webp" /> Native IOS
                  application development
                </button>
              </li>
            </ul>

            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id="Logistic-Management"
                role="tabpanel"
                aria-labelledby="Logistic-Management-tab"
              >
                <h3>Flutter aplication development services</h3>

                <p className="">
                  Flutter application development services leverage the power of
                  the Flutter framework to build exceptional applications for
                  both iOS and Android platforms using a unified codebase. Our
                  expert team offers end-to-end Flutter app development
                  services, encompassing consultation, design, deployment, and
                  maintenance, all delivered at a competitive price point.
                </p>
              </div>

              <div
                className="tab-pane fade show"
                id="Inventory-Management"
                role="tabpanel"
                aria-labelledby="Inventory-Management-tab"
              >
                <h3>Progressive web application development</h3>

                <p className="">
                  Our progressive web applications offer unparalleled user
                  experiences through cutting-edge technology. Our skilled team
                  provides top-notch mobile app development services at a
                  competitive price, guaranteeing seamless performance and a
                  native-like interface. As a premier mobile app development
                  company, we deliver premium progressive web app solutions
                  tailored to meet your unique business requirements.
                </p>
              </div>

              <div
                className="tab-pane fade"
                id="Warehouse-Management"
                role="tabpanel"
                aria-labelledby="Warehouse-Management-tab"
              >
                <h3> Ionic application development</h3>

                <p className="">
                  Our team at Hilton takes pride in our highly skilled Ionic
                  developers who possess extensive practical experience in both
                  Ionic web and application development services. Following an
                  agile methodology, we prioritize effective communication and
                  project transparency to ensure optimal outcomes for our valued
                  clients.
                </p>
              </div>

              <div
                className="tab-pane fade"
                id="PIM"
                role="tabpanel"
                aria-labelledby="Warehouse-Management-tab"
              >
                <h3> React aative application development</h3>

                <p className="">
                  React Native empowers the development of high-performance
                  mobile applications using a unified codebase and an extensive
                  ecosystem of third-party resources. Our team of expert
                  developers is dedicated to delivering exceptional iOS and
                  Android applications that surpass the expectations of our
                  esteemed clients.
                </p>
              </div>

              <div
                className="tab-pane fade"
                id="Document-Management"
                role="tabpanel"
                aria-labelledby="Warehouse-Management-tab"
              >
                <h3> Native android application development</h3>

                <p className="">
                  The rapid growth of Android in the smartphone market, boasting
                  over 65 billion apps, can be attributed to its versatility and
                  widespread popularity. Our company is committed to meeting
                  your unique requirements by offering a comprehensive range of
                  features and services on this mobile-ready platform, with the
                  ultimate goal of boosting your business revenue.
                </p>
              </div>

              <div
                className="tab-pane fade"
                id="POS"
                role="tabpanel"
                aria-labelledby="Warehouse-Management-tab"
              >
                <h3> Native application devlopmment</h3>

                <p className="">
                  Native mobile apps provide superior user experience and
                  performance on specific operating systems like iOS or Android.
                  Cross-platform apps require only one codebase to create apps
                  for multiple platforms, offering versatility and convenience.
                  Choose the right approach based on your needs and target
                  audience, ensuring optimal reach and engagement for your
                  mobile app.
                </p>
              </div>

              <div
                className="tab-pane fade"
                id="Loyalty-Programs"
                role="tabpanel"
                aria-labelledby="Warehouse-Management-tab"
              >
                <h3> Native IOS application development</h3>

                <p className="">
                  Our iOS app development services prioritize security measures
                  to protect user data and mitigate potential security threats.
                  With our team of experienced professionals, we deliver
                  customized and professional iOS app solutions tailored to
                  various industries. By leveraging our services, businesses can
                  effectively tap into the ever-growing iOS user base and
                  capitalize on the opportunities it presents.
                </p>
              </div>
            </div>

            <div className="clearboth"></div>
          </div>
          {/* <!-- .tab-area --> */}
          <div className="rs-faq mobile">
            <div className="faq-content">
              <div id="accordion" className="accordion">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header">
                        <a
                          className="card-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="false"
                        >
                          <img src="/assets/images/icons/mapp1.webp" />
                          <span>Flutter application development services</span>
                        </a>
                      </div>
                      <div
                        id="collapseOne"
                        className="collapse"
                        data-bs-parent="#accordion"
                      >
                        <div className="card-body">
                          <h3>Flutter aplication development services</h3>

                          <p className="">
                            Flutter application development services leverage
                            the power of the Flutter framework to build
                            exceptional applications for both iOS and Android
                            platforms using a unified codebase. Our expert team
                            offers end-to-end Flutter app development services,
                            encompassing consultation, design, deployment, and
                            maintenance, all delivered at a competitive price
                            point.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <a
                          className="card-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                        >
                          <img src="/assets/images/icons/mapp2.webp" />
                          <span>Progressive web application development</span>
                        </a>
                      </div>
                      <div
                        id="collapseTwo"
                        className="collapse"
                        data-bs-parent="#accordion"
                      >
                        <div className="card-body">
                          <h3>Progressive web application development</h3>

                          <p className="">
                            Our progressive web applications offer unparalleled
                            user experiences through cutting-edge technology.
                            Our skilled team provides top-notch mobile app
                            development services at a competitive price,
                            guaranteeing seamless performance and a native-like
                            interface. As a premier mobile app development
                            company, we deliver premium progressive web app
                            solutions tailored to meet your unique business
                            requirements.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <a
                          className="card-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                        >
                          <img src="/assets/images/icons/mapp3.webp" />
                          <span>Ionic application development</span>
                        </a>
                      </div>
                      <div
                        id="collapseThree"
                        className="collapse"
                        data-bs-parent="#accordion"
                      >
                        <div className="card-body">
                          <h3> Ionic application development</h3>

                          <p className="">
                            Our team at Hilton takes pride in our highly skilled
                            Ionic developers who possess extensive practical
                            experience in both Ionic web and application
                            development services. Following an agile
                            methodology, we prioritize effective communication
                            and project transparency to ensure optimal outcomes
                            for our valued clients.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <a
                          className="card-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="false"
                        >
                          <img src="/assets/images/icons/mapp5.webp" />
                          <span> React native application development</span>
                        </a>
                      </div>
                      <div
                        id="collapseFour"
                        className="collapse"
                        data-bs-parent="#accordion"
                      >
                        <div className="card-body">
                          <h3> React aative application development</h3>

                          <p className="">
                            React Native empowers the development of
                            high-performance mobile applications using a unified
                            codebase and an extensive ecosystem of third-party
                            resources. Our team of expert developers is
                            dedicated to delivering exceptional iOS and Android
                            applications that surpass the expectations of our
                            esteemed clients.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <a
                          className="card-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFive"
                          aria-expanded="false"
                        >
                          <img src="/assets/images/icons/mapp1.webp" />
                          <span> Native android application development</span>
                        </a>
                      </div>
                      <div
                        id="collapseFive"
                        className="collapse"
                        data-bs-parent="#accordion"
                      >
                        <div className="card-body">
                          <h3> Native android application development</h3>

                          <p className="">
                            The rapid growth of Android in the smartphone
                            market, boasting over 65 billion apps, can be
                            attributed to its versatility and widespread
                            popularity. Our company is committed to meeting your
                            unique requirements by offering a comprehensive
                            range of features and services on this mobile-ready
                            platform, with the ultimate goal of boosting your
                            business revenue.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <a
                          className="card-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSix"
                          aria-expanded="false"
                        >
                          <img src="/assets/images/icons/mapp3.webp" />
                          <span> Native application devlopmment</span>
                        </a>
                      </div>
                      <div
                        id="collapseSix"
                        className="collapse"
                        data-bs-parent="#accordion"
                      >
                        <div className="card-body">
                          <h3> Native application devlopmment</h3>

                          <p className="">
                            Native mobile apps provide superior user experience
                            and performance on specific operating systems like
                            iOS or Android. Cross-platform apps require only one
                            codebase to create apps for multiple platforms,
                            offering versatility and convenience. Choose the
                            right approach based on your needs and target
                            audience, ensuring optimal reach and engagement for
                            your mobile app.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <a
                          className="card-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSeven"
                          aria-expanded="false"
                        >
                          <img src="/assets/images/icons/mapp4.webp" />
                          <span> Native IOS application development</span>
                        </a>
                      </div>
                      <div
                        id="collapseSeven"
                        className="collapse"
                        data-bs-parent="#accordion"
                      >
                        <div className="card-body">
                          <h3> Native IOS application development</h3>

                          <p className="">
                            Our iOS app development services prioritize security
                            measures to protect user data and mitigate potential
                            security threats. With our team of experienced
                            professionals, we deliver customized and
                            professional iOS app solutions tailored to various
                            industries. By leveraging our services, businesses
                            can effectively tap into the ever-growing iOS user
                            base and capitalize on the opportunities it
                            presents.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="rs-services"
        className="rs-services mob-dev style4 pt-60 pb-60 md-pt-60 md-pb-60 xs-pt-30 xs-pb-30 hidden"
      >
        <div className="container">
          <div className="sec-title text-center mb-60">
            <h2 className="title title2 pb-20">
              We provide custom mobile app development services as per your
              requirements
            </h2>

            <p className="dese margin-0">
              Hilton offers end-to-end mobile app development services, led by a
              team of experienced professionals skilled in the latest
              technologies. Our customized approach to mobile app development
              ensures seamless integration across multiple platforms and
              devices. With our state-of-the-art development center, we deliver
              scalable, robust, and secure solutions catered to clients in
              various industries.
            </p>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-6 md-mb-30">
              <div className="services-item">
                <div className="services-icon">
                  <img
                    className="dance_hover"
                    src="/assets/images/ui.webp"
                    alt="Services"
                  />
                </div>

                <div className="services-content">
                  <h3 className="title">UI/UX design services</h3>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 md-mb-30">
              <div className="services-item">
                <div className="services-icon">
                  <img
                    className="dance_hover"
                    src="/assets/images/app-development.webp"
                    alt="Services"
                  />
                </div>

                <div className="services-content">
                  <h3 className="title">Native app development services</h3>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 md-mb-30">
              <div className="services-item">
                <div className="services-icon">
                  <img
                    className="dance_hover"
                    src="/assets/images/wearable-app-development.webp"
                    alt="Services"
                  />
                </div>

                <div className="services-content">
                  <h3 className="title">Wearable app development services</h3>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 md-mb-30">
              <div className="services-item">
                <div className="services-icon">
                  <img
                    className="dance_hover"
                    src="/assets/images/cross-platform.webp"
                    alt="Services"
                  />
                </div>

                <div className="services-content">
                  <h3 className="title">
                    Cross-platform app development services
                  </h3>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 md-mb-30">
              <div className="services-item">
                <div className="services-icon">
                  <img
                    className="dance_hover"
                    src="/assets/images/mobile-app-developers.webp"
                    alt="Services"
                  />
                </div>

                <div className="services-content">
                  <h3 className="title">Hire expert mobile app developers</h3>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 md-mb-30">
              <div className="services-item">
                <div className="services-icon">
                  <img
                    className="dance_hover"
                    src="/assets/images/webapp-development.webp"
                    alt="Services"
                  />
                </div>

                <div className="services-content">
                  <h3 className="title">Progressive web app development</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="major-platforms"
        className="major-platforms flipk pt-30 md-pt-20"
      >
        <div className="container">
          <div className="sec-title3 text-center mb-55 md-mb-30">
            <h2 className="title title2 pb-20">
              Our Expertise in Mobile App Services
            </h2>
                <span className="border-line">
                  <i className="fa fa-diamond" id="diamond"></i>
                </span>
            <p className="margin-0 text-white">
              Come and choose your specific business requirements from our pool
              of expert solutions.
            </p>
            {/* <div class="bar-main">
                  <div class="bar bar-big"></div>
             </div> */}
          </div>
        </div>
      </div>
      <div
        id="ecom-platforms"
        className="ecom-platforms flipk"
      >
          <div className="container-fluid mt-4">
            <TabsComponentMobileApp activeKey={activeKey} onSelect={setActiveKey} />
          </div>
          <div>
          <TabContentComponentMobileApp activeKey={activeKey} />
          </div>
      </div>
      <div
        id="process-steps"
        className="mob-dev style4 pt-60 pb-60 md-pt-60 md-pb-60 xs-pt-30 xs-pb-30"
      >
        <div className="container-fluid">
          <div className="sec-title3 text-center mb-50">
            <h2 className="title title2 pb-20">
              Mobile App Development Process
            </h2>
            <span className="border-line">
                  <i className="fa fa-diamond" id="diamond"></i>
                  </span>
            {/* <div class="bar-main">
                  <div class="bar bar-big"></div>
             </div> */}
          </div>

          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="process-steps">
                <div className="process-item bottom">
                    <div className="pt-150 sm-pt-0"></div>
                    <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/design-thinking.webp" className="attachment-full dance_hover" alt="Planning & Design" title="Planning & Design" />
                        </span>
                    </div>
                    <div className="process-content">
                      <h3 className="title">Planning & Design</h3>
                      <p>Develop a detailed project plan covering the timeline, key milestones, resource allocation, budget, risk management strategies, and contingency plans. Design the UI/UX with a focus on aesthetics, usability, and user satisfaction, creating high-fidelity prototypes resembling the final product.</p>
                    </div>
               </div>
               <div className="process-item top">
                    <div className="icon-wrapper reverse">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/development.webp" className="attachment-full dance_hover" alt="Development" title="Development" />
                        </span>
                    </div>
                    <div className="process-content">
                      <h3 className="title">Development</h3>
                      <p>Configure the development environment by selecting the appropriate tools, frameworks, and programming languages.</p>
                    </div>
               </div>
               <div className="process-item bottom">
                   <div className="pt-150 sm-pt-0"></div>
                    <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/qa.webp" className="attachment-full dance_hover" alt="QA & Testing" title="QA & Testing" />
                        </span>
                    </div>
                    <div className="process-content">
                      <h3 className="title">QA & Testing</h3>
                      <p>Test individual components or units of code in isolation to ensure they function correctly. Conduct testing with real users or stakeholders to validate that the app meets business requirements and user expectations.</p>
                    </div>
               </div>
               <div className="process-item">
                    <div className="icon-wrapper reverse">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/startup.webp" className="attachment-full dance_hover" alt="Deployment" title="Deployment" />
                        </span>
                    </div>
                    <div className="process-content">
                      <h3 className="title">Deployment</h3>
                      <p>Publish the app to app stores, following the submission guidelines for platforms like the Apple App Store and Google Play Store. Monitor the launch process to address any issues that arise.</p>
                    </div>
               </div>
               </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="dev-process"
        className="pt-60 pb-30 md-pt-60 sm-pb-60 bg7 hidden"
      >
        <div className="container">
          <div className="sec-title text-center mb-55 md-mb-30">
            <h2 className="title title2">
              Mobile App Development Process
            </h2>
            <div class="bar-main">
                  <div class="bar bar-big"></div>
                </div>
          </div>
          <div className="road-map-main">
            <div className="road-map-wrapper">
              <div className="road-map-circle">
                <span className="road-map-circle-text d-flex align-items-center justify-content-center">
                  <span className="title">Analysis</span>
                </span>
              </div>
              <div className="road-map-card">
                <h4 className="card-head">Analysis</h4>
                <p className="card-text">
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                </p>
              </div>
            </div>
            <div className="road-map-wrapper">
              <div className="road-map-circle">
                <span className="road-map-circle-text d-flex align-items-center justify-content-center">
                  Development
                </span>
              </div>
              <div className="road-map-card">
                <h4 className="card-head">Development</h4>
                <p className="card-text">
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                </p>
              </div>
            </div>
            <div className="road-map-wrapper">
              <div className="road-map-circle">
                <span className="road-map-circle-text d-flex align-items-center justify-content-center">
                  <span>Testing</span>
                </span>
              </div>
              <div className="road-map-card">
                <h4 className="card-head">Testing</h4>
                <p className="card-text">
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                </p>
              </div>
            </div>
            <div className="road-map-wrapper">
              <div className="road-map-circle">
                <span className="road-map-circle-text d-flex align-items-center justify-content-center">
                  Launch
                </span>
              </div>
              <div className="road-map-card">
                <h4 className="card-head">Launch</h4>
                <p className="card-text">
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="Full-stack-portfolio" className="pt-60 pb-60 md-pt-60 sm-pb-60 xs-pt-30 xs-pb-30 bg-black">
          <div className="container">
          <div className="sec-title3 text-center mb-30 md-mb-30">
            <h2 className="title title2 pb-20">Mobile App Portfolio</h2>
                <span className="border-line">
                  <i className="fa fa-diamond" id="diamond"></i>
                  </span>
              {/* <div class="bar-main">
                  <div class="bar bar-big"></div>
              </div> */}
          </div>
              <div className="book-wrapper">
              <HTMLFlipBook
                width={500}
                height={600}
                size="stretch"
                startPage={1}  // Start with the first page open
                showCover={false}
                maxShadowOpacity={0.5}
                className="book-container"
              >
                <div className="page1">
                <div className="service-wrap">
                          <div className="client-logo">
                            <h2>Innovative Lifts</h2>
                            <img
                              src="/assets/images/brands/innovative_lifts.webp"
                              alt="images" style={{height: '100px'}}
                            />
                          </div>
                          <div className="client-banner">
                            <img
                              src="/assets/images/portfolio/innovative-lifts.webp"
                              alt="images" style={{width: 'auto'}}
                            />
                          </div>
            </div>
                </div>
                {/* <div className="page1">
                  <div className="about-img">
                      <img
                        src="/assets/images/portfolio/innovative-lifts.webp"
                        alt="images" style={{height: '60vh'}}
                      />
                    </div>
                  <div className="page-flip-marker">➔</div>
                </div> */}
                <div className="page1">
                  <div className="service-wrap">
                          <div className="client-logo">
                            <h2 style={{display:'block'}}>PickNhook<br/>&nbsp;</h2>
                            {/* <img
                              src="/assets/images/brands/picknhook.webp"
                              alt="images"
                            /> */}
                          </div>
                          <div className="client-banner">
                            <img
                              src="/assets/images/portfolio/picknhook.webp"
                              alt="images"
                            />
                          </div>
                  </div>
                </div>
              </HTMLFlipBook>
        </div>
         </div>
    </div>

      <Footer />
    </>
  );
};

export default MobileAppDevelopment;
