import React from 'react';

const TabContentComponentFullStack = ({ activeKey }) => {
    return (
        <div className="tab-content justify-content-center">
            {activeKey === 'tab1' && 
            <p>
                <div className="yellow-bg pt-150 pb-70 md-pt-60 md-pb-60">
                    <div className="container">
                    <div className="row">
                        <div className="col-lg-6 md-mb-50">
                        <div className="about-content">
                            <div className="images-part">
                            <img
                                src="/assets/images/frontend-img.webp"
                                alt="Images"
                            />
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-6 pl-30 md-pr-l5">
                        <div className="sec-title mb-20">
                           <h2 className="title title3 pb-25">
                           Frontend
                            </h2>
                            <p className="margin-0">
                            Full stack frontend technology is vital for crafting sophisticated, user-friendly web applications. It includes a deep expertise in essential frontend tools like HTML, CSS, and JavaScript, paired with cutting-edge frameworks such as React, Angular, and Vue.js. These technologies enable the creation of engaging, responsive interfaces that improve user interaction. Additionally, effective communication with backend systems through APIs facilitates seamless data integration. Mastery of full stack frontend technology allows your software development company to build versatile, scalable applications that cater to diverse user needs and stay ahead in a competitive market.{" "}
                            </p>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </p>
            }
            {activeKey === 'tab2' && 
            <p>
            <div className="yellow-bg pt-150 pb-70 md-pt-80 md-pb-60">
                <div className="container">
                <div className="row">
                    <div className="col-lg-6 md-mb-50">
                    <div className="about-content">
                        <div className="images-part">
                        <img
                            src="/assets/images/backend-img.webp"
                            alt="Images"
                        />
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-6 pl-30 md-pr-l5">
                    <div className="sec-title mb-20">
                        <h2 className="title title3 pb-25">
                        Backend
                        </h2>
                        <p className="margin-0">
                        Full stack backend technology is crucial for developing scalable and efficient software solutions. It integrates various programming languages like PHP, Python, Java, Laravel and Node.js. By utilizing advanced frameworks and tools, we build robust APIs that enable seamless interaction between the front-end and backend systems. Our approach ensures that applications are not only high-performing but also adaptable to evolving needs. With a focus on security and scalability, we deliver backend solutions that drive innovation and support your business goals effectively.</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </p>
            }
            {activeKey === 'tab3' && 
            <p>
            <div className="yellow-bg pt-150 pb-70 md-pt-80 md-pb-60">
                <div className="container">
                <div className="row">
                    <div className="col-lg-6 md-mb-50">
                    <div className="about-content">
                        <div className="images-part">
                        <img
                            src="/assets/images/databases.webp"
                            alt="Images"
                        />
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-6 pl-30 md-pr-l5">
                    <div className="sec-title mb-20">
                        <h2 className="title title3 pb-25">
                        Databases
                        </h2>
                        <p className="margin-0">
                        Full stack databases are pivotal for robust software development, providing versatile data management solutions across the technology stack. These database systems like MySQL, Elastic search, MongoDB and PostgreSQL which handle structured data for flexible, scalable data storage. By utilizing a combination of these technologies, development teams can address a range of needs from real-time data processing to complex analytics. Full stack databases facilitate efficient data integration, ensuring seamless interaction between different application components. They support dynamic features, scalable performance, and responsive user experiences, adapting to evolving requirements and increasing data volumes. This versatility makes full stack databases a crucial element in building high-performance, adaptable software solutions.</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </p>
            }
             {activeKey === 'tab4' && 
            <p>
            <div className="yellow-bg pt-150 pb-70 md-pt-80 md-pb-60">
                <div className="container">
                <div className="row">
                    <div className="col-lg-6 md-mb-50">
                    <div className="about-content">
                        <div className="images-part">
                        <img
                            src="/assets/images/frameworks.webp"
                            alt="Images"
                        />
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-6 pl-30 md-pr-l5">
                    <div className="sec-title mb-20">
                        <h2 className="title title3 pb-25">
                        Frameworks
                        </h2>
                        <p className="margin-0">
                        Full stack frameworks offer a complete solution for developing both the front-end and back-end of web applications, providing an integrated approach to software development. These frameworks like Django, Codeigniter and Cake PHP streamline the development process by incorporating essential tools and libraries for seamless client-server interaction. They support various programming languages and technologies, from UI design to database management, ensuring a unified development experience. By using full stack frameworks, developers benefit from pre-built components and standardized practices, which accelerate project delivery and improve code quality. Their modular structure enhances scalability and maintainability, allowing for easier updates and integrations.{" "}
                        </p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </p>
            }
        </div>
    );
};

export default TabContentComponentFullStack;
