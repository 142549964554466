import React from 'react';

const TabContentComponentErpSol = ({ activeKey }) => {
    return (
        <div className="tab-content justify-content-center">
            {activeKey === 'tab1' && 
            <p>
                <div className="yellow-bg pt-150 pb-70 md-pt-60 md-pb-60">
                    <div className="container">
                    <div className="row">
                        <div className="col-lg-6 md-mb-50">
                        <div className="about-content">
                            <div className="images-part">
                            <img
                                src="/assets/images/erp1.webp"
                                alt="Images"
                            />
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-6 pl-30 md-pr-l5">
                        <div className="sec-title mb-20">
                           <h2 className="title title3 pb-25">
                           Microsoft Dynamic 365
                            </h2>
                            <p className="margin-0">
                            Microsoft Dynamics 365 revolutionizes business management by combining ERP and CRM capabilities into one powerful platform. It offers a comprehensive suite of tools to optimize financial operations, streamline supply chains, and enhance customer interactions. With its advanced artificial intelligence and analytics, Dynamics 365 delivers actionable insights for better decision-making and operational efficiency. This scalable solution adapts to the needs of both small businesses and large enterprises, providing industry-specific solutions and a unified view of your business processes. Dynamics 365 fosters greater collaboration, drives innovation, and supports growth by integrating all aspects of your software development and business operations. Experience a solution that grows with you and keeps you ahead in a competitive landscape.{" "}
                            </p>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </p>
            }
            {activeKey === 'tab2' && 
            <p>
            <div className="yellow-bg pt-150 pb-70 md-pt-80 md-pb-60">
                <div className="container">
                <div className="row">
                    <div className="col-lg-6 md-mb-50">
                    <div className="about-content">
                        <div className="images-part">
                        <img
                            src="/assets/images/erp2.webp"
                            alt="Images"
                        />
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-6 pl-30 md-pr-l5">
                    <div className="sec-title mb-20">
                        <h2 className="title title3 pb-25">
                        NetSuite ERP
                        </h2>
                        <p className="margin-0">
                        NetSuite ERP delivers a robust, cloud-based platform designed to unify and streamline your business processes. It integrates core functionalities such as financial management, customer relationship management, and e-commerce into a single, cohesive system. NetSuite's real-time reporting and advanced analytics provide deep insights to drive strategic decision-making and operational excellence. The solution's flexibility accommodates businesses of various sizes and industries, offering customized features to meet specific needs. With its scalable infrastructure, NetSuite ERP supports growth and adaptation, enabling your software development company to manage operations efficiently from anywhere. Leverage NetSuite ERP to enhance collaboration, optimize workflows, and accelerate your business success.</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </p>
            }
            {activeKey === 'tab3' && 
            <p>
            <div className="yellow-bg pt-150 pb-70 md-pt-80 md-pb-60">
                <div className="container">
                <div className="row">
                    <div className="col-lg-6 md-mb-50">
                    <div className="about-content">
                        <div className="images-part">
                        <img
                            src="/assets/images/erp3.webp"
                            alt="Images"
                        />
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-6 pl-30 md-pr-l5">
                    <div className="sec-title mb-20">
                        <h2 className="title title3 pb-25">
                        Infor ERP
                        </h2>
                        <p className="margin-0">
                        Infor ERP provides a robust and adaptable platform tailored to meet the diverse needs of your business. With a suite of integrated applications, it streamlines key functions such as financial management, supply chain, and human resources, ensuring cohesive and efficient operations. Infor ERP’s industry-specific solutions cater to sectors like manufacturing, distribution, and healthcare, offering specialized tools for enhanced performance. The platform’s advanced analytics and artificial intelligence deliver real-time insights, empowering your software development company to make informed decisions and drive growth. Its cloud-based framework ensures flexibility and scalability, adapting seamlessly to your changing needs. Infor ERP fosters improved collaboration and process efficiency, helping your company stay competitive and innovate effectively.</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </p>
            }
             {activeKey === 'tab4' && 
            <p>
            <div className="yellow-bg pt-150 pb-70 md-pt-80 md-pb-60">
                <div className="container">
                <div className="row">
                    <div className="col-lg-6 md-mb-50">
                    <div className="about-content">
                        <div className="images-part">
                        <img
                            src="/assets/images/erp4.webp"
                            alt="Images"
                        />
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-6 pl-30 md-pr-l5">
                    <div className="sec-title mb-20">
                        <h2 className="title title3 pb-25">
                        Epicor ERP
                        </h2>
                        <p className="margin-0">
                        Epicor ERP delivers a dynamic and scalable platform designed to enhance your business operations and foster growth. It integrates essential functions such as financial management, manufacturing, and supply chain into a cohesive system tailored to your industry’s needs. The platform’s flexible, modular design allows for customization, ensuring that your specific business requirements are met. Epicor ERP’s advanced analytics and real-time reporting provide actionable insights for smarter decision-making and operational efficiency. With its cloud-based framework, Epicor offers scalability and accessibility, enabling seamless adaptation to your company’s changing needs. By streamlining processes and improving interdepartmental collaboration, Epicor ERP supports your software development company’s innovation and competitive edge.{" "}
                        </p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </p>
            }
        </div>
    );
};

export default TabContentComponentErpSol;
