import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';

const TabsComponent = ({ activeKey, onSelect }) => {
    return (
        <Tabs activeKey={activeKey} onSelect={onSelect} id="tab-component" className='justify-content-center'>
            <Tab eventKey="tab1" title={
                <span>
                    <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/technologies/Magento.webp" className="attachment-full" alt="Magento" title="Magento" />
                        </span>
                    </div>
                </span>
            } />
            <Tab eventKey="tab2" title={
                <span>
                    <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/technologies/Shopify-1.webp" className="attachment-full" alt="Shopify" title="Shopify" />
                        </span>
                    </div>
                </span>
            } />
            <Tab eventKey="tab3" title={
                <span>
                    <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/technologies/woocommerce.webp" className="attachment-full" alt="woocommerce" title="woocommerce" />
                        </span>
                    </div>
                </span>
            } />
            <Tab eventKey="tab4" title={
                <span>
                    <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/technologies/bigcommere.webp" className="attachment-full" alt="bigcommere" title="Big-commere" />
                        </span>
                    </div>
                </span>
            } />
        </Tabs>
    );
};

export default TabsComponent;
