import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';

const TabsComponentDMApp = ({ activeKey, onSelect }) => {
    return (
        <Tabs activeKey={activeKey} onSelect={onSelect} id="tab-component" className='justify-content-center'>
            <Tab eventKey="tab1" title={
                <span>
                    <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/technologies/seo.png" className="attachment-full" alt="Search Engine Optimization(SEO)" title="Search Engine Optimization(SEO)" />
                        </span>
                    </div>
                </span>
            } />
            <Tab eventKey="tab2" title={
                <span>
                    <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/technologies/smm.png" className="attachment-full" alt="Social Media Marketing(SMM)" title="Social Media Marketing(SMM)" />
                        </span>
                    </div>
                </span>
            } />
            <Tab eventKey="tab3" title={
                <span>
                    <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/technologies/sem.png" className="attachment-full" alt="Search Engine Marketing(SEM)" title="Search Engine Marketing(SEM)" />
                        </span>
                    </div>
                </span>
            } />
            <Tab eventKey="tab4" title={
                <span>
                    <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/technologies/webanalytics.png" className="attachment-full" alt="Web Analytics" title="Web Analytics" />
                        </span>
                    </div>
                </span>
            } />
        </Tabs>
    );
};

export default TabsComponentDMApp;
