import Header from "../Header";
import Footer from "../Footer";
import Slider from "react-slick/lib/slider";
import { clientslider, digitalServicesslider, homeclientslider } from "../CarosuelsSettings";
import ClientSection from "../ClientSection";
import MetaTag from "../MetaTag";
import ScrollToTopLink from "../ScrollToTopLink";
import Background from "../Background";
import { useState, useEffect } from 'react';

const Home = () => {
  const MetaTitle = "Premier Product Development, Mobile, BI Analytics, ERP, and AI Solutions | Hilton Software Technologies Pvt. Ltd.";
  const MetaDescription =
    "Explore innovative product development, mobile app solutions, BI analytics, custom ERP systems, and AI services at Hilton Software Technologies Pvt. Ltd. We specialize in driving business growth and efficiency through cutting-edge technology. Contact us for expert consultation today!";
  const MetaKeywords =
    "Custom ERP Solutions, Web application development company, website development, software development company, software application development";
  const path = window.location.pathname;
    const [bgColor, setBgColor] = useState('black-bg3');

    useEffect(() => {
      const handleScroll = () => {
        const scrollPosition = window.scrollY;
        
        // Change the background color after scrolling past 100px (example)
        if (scrollPosition > 5000) {
          setBgColor('black-bg3'); // Change this to your desired background class or color
        } else {
          setBgColor('bg19'); // Original background class or color
        }
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    });
    
    const [skew, setSkew] = useState({ skewX: 0, skewY: 0 });
    const handleMouseMove = (event) => {
      const container = event.currentTarget;
      const { offsetWidth: width, offsetHeight: height } = container;
      const { offsetX: x, offsetY: y } = event.nativeEvent;
  
      // Calculate skew based on mouse position
      const skewX = ((x / width) - 0.5) * 20; // Adjust 20 to control the max skew
      const skewY = ((y / height) - 0.5) * 20; // Adjust 20 to control the max skew
  
      setSkew({ skewX, skewY });
    };
  
    const handleMouseLeave = () => {
      setSkew({ skewX: 0, skewY: 0 });
    };
  return (
    <>
      <Header />
      <Background />
      <MetaTag
        title={MetaTitle}
        description={MetaDescription}
        keywords={MetaKeywords}
        path={path}
      />
      {/* <!-- Banner Section Start --> */}
      <div className="rs-banner banner-home-style2">
      {/*<div className="video-container">
      <video autoPlay loop muted playsInline>
        <source src="/assets/images/video4.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>*/}
        <div className="container-fluid custom">
          <div className="row y-middle">
            <div className="col-sm-7">
              <div className="content-wrap">
                <h1 className="title">
                Advancing growth through collaboration
                </h1>
                <div className="description mb-30">
                Our employees pursue personal excellence while embracing the strength of teamwork, driving forward innovation and success in our software development projects.
                </div>
                <div className="btn-wrap mt-50 mb-20">
                  <a
                    target="_self"
                    className="fl-button yellow-bg"
                    href="/aboutus"
                    tabIndex="0"
                    previewlistener="true"
                  >
                    <span className="fl-button-text">Know More</span>
                    <i className="fl-button-icon fl-button-icon-after fa fa-long-arrow-right"></i>
                    
                  </a>
                </div>
                
              </div>
            </div>
            <div className="col-sm-5">
            <div
              className="image-containe mt-50"
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
            >
              <img
                src="/assets/images/appdevelopment.webp"
                alt="Image"
                style={{
                  transform: `skew(${skew.skewX}deg, ${skew.skewY}deg)`,
                }}
              />
            </div>
               {/* <div className="image-containe">
                  <img className="bounceInUp mt-50"
                    src="/assets/images/appdevelopment.webp"
                    alt="Images"
                  />
                </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Banner Section End --> */}

     
      <div
        id="rs-about"
        className="rs-about style3 black-bg2 text-white pt-60 pb-60 md-pt-60 md-pb-60 xs-pt-30 xs-pb-30"
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-5 md-mb-50">
              <div className="about-content">
                <div className="rs-animations">
                <div className="images-part ball text-center">
                  <img className="up-down"
                    src="/assets/images/whoweare.webp"
                    alt="Images" style={{height: '400px'}}
                  />
                </div>
                </div>
              </div>
            </div>
            <div className="col-sm-7 pl-30 md-pr-l5">
              <div className="sec-title mb-20">
                <h2 className="title title3 c-yellow">
                  Who we are
                </h2>
                <div class="bar-main">
                  <div class="bar bar-big left"></div>
                </div>
                <p className="ft16">We are committed to delivering optimal solutions, coupled with our vast experience and unrivaled customer support, make us the go-to choice for businesses of all sizes</p>
                
              </div>
              <div className="sec-title4 mb-35">
                <p>At Hilton, we have been providing personalized IT solutions to accelerate business growth since our establishment in 2013. Our premium, tailored services include product development, e-Commerce, CMS, and web/mobile application development, as well as other key areas such as AI, ML, and deep learning. Our unrivaled customer support and vast experience with small, medium, and large companies worldwide make us the go-to for professional IT services. Our commitment to delivering optimal solutions, no matter the company size or location, sets us apart. Contact us today to learn how we can help you achieve your growth goals.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="rs-achievement style1 relative pt-60 pb-60 md-pt-60 xs-pt-30 xs-pb-30 hidden">
        <div className="container-fluid">
          <div className="row rs-vertical-middle">
            <div className="col-md-12">
            <div className="sec-title3 text-center mb-45 md-mb-45">
            <h2 className="title pb-25">Who we are</h2>
            <p className="ft16">We are committed to delivering optimal solutions, coupled with our vast experience and unrivaled customer support, make us the go-to choice for businesses of all sizes</p>
            <div class="bar-main">
                  <div class="bar bar-big"></div>
                </div>
          </div>
              <div className="sec-title4 mb-35 text-center">
                <p>At Hilton, we have been providing personalized IT solutions to accelerate business growth since our establishment in 2013. Our premium, tailored services include product development, e-Commerce, CMS, and web/mobile application development, as well as other key areas such as AI, ML, and deep learning. Our unrivaled customer support and vast experience with small, medium, and large companies worldwide make us the go-to for professional IT services. Our commitment to delivering optimal solutions, no matter the company size or location, sets us apart. Contact us today to learn how we can help you achieve your growth goals.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
 {/* <!-- Services Section Start --> */}
     
 <div
        id="rs-services"
        className="rs-services our-services style4 pt-60 pb-60 md-pt-60 md-pb-60 xs-pt-30 xs-pb-30"
      >
        <div className="container-fluid">
          <div className="sec-title3 text-center mb-60 text-white">
            <h2 className="title pb-20 text-white">Empowering Your Digital Transformation</h2>
            <span className="border-line">
            <i className="fa fa-diamond" id="diamond"></i>
            </span>
            <p className="dese margin-0">
            We deliver a wide range of innovative digital solutions, including interactive e-commerce websites, robust content management systems, and mobile applications. Our expertise in AI and machine learning enhances these offerings, enabling businesses across various industries to thrive in today’s digital landscape. We proudly serve a diverse array of sectors, including:
            </p>
          </div>

          <div className="row">
          <Slider {...digitalServicesslider}>
            <div className="col-lg-3 col-md-6">
            <a
                  target="_self"
                  href="/services/fullstack-development"
                >
              <div className="services-item">
                <div className="services-icon">
                  <img
                    className="dance_hover"
                    src="assets/images/fullstack.webp"
                    alt="Services"
                  />
                </div>
                <div className="services-content">
                  <h3 className="title">
                    <a>Full-stack development</a>
                  </h3>
                  <p>Frontend | Backend | Databases | Frameworks</p>
                </div>
              </div>
              </a>
            </div>
            <div className="col-lg-3 col-md-6">
            <a
                  target="_self"
                  href="/services/e-commerce-development"
                >
              <div className="services-item">
                <div className="services-icon">
                  <img
                    className="dance_hover"
                    src="assets/images/e-commerce.webp"
                    alt="Services"
                  />
                </div>
                <div className="services-content">
                  <h3 className="title">
                    <a>e-Commerce development</a>
                  </h3>
                 <p>Magento | Shopify | Woo-Commerce | Big-Commerce</p>
                </div>
              </div>
              </a>
            </div>
            <div className="col-lg-3 col-md-6">
            <a
                  target="_self"
                  href="/services/mobile-app-development"
                >
              <div className="services-item">
                <div className="services-icon">
                  <img
                    className="dance_hover"
                    src="assets/images/mobile-app-dev.webp"
                    alt="Services"
                  />
                </div>
                <div className="services-content">
                  <h3 className="title">
                    <a>Mobile Application</a>
                  </h3>
                 <p>UI/UX | Native | Progressive web Apps | Swift</p>
                </div>
              </div>
              </a>
            </div>
            <div className="col-lg-3 col-md-6">
            <a
                  target="_self"
                  href="/services/web-app-development"
                >
              <div className="services-item">
                <div className="services-icon">
                  <img
                    className="dance_hover"
                    src="assets/images/web-applications.webp"
                    alt="Services"
                  />
                </div>
                <div className="services-content">
                  <h3 className="title">
                    <a>Web Application</a>
                  </h3>
                 <p>Laravel | Django | Angular | React</p>
                </div>
              </div>
              </a>
            </div>
            <div className="col-lg-3 col-md-6">
            <a
                  target="_self"
                  href="/services/hire-a-developer"
                >
              <div className="services-item">
                <div className="services-icon">
                  <img
                    className="dance_hover"
                    src="assets/images/we-are-hiring.webp"
                    alt="Services"
                  />
                </div>
                <div className="services-content">
                  <h3 className="title">
                    <a>Hire a Developer</a>
                  </h3>
                 <p>High quality work | Quick team scaling | Certified developers</p>
                </div>
              </div>
              </a>
            </div>
            <div className="col-lg-3 col-md-6">
            <a
                  target="_self"
                  href="/services/technical-support"
                >
              <div className="services-item">
                <div className="services-icon">
                  <img
                    className="dance_hover"
                    src="assets/images/team-support.webp"
                    alt="Services"
                  />
                </div>
                <div className="services-content">
                  <h3 className="title">
                    <a>Technical Support</a>
                  </h3>
                 <p>Installation Support | Technical Production Support | Server and Database Support | Troubleshooting Support</p>
                </div>
              </div>
              </a>
            </div>
            <div className="col-lg-3 col-md-6">
            <a
                  target="_self"
                  href="/services/bi-analytics"
                >
              <div className="services-item">
                <div className="services-icon">
                  <img
                    className="dance_hover"
                    src="assets/images/analytics.webp"
                    alt="Services"
                  />
                </div>
                <div className="services-content">
                  <h3 className="title">
                    <a>Data & Analytics</a>
                  </h3>
                  <p>Power BI | Microsoft Fabric | Azure Data Factory</p>
                </div>
              </div>
              </a>
            </div>
            <div className="col-lg-3 col-md-6">
              <a
                  target="_self"
                  href="/services/ai-ml"
                >
                <div className="services-item">
                <div className="services-icon">
                  <img
                    className="dance_hover"
                    src="assets/images/ai-ml.webp"
                    alt="Services"
                  />
                </div>
                <div className="services-content">
                  <h3 className="title">
                    <a>AI & ML</a>
                  </h3>
                  <p>Chatbots | Image Recognition</p>
                </div>
              </div>
              </a>
            </div>
            <div className="col-lg-3 col-md-6">
                <a
                  target="_self"
                  href="/services/erp-solutions"
                >
                <div className="services-item">
                <div className="services-icon">
                  <img
                    className="dance_hover"
                    src="assets/images/erp-solution.webp"
                    alt="Services"
                  />
                </div>
                <div className="services-content">
                  <h3 className="title">
                    <a>ERP Solutions</a>
                  </h3>
                  <p>Microsoft Dynamic 365 | NetSuite ERP | Infor ERP | Epicor ERP</p>
                </div>
              </div>
              </a>
            </div>
            {/* <div className="col-lg-3 col-md-6">
              <div className="services-item">
                <div className="services-icon">
                  <img
                    className="dance_hover"
                    src="assets/images/advertising.webp"
                    alt="Services"
                  />
                </div>
                <div className="services-content">
                  <h3 className="title">
                    <a>Digital Marketing</a>
                  </h3>
                 <p>Search Engine Optimization(SEO) | Social Media Marketing(SMM) | Search Engine Marketing(SEM) | Web Analytics</p>
                </div>
              </div>
            </div> */}
            {/* <div className="col-lg-3 col-md-6 hidden">
              <div className="services-item">
                <div className="services-icon">
                  <img
                    className="dance_hover"
                    src="/assets/images/analytics.webp"
                    alt="Services"
                  />
                </div>
                <div className="services-content">
                  <h3 className="title">
                    <a>Data and Analytics</a>
                  </h3>
                  <ul className="rs-features-list">
                    <li>
                      <i className="fa fa-check"></i>
                      <span className="feature-text">Data Warehousing</span>
                    </li>
                    <li>
                      <i className="fa fa-check"></i>
                      <span className="feature-text">Data Integration</span>
                    </li>
                    <li>
                      <i className="fa fa-check"></i>
                      <span className="feature-text">Data Visualization</span>
                    </li>
                    <li>
                      <i className="fa fa-check"></i>
                      <span className="feature-text">Real-time Analytics</span>
                    </li>
                    <li>
                      <i className="fa fa-check"></i>
                      <span className="feature-text">Predictive Analytics</span>
                    </li>
                    <li>
                      <i className="fa fa-check"></i>
                      <span className="feature-text">Data Governance</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
            
            </Slider>
          </div>
        </div>
      </div>
      {/* <!-- Services Section End --> */}
  <div className="home page-template-default page hidden">
      <article
      className="fl-post post-5 page type-page status-publish hentry"
      id="fl-post-5">
      <div className="fl-post-content clearfix" itemProp="text">
        <div
          className="fl-builder-content fl-builder-content-5 fl-builder-content-primary fl-builder-global-templates-locked"
          data-post-id="5"
        >
          <div
            className="fl-row fl-row-full-width fl-row-bg-color fl-node-2hz6qw9r87t0 fl-row-custom-height fl-row-align-center home-award-wrap"
            data-node="2hz6qw9r87t0"
          >
            <div className="fl-row-content-wrap">
              <div className="fl-row-content fl-row-fixed-width container-fluid fl-node-content">
                <div
                  className="fl-col-group fl-node-g50y6ejf1ui7 fl-col-group-equal-height fl-col-group-align-top"
                  data-node="g50y6ejf1ui7"
                >
                  <div
                    className="fl-col fl-node-bm63h0gsinvk fl-col-small home-award-title"
                    data-node=""
                  >
                    <div className="fl-col-content fl-node-content">
                      <div
                        className="fl-module fl-module-html fl-node-10kbmr6eyoch award-triangle"
                        data-node=""
                      >
                        <div className="fl-module-content fl-node-content">
                        <div className="sec-title2 mb-65 md-mb-45">
            <h2 className="text-white title">Our Services</h2>
             <div class="bar-main">
                  <div class="bar bar-big left"></div>
                </div>
            <p className="text-white ft16">Empower your business with innovative digital solutions that drive creativity and growth. Our
software development services deliver 360° value, maximizing impact at scale. Unlock new
possibilities and achieve your goals with our state-of-the-art technology.</p>
            <div className="btn-wrap mt-50">
              <a
                target="_self"
                className="fl-button yellow-bg"
                href="/services"
                tabIndex="0"
                previewlistener="true"
              >
                <span className="fl-button-text">Know More</span>
                <i className="fl-button-icon fl-button-icon-after fa fa-long-arrow-right"></i>
                
              </a>
            </div>
          </div>
                       
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="fl-col fl-node-efwzpi75mt61 fl-col-small"
                  >
                    <div className="fl-col-content fl-node-content">
                      <div
                        className="fl-module fl-module-pp-infolist fl-node-64e57pqjd2is home-awards-list"
                      >
                        <div className="fl-module-content fl-node-content">
                          <div className="pp-infolist-wrap">
                            <div className="pp-infolist layout-1">
                            <ul className="pp-list-items">
  {[
    {
      title: 'AI & ML',
      imageSrc: 'assets/images/ai-ml.webp',
      description: 'Chatbots | Predictive Analytics | Fraud Detection | Image Recognition',
    },
    {
      title: 'Data & Analytics',
      imageSrc: 'assets/images/analytics.webp',
      description: 'Power BI | Microsoft Fabric | Azure Data Factory',
    },
    {
      title: 'ERP Solutions',
      imageSrc: 'assets/images/erp-solution.webp',
      description: 'Microsoft Dynamic 365 | NetSuite ERP | Infor ERP | Epicor ERP',
    },
    {
      title: 'Full-stack development',
      imageSrc: 'assets/images/fullstack.webp',
      description: 'Frontend | Backend | Databases | Frameworks',
    },
    {
      title: 'e-Commerce development',
      imageSrc: 'assets/images/e-commerce.webp',
      description: 'Magento | Shopify | Woo-Commerce | Big-Commerce',
    },
    {
      title: 'Mobile Application',
      imageSrc: 'assets/images/mobile-app-dev.webp', // Replace with the correct image path
      description: 'UI/UX | Native | Progressive web Apps | Swift',
    },
    {
      title: 'Web Application',
      imageSrc: 'assets/images/web-applications.webp', // Replace with the correct image path
      description: 'Laravel | Django | Angular | React',
    },
    {
      title: 'Hire a Developer',
      imageSrc: 'assets/images/we-are-hiring.webp', // Replace with the correct image path
      description: 'High quality work | Quick team scaling | Certified developers',
    },
    {
      title: 'Technical Support',
      imageSrc: 'assets/images/team-support.webp',
      description: 'Installation Support | Technical Production Support | Server and Database Support | Troubleshooting Support',
    },
    {
      title: 'Digital Marketing',
      imageSrc: 'assets/images/advertising.webp', // Replace with the correct image path
      description: 'Search Engine Optimization(SEO) | Social Media Marketing(SMM) | Search Engine Marketing(SEM) | Web Analytics',
    },
  ].map((item, index) => (
    <li className={`pp-list-item pp-list-item-${index}`} key={index}>
      <div className="pp-list-item-content">
        <div className="pp-icon-wrapper animated none">
          <div className="pp-infolist-icon">
            <div className="pp-infolist-icon-inner">
              <span className="pp-icon" role="presentation"></span>
            </div>
          </div>
        </div>
        <div className="pp-heading-wrapper">
          <div className="pp-infolist-title">
            <h3
              className="pp-infolist-title-text"
              dangerouslySetInnerHTML={{ __html: item.title }}
            ></h3>
          </div>
          <div className="pp-infolist-description">
            <p>
              <img
                width="70"
                height="56"
                decoding="async"
                role="presentation"
                src={item.imageSrc}
                alt="award"
              />
            </p>
            <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
          </div>
        </div>
      </div>
      <div className="pp-list-connector"></div>
    </li>
  ))}
</ul>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  </div>


      {/* <!-- Services Section Start --> */}
      <div className="rs-services style7 black-bg3 pt-60 pb-60 xs-pt-30 xs-pb-30 hidden">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 md-mb-30">
              <div className="services-item">
                <div className="services-icon">
                  <img src="/assets/images/consultation.webp" alt="Images" />
                </div>
                <div className="services-content">
                  <h2 className="title"> Need consultation?</h2>
                  <p className="services-txt">
                    Looking to upgrade your IT solutions with our expert
                    consultation?
                  </p>
                  <div className="btn-part mt-22">
                    <ScrollToTopLink className="hilton-btn-sm" to="/contact">
                      Let's connect
                    </ScrollToTopLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 md-mb-30">
              <div className="services-item">
                <div className="services-icon">
                  <img src="/assets/images/need-team.webp" alt="Images" />
                </div>
                <div className="services-content">
                  <h2 className="title"> Need a team?</h2>
                  <p className="services-txt">
                    Looking for a professional IT development team to work
                    on-site?
                  </p>
                  <div className="btn-part mt-22">
                    <ScrollToTopLink
                      className="hilton-btn-sm"
                      to="/hire-a-developer"
                    >
                      Let's connect
                    </ScrollToTopLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="services-item">
                <div className="services-icon">
                  <img src="/assets/images/mvp.webp" alt="Images" />
                </div>
                <div className="services-content">
                  <h2 className="title"> Building an MVP?</h2>
                  <p className="services-txt">
                    Do you require assistance in developing an MVP for your
                    innovative idea?
                  </p>
                  <div className="btn-part mt-22">
                    <ScrollToTopLink className="hilton-btn-sm" to="/contact">
                      Let's connect
                    </ScrollToTopLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Services Section End --> */}

      <div className="rs-achievement style1 relative pt-60 pb-60 md-pt-60 xs-pt-30 xs-pb-30 hidden">
        <div className="container">
          <div className="row rs-vertical-middle">
            <div className="col-md-5 pr-0">
              <div className="sec-title4 mb-35">
                <h2 className="title">We are accredited by</h2>
                <p>we have been attained a specific qualification including</p>
              </div>
              <div className="rs-counter hover-box">
                <div className="col-md-5">
                  <div className="counter-icon">
                    <img
                      src="/assets/images/clients/magento_certified.webp"
                      alt="Images"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="counter-icon">
                    <img
                      src="/assets/images/zend-php-engineer.webp"
                      alt="Images"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-6">
              <div className="rs-partner client-logos">
                <div className="container">
                  <div className="sec-title4 text-center md-left mb-40">
                    <h2 className="title">Our valued patrons</h2>
                    <p>We strive to surpass expectations and provide outstanding quality across every facet of our business to ensure customer satisfaction.</p>
                  </div>
                  <div className="rs-patter-section ">
                    <ClientSection settings={homeclientslider} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="rs-services home-style2 gray-bg pt-60 pb-60 md-pt-60 md-pb-60 xs-pt-30 xs-pb-30 hidden">
        <div className="container">
          <div className="sec-title2 text-center md-left mb-40">
            <h2 className="title">
              Progressive web app for headless, faster loading e-Commerce
            </h2>
            <p>
              Rev up your headless e-Commerce store's loading speed with our
              cutting-edge Progressive Web App solution, delivering faster
              performance and seamless user experiences for your customers
            </p>
          </div>

          <div className="row y-middle">
            <div className="col-lg-4  md-mb-50 pr-30 md-pr-l5">
              <div className="services-item mb-20">
                <div className="services-icon">
                  <img src="/assets/images/accessibility.webp" alt="Images" />
                </div>
                <div className="services-text">
                  <h3 className="title">
                    <a>Accessibility</a>
                  </h3>
                </div>
              </div>
              <div className="services-item mb-20">
                <div className="services-icon">
                  <img src="/assets/images/performance.webp" alt="Images" />
                </div>
                <div className="services-text">
                  <h3 className="title">
                    <a>Performance</a>
                  </h3>
                </div>
              </div>
              <div className="services-item">
                <div className="services-icon">
                  <img src="/assets/images/speed.webp" alt="Images" />
                </div>
                <div className="services-text">
                  <h3 className="title">
                    <a>Speed</a>
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-lg-4  md-mb-50">
              <div className="rs-videos choose-video">
                <div className="images-video">
                  <img
                    src="/assets/images/ecommerce.webp"
                    alt="images"

                    // style="border-radius:10px;"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4 pl-30 md-pl-15">
              <div className="services-item mb-20">
                <div className="services-icon">
                  <img src="/assets/images/customization.webp" alt="Images" />
                </div>
                <div className="services-text">
                  <h3 className="title">
                    <a>Customization</a>
                  </h3>
                </div>
              </div>
              <div className="services-item mb-20">
                <div className="services-icon">
                  <img src="/assets/images/seo.webp" alt="Images" />
                </div>
                <div className="services-text">
                  <h3 className="title">
                    <a>SEO</a>
                  </h3>
                </div>
              </div>
              <div className="services-item">
                <div className="services-icon">
                  <img src="/assets/images/flexibility.webp" alt="Images" />
                </div>
                <div className="services-text">
                  <h3 className="title">
                    <a>Flexibility</a>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="rs-services main-home services-style1 text-white black-bg3 home-4-style pt-60 pb-60 md-pt-60 md-pb-60 xs-pt-30 xs-pb-30">
        <div className="container-fluid">
          <div className="sec-title3 text-center mb-65 md-mb-45">
            <h2 className="title text-white pb-25">Why Choose Us</h2>
            <span className="border-line">
            <i className="fa fa-diamond" id="diamond"></i>
            </span>
            <p className="ft16">At our software development firm, we are dedicated to delivering top-notch solutions tailored to
              your business needs. With our extensive experience and expertise, we ensure the highest
              quality in every project. Our commitment to excellence, combined with unmatched customer
              support, sets us apart. We partner with businesses of all sizes to turn their ideas into reality.
              Choose us to elevate your software development journey.</p>
                {/* <div class="bar-main">
                  <div class="bar bar-big"></div>
                </div> */}
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-6">
                <div className="services-item mb-30">
                  <div className="services-icon">
                    <img
                      src="/assets/images/trusted.webp"
                      alt="Services"
                    />
                  </div>

                  <div className="services-text">
                    <h2 className="title text-white">
                      <a>Trusted and Proven Expertise</a>
                    </h2>

                    <p className="services-txt text-white">
                      Our skilled team combine deep industry knowledge with cutting-edge technology to deliver custom software solutions. We focus on creating innovative products that meet your unique business needs and are built to stand the test of time.
                    </p>

                    <div className="serial-number">01</div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="services-item">
                  <div className="services-icon">
                    <img
                      src="/assets/images/client.webp"
                      alt="Services"
                    />
                  </div>

                  <div className="services-text">
                    <h2 className="title text-white">
                      <a>Client-Centered Approach</a>
                    </h2>

                    <p className="services-txt text-white">
                    We work closely with you, ensuring your vision is brought to life with precision. Our flexible development process adapts to your feedback, and we offer ongoing support to keep your software running smoothly, long after launch.
                    </p>

                    <div className="serial-number">02</div>
                  </div>
                </div>
              </div>

            {/* <div className="col-lg-4 col-md-4">
            <div className="why-choose-img">
                <div className="images-video">
                  <img src="/assets/images/why-choose-us-.png" alt="images" />
                </div>
              </div>
            </div> */}

          </div>
        </div>
      </div>


      <div className="rs-choose bg5 pt-60 pb-60 md-pt-60 md-pb-60 xs-pt-30 xs-pb-30 hidden">
        <div className="container">
          <div className="row y-middle">
            <div className="col-lg-6 pr-70 md-pr-15 md-mb-50">
              <div className="sec-title mb-35">
                <h2 className="title pb-30">Why choose us</h2>
                <p className="margin-0">
                  We are committed to delivering optimal solutions, coupled with
                  our vast experience and unrivaled customer support, make us
                  the go-to choice for businesses of all sizes
                </p>
              </div>
              <div className="why-us">
                <ul className="rs-features-list">
                  <li>
                    <i className="fa fa-check"></i>
                    <span className="feature-text">10+ Years experience</span>
                  </li>
                  <li>
                    <i className="fa fa-check"></i>
                    <span className="feature-text">Cost-effective</span>
                  </li>
                  <li>
                    <i className="fa fa-check"></i>
                    <span className="feature-text">Innovative technology</span>
                  </li>
                  <li>
                    <i className="fa fa-check"></i>
                    <span className="feature-text">Certified tech team</span>
                  </li>
                  <li>
                    <i className="fa fa-check"></i>
                    <span className="feature-text">Customized solutions</span>
                  </li>
                  <li>
                    <i className="fa fa-check"></i>
                    <span className="feature-text">Strategic partnerships</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="why-choose-img">
                <div className="images-video">
                  <img src="/assets/images/why.webp" alt="images" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="rs-partner gray-bg pt-60 pb-60 xs-pt-30 xs-pb-30 hidden">
        <div className="container">
          <div className="sec-title2 text-center md-left mb-40">
            <h2 className="title">
              Our expertise in cutting-edge technologies
            </h2>
          </div>
          <Slider {...clientslider}>
            <div className="project-item">
              <div className="project-img">
                <a>
                  <img src="/assets/images/technologies/fabric.png" alt="" />
                  Fabric
                </a>
              </div>
            </div>
            <div className="project-item">
              <div className="project-img">
                <a>
                  <img src="/assets/images/technologies/power-bi.png" alt="" />
                  Power Bi
                </a>
              </div>
            </div>
            <div className="project-item">
              <div className="project-img">
                <a>
                  <img src="/assets/images/technologies/adf.png" alt="" />
                  ADF
                </a>
              </div>
            </div>
            <div className="project-item">
              <div className="project-img">
                <a>
                  <img src="/assets/images/technologies/Laravel.webp" alt="" />
                  Laravel
                </a>
              </div>
            </div>
            <div className="project-item">
              <div className="project-img">
                <a>
                  <img src="/assets/images/technologies/Magento.webp" alt="" />
                  Magento
                </a>
              </div>
            </div>
            <div className="project-item">
              <div className="project-img">
                <a>
                  <img
                    src="/assets/images/technologies/node-js-logo.webp"
                    alt=""
                  />
                  Node.Js
                </a>
              </div>
            </div>
            <div className="project-item">
              <div className="project-img">
                <a>
                  <img src="/assets/images/technologies/Shopify-1.webp" alt="" />
                  Shopify
                </a>
              </div>
            </div>
            <div className="project-item">
              <div className="project-img">
                <a>
                  <img
                    src="/assets/images/technologies/php-logo-small.webp"
                    alt=""
                  />
                  PHP
                </a>
              </div>
            </div>
            <div className="project-item">
              <div className="project-img">
                <a>
                  <img src="/assets/images/technologies/drupal.webp" alt="" />
                  Drupal
                </a>
              </div>
            </div>
            <div className="project-item">
              <div className="project-img">
                <a>
                  <img src="/assets/images/technologies/bootstrap.webp" alt="" />
                  Bootstrap
                </a>
              </div>
            </div>
            <div className="project-item">
              <div className="project-img">
                <a>
                  <img
                    src="/assets/images/technologies/woocommerce.webp"
                    alt=""
                  />
                  Woocommerce
                </a>
              </div>
            </div>
            <div className="project-item">
              <div className="project-img">
                <a>
                  <img src="/assets/images/technologies/ReactJS.webp" alt="" />
                  React JS
                </a>
              </div>
            </div>
            <div className="project-item">
              <div className="project-img">
                <a>
                  <img src="/assets/images/technologies/CakePHP.webp" alt="" />
                  CakePHP
                </a>
              </div>
            </div>
            <div className="project-item">
              <div className="project-img">
                <a>
                  <img src="/assets/images/technologies/angularjs.webp" alt="" />
                  AngularJs
                </a>
              </div>
            </div>
            <div className="project-item">
              <div className="project-img">
                <a>
                  <img src="/assets/images/technologies/python.webp" alt="" />
                  Python
                </a>
              </div>
            </div>
          </Slider>
        </div>
      </div>

      <div className="rs-services style5 industries black-bg pt-60 pb-60 md-pt-60 md-pb-60 xs-pt-30 xs-pb-30">
       <div className="container-fluid">
          <div className="sec-title2 text-white text-center mb-40 eleven">
            <h2 className="title c-yellow">
            Empowering Businesses Across Various Sectors
            </h2>
            <span className="border-line">
            {/* <img src="/assets/images/fav.webp" alt="" id="diamond" /> */}
            <i className="fa fa-diamond" id="diamond"></i>
            </span>
            <p className="ft16">Our e-Commerce websites are engineered with a high degree of interactivity and profitability in mind. They come equipped with a plethora of functional tools that enable you to effortlessly manage your business activities, attract more customers, and ultimately increase sales. We serve a diverse array of industries, encompassing the following sectors:</p>
           </div>
          
          <div className="row">
            <div className="col-lg-3 col-md-4 mb-3">
              <div className="flip-box-inner">
                <div className="flip-box-wrap">
                  <div className="front-part">
                    <div className="front-content-part">
                      <div className="front-icon-part">
                        <div className="icon-part">
                          <img src="/assets/images/automobile.webp" alt="" />
                        </div>
                      </div>
                      <div className="front-title-part">
                        <h3 className="title">
                          <a>Automobile</a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="back-part automobile">
                  <a class="service-overlay" href="#" previewlistener="true"></a>
                    <div className="back-front-content">
                      <div className="back-title-part">
                        <h3 className="back-title">
                          <a>Automobile</a>
                        </h3>
                      </div>
                      <div className="back-desc-part">
                        <p className="back-desc">
                          e-Commerce benefits customers and businesses, driving
                          revenue across the entire value chain
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 mb-3">
              <div className="flip-box-inner">
                <div className="flip-box-wrap">
                  <div className="front-part">
                    <div className="front-content-part">
                      <div className="front-icon-part">
                        <div className="icon-part">
                          <img src="/assets/images/electronics.webp" alt="" />
                        </div>
                      </div>
                      <div className="front-title-part">
                        <h3 className="title">
                          <a>Electronics</a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="back-part electronics">
                  <a class="service-overlay" href="#" previewlistener="true"></a>
                    <div className="back-front-content">
                      <div className="back-title-part">
                        <h3 className="back-title">
                          <a>Electronics</a>
                        </h3>
                      </div>
                      <div className="back-desc-part">
                        <p className="back-desc">
                          Our e-Commerce streamlines equipment and parts
                          selection, facilitating ease and convenience for your
                          clients
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 mb-3">
              <div className="flip-box-inner">
                <div className="flip-box-wrap">
                  <div className="front-part">
                    <div className="front-content-part">
                      <div className="front-icon-part">
                        <div className="icon-part">
                          <img src="/assets/images/fashion.webp" alt="" />
                        </div>
                      </div>
                      <div className="front-title-part">
                        <h3 className="title">
                          <a>Fashion</a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="back-part fashion">
                  <a class="service-overlay" href="#" previewlistener="true"></a>
                    <div className="back-front-content">
                      <div className="back-title-part">
                        <h3 className="back-title">
                          <a>Fashion</a>
                        </h3>
                      </div>
                      <div className="back-desc-part">
                        <p className="back-desc">
                          Our e-Commerce platform boosts your organization's
                          competitiveness, driving market penetration and growth
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 mb-3">
              <div className="flip-box-inner">
                <div className="flip-box-wrap">
                  <div className="front-part">
                    <div className="front-content-part">
                      <div className="front-icon-part">
                        <div className="icon-part">
                          <img src="/assets/images/floristry.webp" alt="" />
                        </div>
                      </div>
                      <div className="front-title-part">
                        <h3 className="title">
                          <a>Floristry</a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="back-part floristry">
                  <a class="service-overlay" href="#" previewlistener="true"></a>
                    <div className="back-front-content">
                      <div className="back-title-part">
                        <h3 className="back-title">
                          <a>Floristry</a>
                        </h3>
                      </div>
                      <div className="back-desc-part">
                        <p className="back-desc">
                        Our e-Commerce platform for florists is designed with intuitive features to improve convenience and ease of use.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 mb-3">
              <div className="flip-box-inner">
                <div className="flip-box-wrap">
                  <div className="front-part">
                    <div className="front-content-part">
                      <div className="front-icon-part">
                        <div className="icon-part">
                          <img src="/assets/images/furniture.webp" alt="" />
                        </div>
                      </div>
                      <div className="front-title-part">
                        <h3 className="title">
                          <a>Furniture</a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="back-part furniture">
                  <a class="service-overlay" href="#" previewlistener="true"></a>
                    <div className="back-front-content">
                      <div className="back-title-part">
                        <h3 className="back-title">
                          <a>Furniture</a>
                        </h3>
                      </div>
                      <div className="back-desc-part">
                        <p className="back-desc">
                          As a top furniture e-Commerce site, we prioritize
                          delivering the latest trends and advancements
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-3 col-md-4 mb-3">
              <div className="flip-box-inner">
                <div className="flip-box-wrap">
                  <div className="front-part">
                    <div className="front-content-part">
                      <div className="front-icon-part">
                        <div className="icon-part">
                          <img src="/assets/images/grocery.webp" alt="" />
                        </div>
                      </div>
                      <div className="front-title-part">
                        <h3 className="title">
                          <a>Grocery</a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="back-part grocery">
                  <a class="service-overlay" href="#" previewlistener="true"></a>
                    <div className="back-front-content">
                      <div className="back-title-part">
                        <h3 className="back-title">
                          <a>Grocery</a>
                        </h3>
                      </div>
                      <div className="back-desc-part">
                        <p className="back-desc">
                          Our comprehensive merchandising experience provides a
                          unified customer view for your store
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="col-lg-3 col-md-4 mb-3">
              <div className="flip-box-inner">
                <div className="flip-box-wrap">
                  <div className="front-part">
                    <div className="front-content-part">
                      <div className="front-icon-part">
                        <div className="icon-part">
                          <img src="/assets/images/healthcare.webp" alt="" />
                        </div>
                      </div>
                      <div className="front-title-part">
                        <h3 className="title">
                          <a>Healthcare</a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="back-part healthcare">
                  <a class="service-overlay" href="#" previewlistener="true"></a>
                    <div className="back-front-content">
                      <div className="back-title-part">
                        <h3 className="back-title">
                          <a>Healthcare</a>
                        </h3>
                      </div>
                      <div className="back-desc-part">
                        <p className="back-desc">
                        Our e-Commerce streamlines operations, improves customer service, leading to significant cost reductions.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 mb-3">
              <div className="flip-box-inner">
                <div className="flip-box-wrap">
                  <div className="front-part">
                    <div className="front-content-part">
                      <div className="front-icon-part">
                        <div className="icon-part">
                          <img src="/assets/images/sports.webp" alt="" />
                        </div>
                      </div>
                      <div className="front-title-part">
                        <h3 className="title">
                          <a>Sports</a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="back-part sports">
                  <a class="service-overlay" href="#" previewlistener="true"></a>
                    <div className="back-front-content">
                      <div className="back-title-part">
                        <h3 className="back-title">
                          <a>Sports</a>
                        </h3>
                      </div>
                      <div className="back-desc-part">
                        <p className="back-desc">
                        Manage events, reserve courts, and track your sports performance effortlessly, all within our all-in-one sports platform.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="rs-achievement style1 relative black-bg2 text-white pt-60 pb-60 md-pt-60 md-pb-60 xs-pt-30 xs-pb-30">
        <div className="container-fluid">
          <div className="row rs-vertical-middle">
            <div className="col-md-12">
              <div className="rs-partner client-logos">
                <div className="container-fluid">
                <div className="sec-title3 text-center mb-65 md-mb-45">
                  <h2 className="title text-white pb-25">Our valued patrons</h2>
                  <span className="border-line">
                  <i className="fa fa-diamond" id="diamond"></i>
                  </span>
                  <p className="ft16">We strive to surpass expectations and provide outstanding quality across every facet of our business to ensure customer satisfaction.</p>
                  {/* <div class="bar-main">
                        <div class="bar bar-big"></div>
                      </div> */}
                </div>
                  <div className="rs-patter-section ">
                    <ClientSection settings={homeclientslider} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     
      <div className={`app rs-blog style2 home5-blog-style black-bg3 pt-40 pb-40`} style={{ position: 'relative' }}>
      {/* <a href="#rs-header"><div class="space"></div></a> */}
      <div className="container-fluid">
        <div className="row y-middle">
          <div className="col-lg-9">
            <div className="sec-title2">
              <span className="sub-title yellow-color">News updates</span>
              <h2 className="title text-white">
                Stay up-to-date with our latest & business tips & tricks
              </h2>
            </div>
          </div>
          <div className="col-lg-3" style={{float:'right'}}>
            <div className="btn-wrap">
              <a
                target="_self"
                className="fl-button yellow-bg"
                href="/contact"
                tabIndex="0"
                previewlistener="true"
              >
                <span className="fl-button-text">Contact Us</span>
                <i className="fl-button-icon fl-button-icon-after fa fa-long-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
        {/* <RecentBlog /> */}
      </div>
    </div>
      {/* <!-- Blog End --> */}
      <Footer />
    </>
  );
};

export default Home;
